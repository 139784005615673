import { jsonb, varchar, unique } from "drizzle-orm/pg-core";
import { ContentConfigurationId, ContentId } from "../../agid";
import { ContentInfo } from "../../models";
import { agid } from "../custom-types";
import { config, createBaseWithId, ownership } from "./base";
import { agConfig, agRuntime } from "./schema";

/**
 *
 * Content configuration here define how we generate the content itself.
 *
 * The type of the content is defined in the content_type field of the config
 *
 * For static content, the payload is in the content column
 * For generated content, the payload will contain the prompt and the actual
 * generated will be done as part of the Content Generation Workflow.
 *
 */
export const ContentConfig = agConfig.table(
  "content",
  {
    ...createBaseWithId<ContentConfigurationId>(),
    ...ownership,
    ...config,
  },
  (table) => {
    return {
      uniqueKey: unique("uq_content_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

/**
 * The actual content payloads, static or dynamic get placed here
 * so the Action Execution records always have a reference to the content
 *
 */

export const Content = agRuntime.table("ag_content", {
  ...createBaseWithId<ContentId>(),
  configId: agid("config_id").$type<ContentConfigurationId>().notNull(),
  content: varchar("content").notNull(),
  contentType: varchar("content_type").notNull(),
  info: jsonb("info").$type<ContentInfo>().notNull(),
});
