import type { Dispatch, FC, SetStateAction } from "react";
import type { ActivityFilters } from "@/components/activity/helpers/activity-filters";
import { trpc } from "@/utils/trpc";
import type {
  FunctionChainInputType,
  FunctionChainOutputType,
} from "allgood-api/src/services/rap/rap-functions/company-contact";
import Loading from "@/components/loading";
import { ContactFilter } from "@/pages/company/components/contact-filter";

export type ContactFilterProps = FunctionChainInputType & {
  filters?: ActivityFilters;
  setFilters?: Dispatch<SetStateAction<ActivityFilters>>;
  cachedData?: FunctionChainOutputType;
};
export const ContactFilterWidget: FC<ContactFilterProps> = (input) => {
  const { ident, missionId, params, filters, setFilters, cachedData } = input;
  const { data, isLoading: isLoading } =
    trpc.rap.getFunctionChain.useQuery<FunctionChainOutputType>(
      {
        missionId,
        ident: ident,
        params: params,
      } satisfies FunctionChainInputType,
      {
        // This is used from chat
        enabled: !Boolean(cachedData),
      },
    );

  return (
    <Loading loading={isLoading} fullscreen={false}>
      {data && (
        <ContactFilter
          missionId={missionId}
          filters={filters}
          functionChainInput={input}
          contacts={(cachedData ?? data).output.contacts}
          setFilters={setFilters ? setFilters : () => {}}
        />
      )}
    </Loading>
  );
};
