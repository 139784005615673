import React, { useEffect } from "react";
import Loading from "src/components/loading";
import { type RouterInputs, type RouterOutputs, trpc } from "src/utils/trpc";
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { AgentId, type UserId } from "allgood-schema";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { AgentById } from "@/utils/by-id";
import { AgentAvatar } from "@/pages/task/components/agent-avatar";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { XCircle } from "@phosphor-icons/react/dist/csr/XCircle";
import { FilterButton } from "@/components/filter-button";
import { GuidePopover } from "@/pages/campaign/configure/guide-popover";
import {
  BaseConfigCard,
  type BaseConfigModel,
} from "@/pages/admin/components/base-config-card";
import { ArrowSquareOut } from "@phosphor-icons/react";
import { RouterLink } from "@/components/core/router-link";
import { buildRoute } from "@/utils/route";
import { paths } from "@/paths";
import type { TeamConfigSchema } from "allgood-api/src/repos/team_config.schema";
import { type MemberType } from "allgood-api/src/repos/team_config.schema";

const NULL_AGENT_ID = AgentId.parse("00000000-0000-0000-0000-000000000000");
export type AgentWorkflowMutation =
  | RouterInputs["agentWorkflow"]["createAgentWorkflow"]
  | RouterInputs["agentWorkflow"]["update"];
type AgentWorkflowConfigCardProps = {
  task?: RouterOutputs["agentWorkflow"]["get"];
  onSubmit: (payload: AgentWorkflowMutation) => void;
  submitButtonLabel?: string;
  isSubmitting?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitResult?: any;
};
type TaskConfigCardI = React.FC<AgentWorkflowConfigCardProps>;
export const TaskConfigCard: TaskConfigCardI = ({
  task,
  onSubmit,
  submitButtonLabel,
  isSubmitting,
  submitResult,
}) => {
  const [taskData, setTaskData] = React.useState<AgentWorkflowMutation>(
    // @ts-ignore XXX: This is a hack, the empty state is NOT actually a valid TeamConfiguration here.
    task
      ? ({
          ...task,
          config: {
            ...task.config,
            // @ts-ignore
            teamLead:
              task.config.graph.find((g) => g.from === "__start__")?.to ??
              NULL_AGENT_ID,
            repl: task.config.repl === undefined ? "" : task.config.repl,
          },
        } satisfies RouterInputs["agentWorkflow"]["update"])
      : ({
          name: "",
          description: "",
          config: {
            teamMembers: [],
            graph: [
              { from: "__start__", to: NULL_AGENT_ID },
              { from: NULL_AGENT_ID, to: "__end__" },
            ],
            goal: "",
            repl: "",
            teamLead: NULL_AGENT_ID,
            suggestions: [],
          } as TeamConfigSchema,
        } satisfies RouterInputs["agentWorkflow"]["createAgentWorkflow"]),
  );
  const [error, setError] = React.useState<string>("");

  const [startNode, setStartNode] = React.useState<
    AgentId | UserId | "__end__" | undefined
  >(task?.config.graph.find((node) => node.from === "__start__")?.to);

  const [selectedGuide, setSelectedGuide] = React.useState<
    AgentWorkflowMutation["config"]["teamMembers"][number] | undefined
  >();

  useEffect(() => {
    if (startNode) {
      const graph = taskData?.config.graph ?? [];
      if (!graph.length) {
        graph.push({
          from: "__start__",
          to: NULL_AGENT_ID,
        });
        graph.push({
          from: NULL_AGENT_ID,
          to: "__end__",
        });
      }
      setTaskData({
        ...taskData,
        config: {
          ...taskData?.config,
          teamLead: AgentId.parse(startNode),
          // @ts-ignore XXX: This is a hack, TypeScript is not able to figure out the right type for the node names
          graph: taskData?.config.graph.map((node) => {
            if (node.from === "__start__") {
              return { ...node, to: startNode };
            } else if (node.to === "__end__") {
              return { ...node, from: startNode };
            } else {
              return { ...node };
            }
          }),
        },
      });
    }
  }, [startNode]);

  const {
    data: agentList,
    isLoading: isLoadingAgentList,
    refetch: refetchAgents,
  } = trpc.agent.getAgents.useQuery({});

  const { data: _missionList, isFetching: isLoadingMissionList } =
    trpc.mission.list.useQuery(
      {},
      {
        enabled: Boolean(task),
      },
    );

  async function handleUpdateAgentWorkflow() {
    if (!taskData.name) {
      return setError("Name is required");
    }
    if (!taskData.description) {
      return setError("Description is required");
    }
    if (
      !taskData.config.teamMembers ||
      taskData.config.teamMembers.length === 0
    ) {
      return setError("Agents are required");
    }
    if (taskData.config.teamMembers.some((agent) => !agent.id)) {
      return setError("One or more agents are missing");
    }
    if (!startNode || !startNode.toString()) {
      return setError("Start Node is required");
    }
    setError("");
    onSubmit(taskData);
    await refetchAgents();
  }

  const handleGuideChange = React.useCallback(
    (value?: string) => {
      if (value) {
        const agent = {
          id: AgentId.parse(value),
          task: "",
          memberType: "agent" as MemberType,
        };

        if (taskData.config.teamMembers.length === 0) {
          setStartNode(agent.id);
        }
        setTaskData({
          ...taskData,
          config: {
            ...taskData.config,
            teamMembers: [...taskData.config.teamMembers, agent],
          },
        });
        setSelectedGuide(agent);
      }
    },
    [taskData],
  );

  return (
    <Loading
      loading={isLoadingAgentList || isLoadingMissionList}
      fullscreen={false}
    >
      <BaseConfigCard
        data={taskData}
        setData={(data: BaseConfigModel) =>
          setTaskData({ ...taskData, ...data })
        }
        onSubmit={handleUpdateAgentWorkflow}
        submitButtonLabel={submitButtonLabel}
        isSubmitting={isSubmitting}
        submitResult={submitResult}
        error={error}
        readonly={task ? ["name"] : []}
      >
        {agentList ? (
          <Box mb={3} mt={4}>
            <TextField
              label={
                <>
                  Task Goal
                  <br />
                  <Typography variant={"caption"} color={"text.secondary"}>
                    The goal is shared across the agents to achieve the goal.
                  </Typography>
                </>
              }
              variant="outlined"
              multiline
              minRows={4}
              maxRows={10}
              value={taskData.config.goal}
              onChange={(e) =>
                setTaskData({
                  ...taskData,
                  config: {
                    ...taskData.config,
                    goal: e.target.value,
                  },
                })
              }
              sx={{ width: "100%", mb: 4, bgcolor: "white" }}
            />

            <TextField
              label={
                <>
                  Interactive Instructions
                  <br />
                  <Typography variant={"caption"} color={"text.secondary"}>
                    These instructions are used by the Lead Agent when a user
                    has a conversation interactively. It is shared across the
                    agents to achieve the goal.
                  </Typography>
                </>
              }
              variant="outlined"
              multiline
              minRows={4}
              maxRows={10}
              value={taskData.config.repl}
              onChange={(e) =>
                setTaskData({
                  ...taskData,
                  config: {
                    ...taskData.config,
                    repl: e.target.value,
                  },
                })
              }
              sx={{ width: "100%", mb: 4, bgcolor: "white" }}
            />

            <Box mb={4}>
              <Typography variant="subtitle2">Initial Suggestions</Typography>
              <Stack direction={"column"} spacing={2} mt={1} mb={2}>
                {taskData.config.suggestions?.map((suggestion, i) => {
                  return (
                    <Stack direction={"row"} spacing={2}>
                      <TextField
                        key={i}
                        fullWidth
                        value={suggestion}
                        onChange={(e) =>
                          setTaskData({
                            ...taskData,
                            config: {
                              ...taskData.config,
                              suggestions: taskData.config.suggestions?.map(
                                (s, j) => (i === j ? e.target.value : s),
                              ),
                            },
                          })
                        }
                      />
                      <IconButton
                        onClick={() => {
                          setTaskData({
                            ...taskData,
                            config: {
                              ...taskData.config,
                              suggestions: taskData.config.suggestions?.filter(
                                (_s, j) => i !== j,
                              ),
                            },
                          });
                        }}
                      >
                        <XCircle weight={"fill"} />
                      </IconButton>
                    </Stack>
                  );
                })}
              </Stack>
              <Button
                variant={"outlined"}
                onClick={() => {
                  setTaskData({
                    ...taskData,
                    config: {
                      ...taskData.config,
                      suggestions: [...(taskData.config.suggestions ?? []), ""],
                    },
                  });
                }}
              >
                Add
              </Button>
            </Box>

            <Typography variant="subtitle2">Your Guides</Typography>
            <Typography variant="body2" color={"text.secondary"}>
              Here are your guides for the task at hand. A guide's skills are
              based on their role.
            </Typography>

            <Stack direction={"row"} spacing={2} mt={2} flexWrap={"wrap"}>
              {taskData.config.teamMembers.map((agent) => {
                const agentFound = agentList.records.find(
                  (a) => a.id === agent.id,
                );
                if (agent.id !== "" && !agentFound) {
                  return <>Agent not found for {agent.id}</>;
                }
                const selected = selectedGuide?.id === agent.id;
                return (
                  <Button
                    key={agent.id}
                    variant={selected ? "contained" : "outlined"}
                    onClick={() => {
                      setSelectedGuide(agent);
                    }}
                    color={"secondary"}
                    sx={{ bgcolor: "white" }}
                    endIcon={
                      <IconButton
                        size={"small"}
                        color={"default"}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (selected) {
                            setSelectedGuide(undefined);
                          }
                          setTaskData({
                            ...taskData,
                            config: {
                              ...taskData.config,
                              teamMembers: taskData.config.teamMembers.filter(
                                (a) => a.id !== agent.id,
                              ),
                            },
                          });
                        }}
                      >
                        <XCircle
                          weight={"fill"}
                          color={selected ? "white" : undefined}
                        />
                      </IconButton>
                    }
                  >
                    <AgentById agentId={agent.id as AgentId}>
                      {(agent) => (
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                        >
                          <AgentAvatar agentId={agent.id} size={28} />
                          {agent.name}{" "}
                          {taskData.config.teamLead === agent.id && (
                            <Chip
                              label={"Team Lead"}
                              size={"small"}
                              color={"agPink"}
                            />
                          )}
                        </Stack>
                      )}
                    </AgentById>
                  </Button>
                );
              })}
              <FilterButton
                label="Add Guide"
                onFilterApply={(value) => {
                  handleGuideChange(value as string);
                }}
                onFilterDelete={() => {
                  handleGuideChange();
                }}
                popover={
                  <GuidePopover
                    selectedGuides={taskData.config.teamMembers.map(
                      (a) => a.id as AgentId,
                    )}
                  />
                }
                value={""}
              />
            </Stack>
          </Box>
        ) : null}

        <Divider />
        <Box mt={3}>
          {selectedGuide ? (
            <Stack spacing={2}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        taskData.config.graph.find(
                          (g) => g.from === "__start__",
                        )?.to === selectedGuide.id
                      }
                      onChange={() => {
                        setStartNode(AgentId.parse(selectedGuide.id));
                      }}
                    />
                  }
                  label="Team Lead"
                  labelPlacement={"start"}
                />
                <RouterLink
                  href={buildRoute(paths.guides.configure, {
                    agentId: selectedGuide.id,
                  })}
                  target={"_blank"}
                >
                  <Button
                    variant={"outlined"}
                    color={"secondary"}
                    startIcon={<ArrowSquareOut />}
                  >
                    Guide Info
                  </Button>
                </RouterLink>
              </Stack>

              <TextField
                label="Task"
                variant="outlined"
                value={
                  taskData.config.teamMembers.find(
                    (a) => a.id === selectedGuide.id,
                  )?.task
                }
                multiline
                fullWidth
                sx={{ bgcolor: "white" }}
                minRows={4}
                maxRows={10}
                onChange={(e) => {
                  setTaskData({
                    ...taskData,
                    config: {
                      ...taskData.config,
                      teamMembers: taskData.config.teamMembers.map((a) =>
                        a.id === selectedGuide.id
                          ? {
                              ...a,
                              task: e.target.value,
                            }
                          : a,
                      ),
                    },
                  });
                }}
              />
            </Stack>
          ) : (
            "Please select guide"
          )}
        </Box>
      </BaseConfigCard>
    </Loading>
  );
};
