import type { Dispatch, FC, SetStateAction } from "react";
import type { MilestoneCluster } from "allgood-api/src/services/opportunity_engagement.schema";
import type { ActivityFilters } from "@/components/activity/helpers/activity-filters";
import { trpc } from "@/utils/trpc";
import type {
  FunctionChainInputType,
  FunctionChainOutputType,
} from "allgood-api/src/services/rap/rap-functions/company-journey";
import Loading from "@/components/loading";
import { TouchTimeline } from "@/pages/company/touch-timeline";
import { MILESTONE_COUNT_IN_PAGE } from "@/pages/company/company-page";

export type TouchTimelineWidgetProps = FunctionChainInputType & {
  setFilters?: Dispatch<SetStateAction<ActivityFilters>>;
  onClickCluster?: (cluster: MilestoneCluster) => void;
  cachedData?: FunctionChainOutputType;
};
export const TouchTimelineWidget: FC<TouchTimelineWidgetProps> = (input) => {
  const { missionId, ident, params, setFilters, onClickCluster, cachedData } =
    input;
  const { filter, limit = MILESTONE_COUNT_IN_PAGE, offset } = params;

  const { data, isLoading: isLoading } =
    trpc.rap.getFunctionChain.useQuery<FunctionChainOutputType>(
      {
        missionId,
        ident: ident,
        params: {
          filter,
          limit: limit + (offset ?? 0),
        },
      } satisfies FunctionChainInputType,
      {
        // This is used from chat
        enabled: !Boolean(cachedData),
      },
    );

  return (
    <Loading loading={isLoading} fullscreen={false}>
      {data && (
        <TouchTimeline
          opportunityId={filter.opportunityId}
          opportunities={(cachedData ?? data).output.opportunities}
          milestoneData={(cachedData ?? data).output.touches}
          onClickCluster={onClickCluster ? onClickCluster : () => {}}
          functionChainInput={input}
          setFilters={setFilters ? setFilters : () => {}}
        />
      )}
    </Loading>
  );
};
