import { unique, varchar } from "drizzle-orm/pg-core";
import { sql } from "drizzle-orm";
import { NormalizationId } from "../../agid";
import { config, createBaseWithId, ownership } from "./base";
import { agConfig } from "./schema";

export const Normalization = agConfig.table(
  "normalization",
  {
    ...createBaseWithId<NormalizationId>(),
    ...ownership,
    ...config,

    /** Short ID for the normalization */
    shortId: varchar("short_id")
      .notNull()
      .default(
        // TODO: remove after migration
        sql`upper(substr(sha256(gen_random_uuid()::text::bytea)::text, 10, 8))`,
      ),
  },
  (table) => {
    return {
      uniqueKey: unique("uq_normalization_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);
