import { z } from "zod";

export const BarChartProps = z.object({
  type: z.literal("BarChart"), // Fix error on OpenAI: Consider adding a discriminator key or rearranging the properties to ensure the first key is unique.
  data: z.array(
    z.object({
      name: z.string(),
      value: z.number(),
    }),
  ),
  barSize: z.number().optional(),
  chartHeight: z.number().optional(),
  layout: z.enum(["horizontal", "vertical"]).optional(),
  percentage: z.boolean().optional(),
});

export type BarChartProps = z.infer<typeof BarChartProps>;
export const PieChartProps = z.object({
  type: z.literal("PieChart"), // Fix error on OpenAI: Consider adding a discriminator key or rearranging the properties to ensure the first key is unique.
  data: z.array(
    z.object({
      name: z.string(),
      value: z.number(),
    }),
  ),
  chartHeight: z.number().optional(),
  innerRadius: z.number().optional(),
  outerRadius: z.number().optional(),
  fillColors: z.array(z.string()).optional(),
  label: z.boolean().optional(),
});

export type PieChartProps = z.infer<typeof PieChartProps>;

export const ChartCardProps = z.object({
  chartType: z
    .enum(["BarChart", "PieChart"])
    .describe("The type of the chart."),
  title: z.string().describe("The title of the chart."),
  chartData: BarChartProps.or(PieChartProps).describe(
    "The data for the chart.",
  ),
});
export type ChartCardProps = z.infer<typeof ChartCardProps>;

export const DimensionalBarChartProps = z.object({
  data: z
    .object({
      dimension: z.record(z.string()),
      metric: z.record(z.number()),
    })
    .array(),
  defaults: z
    .object({
      dimension: z.string().optional(),
      filter: z
        .object({
          dimension: z.string(),
          operator: z.enum(["is", "is not"]),
          target: z.string(),
        })
        .optional(),
    })
    .optional(),
  name: z.string().optional(),
  instanceId: z.string().optional(),
});

export type DimensionalBarChartProps = z.infer<typeof DimensionalBarChartProps>;
