import { z } from "zod";

// Default Task Widgets to be used by any standard Task

export const HumanTaskWidget = "HumanTaskWidget";
export const TeamTaskWidget = "TeamTaskWidget";
export const ServiceTaskWidget = "ServiceTaskWidget";
export const ParentTaskWidget = "ParentTaskWidget";

// Data Pipeline Tasks
export const DataPipelinePreReqHumanTasksSchema = z.object({
  marketoConnected: z.boolean().optional(),
  salesforceConnected: z.boolean().optional(),
});

// Marketing Database Health Tasks
export const AuditPreReqHumanTasksSchema = z.object({
  marketoConnected: z.boolean().optional(),
  salesforceConnected: z.boolean().optional(),
  smartListName: z.string().optional(),
  slackConnected: z.boolean().optional(),
});

// Buyer Journey Tasks
// Add a refinement that these two lists cannot have overlapping elements
export const BuyerJourneyPreReqHumanTasksSchema = z
  .object({
    finalMilestones: z.array(z.string()).min(1),
    excludeMilestones: z.array(z.string()),
  })
  .refine(
    (data) => {
      return (
        data.finalMilestones.filter((milestone) =>
          data.excludeMilestones.includes(milestone),
        ).length === 0
      );
    },
    { message: "Final milestones cannot be excluded" },
  );
