import * as dateFns from "date-fns";
import { trpc } from "@/utils/trpc";
import type { AgenticComponentDeliverable, MissionId } from "allgood-schema";
import { useMemo } from "react";

export const useDeliverable = <DataType = unknown>({
  missionId,
  taskKey,
  data,
  evaluate = true,
}: {
  missionId?: MissionId;
  taskKey: string;
  data: DataType;
  evaluate?: boolean;
}) => {
  const {
    data: deliverableRecord,
    isFetching: isLoading,
    refetch,
  } = trpc.deliverable.list.useQuery(
    {
      filter: {
        missionId,
        taskKey: taskKey,
      },
      limit: 1,
    },
    {
      enabled: Boolean(missionId),
    },
  );

  const deliverable = useMemo(
    () =>
      deliverableRecord?.records[0]?.deliverable
        ?.data as AgenticComponentDeliverable,
    [deliverableRecord],
  );

  const result: DataType = useMemo(() => {
    if (!evaluate) {
      return data;
    }

    if (deliverable?.reducer) {
      const wrap = (s: string) => "{ return " + s + " };";
      return new Function(wrap(deliverable.reducer))
        .call(null)
        .call(null, data, { dateFns });
    } else {
      return data;
    }
  }, [data, deliverable?.reducer]);

  return {
    isLoading,
    result,
    refetch,
    template: deliverable?.template,
  };
};
