import { MenuItem, Select } from "@mui/material";
import type { ReactElement } from "react";
import { useEffect, useMemo, useState } from "react";
import * as handlebars from "handlebars";

function getValueFromPath(obj: unknown, path: string) {
  // @ts-ignore
  return path.split(".").reduce((acc, key) => acc && acc[key], obj);
}

export const useRenderHandlebarTemplate = ({
  template,
  data,
}: {
  template: string;
  data: unknown;
}): {
  component: ReactElement;
  text?: string;
} => {
  const [selected, setSelected] = useState("");

  // replace {{renderSelect data}} where data is { label: string; value: string }[]
  // assume we only have 1 renderSelect per template.
  return useMemo(() => {
    if (template.includes("{{renderSelect ")) {
      const dataVar = template.match(/{{renderSelect (.*?)}}/)?.[1];
      if (!dataVar) {
        return { component: <>Invalid renderSelect syntax</> };
      }

      const options = getValueFromPath(data, dataVar) as RenderSelectSchema;

      const textChunks = template.split(/{{renderSelect .*?}}/);
      const compiledChunk1 = handlebars.compile(textChunks[0])(data, {
        allowCallsToHelperMissing: true,
      });
      const compiledChunk2 = handlebars.compile(textChunks[1])(data, {
        allowCallsToHelperMissing: true,
      });

      return {
        component: (
          <div>
            {compiledChunk1}{" "}
            <RenderSelect
              value={selected}
              options={options}
              onChange={(value) => {
                setSelected(value);
              }}
            />{" "}
            {compiledChunk2}
          </div>
        ),
        text: compiledChunk1 + selected + compiledChunk2,
      };
    } else {
      const compiled = handlebars.compile(template)(data, {
        allowCallsToHelperMissing: true,
      });
      return {
        component: <>{compiled}</>,
        text: compiled,
      };
    }
  }, [data, selected, template]);
};

export type RenderSelectSchema = {
  label: string;
  value: string;
}[];
export const RenderSelect = ({
  value,
  options,
  onChange,
}: {
  value: string;
  options: RenderSelectSchema;
  onChange: (value: string) => void;
}) => {
  useEffect(() => {
    if (!value && options.length > 0) {
      onChange(options[0].value);
    }
  }, [options]);

  return (
    <Select
      value={value}
      onChange={(e) => {
        onChange(e.target.value as string);
      }}
      size={"small"}
      sx={{
        background: "white",
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
