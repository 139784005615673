import { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";

import { ConfigureLayout } from "@/pages/campaign/configure-layout";
import { DashboardLayout } from "@/pages/campaign/dashboard-layout";
import { TeamTask } from "@/pages/campaign/configure/team-task";

const CampaignsPage = lazy(
  () => import("src/pages/campaign-list/campaign-list-page"),
);
const CompanyPage = lazy(() => import("src/pages/company/company-page"));

/**
 * Configure
 */
const CampaignConfigurePage = lazy(
  () => import("@/pages/campaign/configure-page"),
);
const Configure = lazy(() => import("@/pages/campaign/configure/configure"));
const _Deliverables = lazy(
  () => import("@/pages/campaign/configure/deliverables"),
);

/**
 * Dashboard
 */
const CampaignDashboardPage = lazy(
  () => import("@/pages/campaign/dashboard-page"),
);
const BuyerJourneyOverview = lazy(
  () => import("@/pages/campaign/dashboard/buyerjourney-overview"),
);
const BuyerJourneyJourneys = lazy(
  () => import("@/pages/campaign/dashboard/buyerjourney-journeys"),
);

const SignalsList = lazy(() => import("@/pages/admin/signals/signals-page"));

const InsightsPage = lazy(() => import("@/pages/campaign/dashboard/insights"));

const DataPipelineOverview = lazy(
  () => import("@/pages/admin/data-pipeline/data-pipeline-overview"),
);

const DataNormalizationOverview = lazy(
  () =>
    import("@/pages/insights/data-normalization/data-normalization-overview"),
);

const ChecksOverview = lazy(() => import("@/pages/campaign/dashboard/checks"));

const AttributionOverview = lazy(
  () => import("@/pages/campaign/dashboard/attribution-overview"),
);

export const missionRoutes: RouteObject[] = [
  {
    path: paths.missions.index,
    element: <CampaignsPage />,
  },
  // Insights Dashboard
  {
    path: paths.missions.dashboards.insights,
    element: (
      <DashboardLayout>
        <CampaignDashboardPage>
          <InsightsPage />
        </CampaignDashboardPage>
      </DashboardLayout>
    ),
  },
  // Database Health Dashboard
  {
    path: paths.missions.dashboards.checks,
    element: (
      <DashboardLayout>
        <CampaignDashboardPage disablePadding={true}>
          <ChecksOverview />
        </CampaignDashboardPage>
      </DashboardLayout>
    ),
  },
  {
    path: paths.missions.configure,
    element: (
      <ConfigureLayout>
        <CampaignConfigurePage>
          <Configure />
        </CampaignConfigurePage>
      </ConfigureLayout>
    ),
  },
  {
    path: paths.missions.task,
    element: (
      <ConfigureLayout>
        <CampaignConfigurePage>
          <TeamTask />
        </CampaignConfigurePage>
      </ConfigureLayout>
    ),
  },
  {
    path: paths.missions.dashboard,
    element: (
      <DashboardLayout>
        <CampaignDashboardPage></CampaignDashboardPage>
      </DashboardLayout>
    ),
  },

  // Journey Dashboards
  {
    path: paths.missions.dashboards.journey.overview,
    element: (
      <DashboardLayout>
        <CampaignDashboardPage>
          <BuyerJourneyOverview />
        </CampaignDashboardPage>
      </DashboardLayout>
    ),
  },
  {
    path: paths.missions.dashboards.journey.journeys,
    element: (
      <DashboardLayout>
        <CampaignDashboardPage>
          <BuyerJourneyJourneys />
        </CampaignDashboardPage>
      </DashboardLayout>
    ),
  },

  // Signal Dashboards
  {
    path: paths.missions.dashboards.signals.list,
    element: (
      <DashboardLayout>
        <CampaignDashboardPage>
          <SignalsList />
        </CampaignDashboardPage>
      </DashboardLayout>
    ),
  },
  // Data Pipeline Dashboard
  {
    path: paths.missions.dashboards.dataPipeline.overview,
    element: (
      <DashboardLayout>
        <CampaignDashboardPage>
          <DataPipelineOverview />
        </CampaignDashboardPage>
      </DashboardLayout>
    ),
  },

  // Data Normalization Dashboard
  {
    path: paths.missions.dashboards.dataNormalization.overview,
    element: (
      <DashboardLayout>
        <CampaignDashboardPage>
          <DataNormalizationOverview />
        </CampaignDashboardPage>
      </DashboardLayout>
    ),
  },
  // Attribution Dashboard
  {
    path: paths.missions.dashboards.attribution.overview,
    element: (
      <DashboardLayout>
        <CampaignDashboardPage>
          <AttributionOverview />
        </CampaignDashboardPage>
      </DashboardLayout>
    ),
  },

  {
    path: paths.missions.companyDetail,
    element: <CompanyPage />,
  },
];
