import { Box, Stack } from "@mui/system";
import type { TaskWidgetContext } from "@/pages/campaign/configure/team-task";
import Markdown from "react-markdown";
import { trpc } from "src/utils/trpc";
import { Button } from "@mui/material";
import remarkGfm from "remark-gfm";

/*
  The human task widget will show the human task instructions
  as markdown text if it exists in the TaskConfig.

 */
const HumanTaskWidget = (context: TaskWidgetContext) => {
  const taskId = context.task?.id;
  const submitTask = trpc.task.submit.useMutation();
  const deliverableCreate = trpc.deliverable.getOrCreate.useMutation();
  const trpcUtils = trpc.useUtils();

  const handleSubmit = async () => {
    const deliverable = await deliverableCreate.mutateAsync({
      taskId: taskId,
    });

    await submitTask.mutateAsync({
      status: "IN_REVIEW",
      deliverable: {
        data: {
          status: "Marked as complete",
        },
        summary: "Submitted deliverable....",
      },
      id: deliverable.id,
    });

    trpcUtils.task.list.invalidate();
  };

  if (!context.task || !context.task.config.instructions) {
    return <div>Task Instructions not found</div>;
  } else {
    return (
      <>
        <Box pt={1} m={3}>
          <Markdown className={"markdown"} remarkPlugins={[remarkGfm]}>
            {context.task.config.instructions}
          </Markdown>
          <Stack alignItems={"flex-end"}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                width: "200px",
              }}
            >
              Mark As Complete
            </Button>
          </Stack>
        </Box>
      </>
    );
  }
};

export default HumanTaskWidget;
