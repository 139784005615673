import type { DeliverableId, MissionId, TaskId } from "allgood-schema";
// import {
//   SignalFindingsDeliverableWidget,
//   SignalProcessDeliverableWidget,
//   SignalVerifyDeliverableWidget,
// } from "@/pages/task/components/widgets/components/signal-deliverable-widgets";
import { AuditDatabaseFetchDeliverableWidget } from "@/pages/task/components/widgets/components/audit-deliverable-widgets";
import { AgenticComponentWidget } from "@/pages/task/components/widgets/components/agentic-component-widget";
import type { DeliverablePayload } from "allgood-api/src/repos/deliverable.schema";

export type DeliverableWidgetContext = {
  missionId: MissionId;
  taskId?: TaskId;
  deliverableId?: DeliverableId;
  submitDeliverable?: (
    data: Record<string, unknown> | undefined,
  ) => Promise<string>;
  //  businessObjectiveId: BusinessObjectiveId; This is not available for most missions.
};

// const SIGNAL_MISSION_VERIFY = "signals.verify";
// const SIGNAL_MISSION_PROCESS = "signals.process";
// const SIGNAL_MISSION_FINDINGS = "signals.findings";

export const AGENTIC_COMPONENT = "agentic_component"; // TODO link to the correct mission

export const deliverableWidgets: Record<
  NonNullable<DeliverablePayload["widget"]>["id"],
  (context: DeliverableWidgetContext) => React.ReactElement
> = {
  // [SIGNAL_MISSION_VERIFY]: SignalVerifyDeliverableWidget,
  // [SIGNAL_MISSION_PROCESS]: SignalProcessDeliverableWidget,
  // [SIGNAL_MISSION_FINDINGS]: SignalFindingsDeliverableWidget,
  ["databaseHealth.fetchData"]: AuditDatabaseFetchDeliverableWidget,
  [AGENTIC_COMPONENT]: AgenticComponentWidget,
};
