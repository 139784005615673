import { ChartCard } from "@/components/charts/chart-card";
import InsightCard from "@/components/insight-card";
import { SignalWidget } from "@/pages/task/components/widgets/components/signal-widget";
import type { RouterOutputs } from "@/utils/trpc";
import type { StreamEvent } from "@langchain/core/dist/tracers/log_stream";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import {
  AGCompanyId,
  type ChartCardProps,
  type SignalId,
  TaskId,
  ThreadId,
} from "allgood-schema";
import type { ReactElement } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { z } from "zod";
import { CommonJourneyWidget } from "@/pages/task/components/widgets/components/common-journey-widget";
import { CompanyById } from "@/utils/by-id";
import Stack from "@mui/material/Stack";
import { Avatar } from "@mui/material";
import { Journey } from "allgood-api/src/repos/buyer_journey.schema";
import type { RenderSelectSchema } from "src/utils/handlebars";
import { rapWidgets } from "@/components/rap/rap-widgets";
import type {
  FunctionChainInputType,
  FunctionChainOutputType,
} from "allgood-api/src/services/rap/rap-functions/company-journey";

export const llmWidgets: {
  readonly on_tool_end: Record<
    string,
    (event: StreamEvent) => React.ReactElement
  >;
} = {
  on_tool_end: {
    get_signals: (event: StreamEvent) => {
      const data: {
        name: string;
        status: string;
        id: SignalId;
        description: string;
      }[] = event.data.output;
      return <SignalWidget data={data} />;
    },
    get_common_journeys: (event: StreamEvent) => {
      console.log("get_common_journeys", event.data.output);
      const data: Journey[] = event.data.output;

      return (
        <Box
          sx={{
            p: 3,
          }}
        >
          <Card>
            <CardHeader title={"Most common journeys"} subheader={""} />
            <CardContent sx={{ py: 0 }}>
              <Box width={"fit-content"} maxWidth={"100%"}>
                {`${data.length} journeys found`}
                {/*  TODO: Add more context on this level, or remove this widget, let Agent summarize   */}
              </Box>
            </CardContent>
          </Card>
        </Box>
      );
    },

    start_buyer_journey_analysis: (event: StreamEvent) => {
      const data: { workflowId: string; status: string } = event.data.output;
      return (
        <Box
          sx={{
            p: 3,
          }}
        >
          <Card>
            <CardHeader
              title={"Buyer Journey Analysis Workflow"}
              subheader={data.workflowId}
              sx={{ bgcolor: "#d8efe8" }}
            />
            <CardContent sx={{ py: 3 }}>
              <Typography> Workflow Status {data.status}</Typography>
            </CardContent>
          </Card>
        </Box>
      );
    },
    get_workflow_status: (event: StreamEvent) => {
      const data: { startTime: Date; closeTime?: Date; status: string } =
        event.data.output;
      return (
        <Box
          sx={{
            p: 3,
          }}
        >
          <Card>
            <CardHeader
              title={"Workflow Status"}
              subheader={data?.status}
              sx={{ bgcolor: "#d8efe8" }}
            />
            <CardContent sx={{ py: 3 }}>
              <Typography>
                {" "}
                Start Time : {data?.startTime.toString()}
              </Typography>
              <Typography>
                {" "}
                {data?.closeTime
                  ? "Close Time : " + data.closeTime.toString()
                  : ""}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      );
    },
    send_message: (event: StreamEvent) => {
      const data: { message: string } = event.data.output;
      return (
        <Box
          sx={{
            p: 3,
          }}
        >
          <Card>
            <CardHeader
              title={"Message to User (slack)"}
              subheader={data.message}
              sx={{ bgcolor: "orange" }}
            />
          </Card>
        </Box>
      );
    },
    get_journey_by_company_id: (event: StreamEvent) => {
      const parsed = Journey.safeParse(event.data.output);
      if (!parsed.success) {
        return <> There was an error parsing a previous journey </>;
      }
      const data = parsed.data;
      return (
        <Box
          width={"100%"}
          sx={{
            p: 3,
          }}
        >
          <CompanyById companyId={AGCompanyId.parse(data.companyId)}>
            {(company) =>
              company && (
                <Card>
                  <CardHeader
                    title={
                      <>
                        {/*<Link*/}
                        {/*   to={buildRoute(paths.companies.companyDetail, {*/}
                        {/*     companyId: company?.id,*/}
                        {/*   })}*/}
                        {/*   target="_blank"*/}
                        {/*   rel="noopener noreferrer"*/}
                        {/*   style={{ color: "inherit" }}*/}
                        {/* >*/}
                        <Typography>
                          Company Journey -{" "}
                          {String(company?.fieldRollup.companyName)}
                        </Typography>
                        {/*</Link>*/}
                      </>
                    }
                  />
                  <CardContent sx={{ py: 0 }}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      {String(company?.fieldRollup.companyWebsite) ? (
                        <Box>
                          <object
                            type="image/png"
                            width={40}
                            height={40}
                            data={`https://logo.clearbit.com/${
                              String(company?.fieldRollup.companyWebsite)
                                .replace("https://", "")
                                .replace("http://", "")
                                .match(/[^/]+/g)?.[0]
                            }`}
                          />
                        </Box>
                      ) : (
                        <Avatar variant={"square"}>
                          {String(company?.fieldRollup.companyName)[0]}
                        </Avatar>
                      )}
                      <CommonJourneyWidget
                        journey={data}
                        lastIsGoal={true}
                        recommendation={{ name: data.recommendation || "" }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              )
            }
          </CompanyById>
        </Box>
      );
    },
    get_most_common_representative_journey: (event: StreamEvent) => {
      const data: Journey = event.data.output;

      if (
        !data.content ||
        !data.content.milestones ||
        !data.content.milestones.length ||
        !data.content.milestones[0].name
      ) {
        return <>No data found</>;
      }
      // Transform data for suggestions
      // @ts-ignore
      data["select"] = {
        milestones: data.content.milestones.map((milestone) => ({
          label: milestone.name,
          value: milestone.name,
        })) as RenderSelectSchema,
      };
      return (
        <Box
          width={"100%"}
          sx={{
            p: 3,
          }}
        >
          <Card>
            <CardHeader
              subheader={`${data?.companyCount} companies`}
              title={`Representative Journey ${data?.content?.cluster}`}
            />
            <CardContent sx={{ py: 0 }}>
              <CommonJourneyWidget journey={data} />
            </CardContent>
          </Card>
        </Box>
      );
    },
    create_insight: (event: StreamEvent) => {
      const data: RouterOutputs["insight"]["create"] = event.data.output;
      const insightSchema = z.object({
        taskId: TaskId,
        threadId: ThreadId.nullish(),
        name: z.string(),
        description: z.string(),
        priority: z.number().int().min(0).max(3).default(0),
        content: z.object({ bigNumber: z.string().optional() }),
      });
      if (insightSchema.safeParse(data).success) {
        return (
          <Box
            sx={{
              p: 3,
            }}
          >
            <InsightCard insight={data} />
          </Box>
        );
      } else {
        return <>Insight Created</>;
      }
    },
    create_chart: (event: StreamEvent) => {
      const data: ChartCardProps = event.data.input;
      return (
        <Box
          sx={{
            p: 3,
          }}
        >
          <ChartCard {...data} />
        </Box>
      );
    },
    google_search_company_by_domains: (event: StreamEvent) => {
      const data: { searchUrl: string } = event.data.output;
      return (
        <Box
          sx={{
            p: 3,
          }}
        >
          <Box>
            <Link to={data.searchUrl} target="_blank" rel="noopener noreferrer">
              {data.searchUrl}
            </Link>
          </Box>
        </Box>
      );
    },
    get_newsletter_program_tokens: (event: StreamEvent) => {
      return (
        <Box
          sx={{
            p: 3,
          }}
        >
          <Box>
            <Typography>Newsletter Program Tokens</Typography>
            {JSON.stringify(event.data)}
          </Box>
        </Box>
      );
    },
    get_function_chain: (event: StreamEvent) => {
      const input: FunctionChainInputType = event.data.input;
      const output: FunctionChainOutputType = event.data.output;
      const widget = (
        rapWidgets as Record<string, (params: unknown) => ReactElement>
      )[input.ident];
      if (widget) {
        return (
          <Box
            sx={{
              p: 3,
            }}
          >
            <Card>{widget({ ...input, cachedOutput: output })}</Card>
          </Box>
        );
      }
      return <></>;
    },
  },
};
