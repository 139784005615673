import type { FC, ReactNode } from "react";

import { withAuthGuard } from "@/hocs/with-auth-guard";

import { VerticalLayout } from "@/layouts/dashboard/layout/vertical-layout/vertical-layout";

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard(({ children }) => {
  return <VerticalLayout>{children}</VerticalLayout>;
});
