import * as React from "react";
import Box from "@mui/material/Box";
import { DashboardNav } from "@/pages/campaign/dashboard/dashboard-nav";
import { Seo } from "@/components/seo";

interface LayoutProps {
  children: React.ReactNode;
}

export function DashboardLayout({ children }: LayoutProps): React.JSX.Element {
  return (
    <Box
      flex={1}
      display={"flex"}
      flexDirection={"column"}
      position={"absolute"}
      top={"var(--MainNav-height)"}
      left={{
        md: 0,
        lg: "var(--SideNav-width)",
      }}
      right={0}
      bottom={0}
    >
      <Seo title="Missions Dashboard" />
      <Box mt={2}>
        <DashboardNav />
      </Box>
      <Box width={"100%"} height={"100%"} overflow={"auto"}>
        {children}
      </Box>
    </Box>
  );
}
