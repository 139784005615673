import React, { useEffect, useRef } from "react";
import { Box, Chip, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import type { Journey } from "allgood-api/src/repos/buyer_journey.schema";

export type CommonJourneyWidgetProps = {
  journey: Journey;
  goal?: {
    name: string;
  };
  recommendation?: {
    name: string;
  };
  vertical?: boolean;
  align?: "left" | "right";
  color?: "white" | "grey";
  /**
   * For now, we are using this as a hack, in the future the journey code would properly do this as a
   * proper field on the Milestone in the Journey.
   */
  lastIsGoal?: boolean;
};

export const CommonJourneyWidget: React.FC<CommonJourneyWidgetProps> = ({
  journey,
  goal,
  recommendation,
  vertical = false,
  align = "right",
  color = "grey",
  lastIsGoal = false,
}) => {
  const theme = useTheme();
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy(vertical ? 0 : 99999, 0);
    }
  }, [vertical]);

  const getStyleByType = (
    type: "milestone" | "goal" | "recommendation",
    inRepresentative?: boolean,
  ) => {
    if (type === "milestone") {
      return {
        bgcolor: color === "grey" ? theme.palette.grey["100"] : "white",
        boxShadow:
          color === "grey"
            ? undefined
            : `0 0 4px 1px ${theme.palette.grey["200"]}`,
        border: inRepresentative
          ? `2px solid ${theme.palette.primary.main}`
          : `2px dashed ${theme.palette.grey["400"]}`,
      };
    } else if (type === "goal") {
      return {
        bgcolor: theme.palette.success.light,
        border: `4px solid ${theme.palette.success.dark}`,
      };
    } else {
      return {
        border: `2px dashed ${theme.palette.grey["400"]}`,
      };
    }
  };

  if (!journey || !journey.content || !journey.content.milestones) {
    return null;
  }
  const sortedMilestones = [...journey.content.milestones].sort(
    (a, b) => a.order - b.order,
  );

  const goalList = [];
  if (lastIsGoal && sortedMilestones.length > 0) {
    const last = sortedMilestones.pop();
    if (last) {
      goalList.push({
        name: last.name,
        type: "goal" as const,
        order: Infinity,
        time: last.time,
        tags: last.tags.map((tag) => JSON.stringify(tag)),
        inRepresentativeJourney: false,
      });
    }
  } else {
    if (goal) {
      goalList.push({
        ...goal,
        type: "goal" as const,
        order: Infinity,
        time: 0,
        tags: [],
        inRepresentativeJourney: false,
      });
    }
  }
  // ALL-2327 - This is for Demo purposes only
  if (recommendation && recommendation.name !== "") {
    console.log("WTF", recommendation.name);
    goalList.push({
      ...recommendation,
      type: "recommendation" as const,
      order: Infinity,
      time: 0,
      inRepresentativeJourney: false,
    });
  }
  const allItems = [
    ...sortedMilestones.map((m) => ({
      ...m,
      tags: m.tags.map((tag) => JSON.stringify(tag)),
      type: "milestone" as const,
    })),
    ...goalList,
  ].sort((a, b) => a.order - b.order);

  return (
    <Box
      py={1}
      width={"100%"}
      overflow={"auto"}
      ref={scrollRef}
      sx={
        vertical
          ? {}
          : {
              "-webkit-mask":
                "linear-gradient(to left, black calc(100% - 30px), transparent);",
            }
      }
    >
      <Stack direction="row" spacing={2}>
        <Box flex={1} paddingLeft={vertical ? 0 : "30px"}>
          <Stack
            direction={vertical ? "column-reverse" : "row"}
            spacing={1}
            alignItems="center"
            justifyContent={align}
            data-cy="widget-journey"
          >
            {allItems.map((item, index) => (
              <React.Fragment key={index}>
                <Box
                  data-cy="widget-journey-item"
                  p={2}
                  borderRadius={1}
                  width={vertical ? "100%" : 200}
                  {...getStyleByType(
                    item.type,
                    item.type === "milestone"
                      ? (item.inRepresentativeJourney ?? false)
                      : false,
                  )}
                  minHeight={80}
                  alignContent={"center"}
                  textAlign={"center"}
                  position="relative"
                >
                  <Tooltip
                    title={item.tags ? item.tags.join(", ") : "No tags"}
                    placement="top-end"
                  >
                    <Box
                      position="absolute"
                      top={5}
                      right={5}
                      sx={{
                        cursor: "pointer",
                        color: "primary.main",
                        width: 20,
                        height: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Box>
                  </Tooltip>
                  <Box marginBottom="5px">
                    {item.type === "recommendation" && (
                      <Chip
                        label="Recommendation"
                        size="small"
                        color="primary"
                        sx={{ mb: 1 }}
                      />
                    )}
                    <Typography variant="subtitle2">{item.name}</Typography>
                  </Box>
                </Box>
                {index !== allItems.length - 1 && (
                  <Typography
                    color="text.secondary"
                    textAlign={"center"}
                    mt={1}
                    lineHeight={0.5}
                  >
                    {vertical ? <ArrowUpwardIcon /> : <ArrowForwardIcon />}
                    <br />
                    <Typography variant={"caption"} whiteSpace={"nowrap"}>
                      {(allItems[index + 1]?.time ?? item.time) - item.time}{" "}
                      days
                    </Typography>
                  </Typography>
                )}
              </React.Fragment>
            ))}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
