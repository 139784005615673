import { sql } from "drizzle-orm";
import {
  index,
  integer,
  jsonb,
  pgEnum,
  varchar,
  unique,
} from "drizzle-orm/pg-core";
import {
  DeliverableId,
  InsightId,
  MissionId,
  TaskId,
  ThreadId,
} from "../../agid";
import { agid } from "../custom-types";
import { config, createBaseWithId, deletable, ownership } from "./base";
import { agConfig, agRuntime } from "./schema";

// TODO: Are these really ag_runtime or ag_config tables? They're not
// configuration, they're more like an execution trace, so that leans runtime.
// However, they're all very small data volume, so that leans config.

export const missionStatus = pgEnum("task_status", [
  "IN_PROGRESS",
  "ACTIVE",
  "ARCHIVED",
]);

export const Mission = agConfig.table(
  "mission",
  {
    ...createBaseWithId<MissionId>(),
    ...ownership,
    ...config,

    /** Mission type. */
    missionType: varchar("mission_type").notNull(),
  },
  (table) => {
    return {
      uniqueKey: unique("uq_mission_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

export const Task = agConfig.table(
  "task",
  {
    ...createBaseWithId<TaskId>(),
    ...ownership,
    ...config,

    missionId: agid("mission_id").$type<MissionId>().notNull(),

    /** Task Order displayed in UI. */
    order: integer("order").notNull(),

    parentId: agid("parent_id").$type<TaskId>(),

    taskType: varchar("task_type").notNull(),

    taskKey: varchar("task_key").notNull(),

    /** Acceptance criterion */
    criterion: jsonb("criterion"),
  },
  (table) => {
    return {
      uniqueKey: unique("uq_task_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

export const DeliverableStatus = [
  "TODO",
  "IN_PROGRESS",
  "IN_ERROR",
  "IN_REVIEW",
  "DONE",
] as const;
export type DeliverableStatus = (typeof DeliverableStatus)[number];
export const pgDeliverableStatus = pgEnum(
  "deliverable_status",
  DeliverableStatus,
);

export const Deliverable = agRuntime.table(
  "deliverable",
  {
    ...createBaseWithId<DeliverableId>(),
    ...ownership,

    missionId: agid("mission_id").$type<MissionId>().notNull(),

    taskId: agid("task_id").$type<TaskId>().notNull(),

    /** Current task status. */
    status: pgDeliverableStatus("status").default("TODO").notNull(),

    /** Result of the task. */
    deliverable: jsonb("deliverable"),

    /** Monotonically increasing version number for the `result` column. */
    revision: integer("revision").default(0).notNull(),

    /** Result of evaluating the machine criterion */
    verdict: jsonb("verdict"),
  },
  (t) => ({
    byMission: index("ix_deliverable_mission_id").on(t.tenantId, t.missionId),
    byTeamConfig: index("ix_task__id").on(t.tenantId, t.taskId),
  }),
);

export const Insight = agRuntime.table(
  "insight",
  {
    ...createBaseWithId<InsightId>(),
    ...ownership,
    ...deletable,

    /** The Task this insight was created by. This also gives us the ID of the
     * mission containing that task. */
    taskId: agid("task_id").$type<TaskId>().notNull(),

    /** The agent thread which created this insight. Null for explicitly human-created. */
    threadId: agid("thread_id").$type<ThreadId>(),

    /** the Deliverable this was part of.  Null for explicitly human-created. */
    deliverableId: agid("deliverable_id").$type<DeliverableId>(),

    /** Human name for the insight. */
    name: varchar("name").notNull(),

    /** Insight description displayed in UI. */
    description: varchar("description").notNull(),

    /** Insight Priority (none, low, medium, high; like Linear) */
    priority: integer("priority").notNull().default(0),

    /** Insight content. */
    content: jsonb("content").notNull(),
  },
  (t) => ({
    byTask: index("ix_insight_task_id").on(t.tenantId, t.taskId),
  }),
);
