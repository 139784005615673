import { useRouter } from "src/hooks/use-router";
import { Button } from "@mui/material";
import { FC } from "react";

type BackButtonProps = {
  path?: string;
};

export const BackButton: FC<BackButtonProps> = ({ path }) => {
  const router = useRouter();
  return (
    <Button
      onClick={() => (path ? router.push(path) : router.back())}
      variant={"text"}
      color={"secondary"}
      size={"small"}
    >
      &lt;- Back
    </Button>
  );
};
