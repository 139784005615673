import { sql } from "drizzle-orm";
import { jsonb, unique, uniqueIndex, varchar } from "drizzle-orm/pg-core";
import {
  ActivityTypeId,
  AttributeId,
  FieldId,
  LinkageId,
  IntegrationId,
} from "../../agid";
import { config, createBaseWithId, ownership } from "./base";
import { agConfig } from "./schema";

export const Integration = agConfig.table(
  "integration",
  {
    ...createBaseWithId<IntegrationId>(),
    ...ownership,
    ...config,

    integrationType: varchar("integration_type").notNull(),

    /** Integration credentials, encrypted with the credential KMS key.
     *
     * Base64-encoded (as returned from KMS API) encrypted blob. When decrypted,
     * it should be a UTF-8-encoded JSON blob, whose type is determined by the
     * `integration_type`.
     * */
    encryptedCredentials: varchar("encrypted_credentials").notNull(),
  },
  (table) => {
    return {
      uniqueKey: unique("uq_integration_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

export const ActivityType = agConfig.table(
  "activity_type",
  {
    ...createBaseWithId<ActivityTypeId>(),
    ...ownership,
    ...config,

    stats: jsonb("stats"),
    srcName: varchar("src_name").notNull(),
    srcEntity: varchar("src_entity").notNull(),
    activityTypeName: varchar("activity_type_name").notNull(),
  },
  (table) => {
    return {
      uniqueKey: unique("uq_activity_type_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),

      srcNameEntity: uniqueIndex("ix_activity_type_src_name_entity_name")
        .on(
          table.tenantId,
          table.srcName,
          table.srcEntity,
          table.activityTypeName,
        )
        .where(sql`${table.status} = 'ACTIVE'`),
    };
  },
);

export const Attribute = agConfig.table(
  "attribute",
  {
    ...createBaseWithId<AttributeId>(),
    ...ownership,
    ...config,

    stats: jsonb("stats"),
    srcName: varchar("src_name").notNull(),
    srcEntity: varchar("src_entity").notNull(),
    attrName: varchar("attr_name").notNull(),
  },
  (table) => {
    return {
      uniqueKey: unique("uq_attribute_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),

      srcNameEntity: uniqueIndex("ix_attr_src_name_entity_name")
        .on(table.tenantId, table.srcName, table.srcEntity, table.attrName)
        .where(sql`${table.status} = 'ACTIVE'`),
    };
  },
);

export const Field = agConfig.table(
  "field",
  {
    ...createBaseWithId<FieldId>(),
    ...ownership,
    ...config,

    srcEntity: varchar("src_entity").notNull(),
    srcName: varchar("src_name").notNull(),
  },
  (table) => {
    return {
      uniqueKey: unique("uq_field_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

export const Linkage = agConfig.table("linkage", {
  ...createBaseWithId<LinkageId>(),
  ...ownership,
  ...config,
});
