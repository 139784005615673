import type { UserId } from "allgood-schema";
import { Avatar, Tooltip } from "@mui/material";
import type { ComponentProps } from "react";
import { UserById } from "@/utils/by-id";

import { Invoker } from "allgood-api/src/context";
import { AgentAvatar } from "@/pages/task/components/agent-avatar";

export const UserAvatar = ({
  userId,
  tooltip = true,
  size,
  TooltipProps = {},
}: {
  userId: UserId;
  tooltip?: boolean;
  size: number;
  TooltipProps?: Omit<ComponentProps<typeof Tooltip>, "children" | "title"> & {
    title?: string;
  };
}) => {
  return (
    <UserById userId={userId}>
      {(user) => (
        <Tooltip
          title={user ? `${user.firstName} ${user.lastName}` : "User"}
          disableHoverListener={!tooltip}
          {...TooltipProps}
        >
          <Avatar
            // TODO we don't store user icons yet
            // src={user ? `/${user.config.icon}` : undefined}
            sx={{ width: size, height: size }}
          >
            {user?.firstName?.[0] + user?.lastName?.[0]}
          </Avatar>
        </Tooltip>
      )}
    </UserById>
  );
};

export const InvokerAvatar = ({
  invoker,
  tooltip = true,
  size = 36,
  TooltipProps = {},
}: {
  invoker: Invoker;
  tooltip?: boolean;
  size?: number;
  TooltipProps?: Omit<ComponentProps<typeof Tooltip>, "children" | "title"> & {
    title?: string;
  };
}) => {
  const inv = Invoker.safeParse(invoker);
  if (!inv.success) {
    return (
      <Tooltip title={invoker._type} {...TooltipProps}>
        <Avatar sx={{ width: size, height: size }}>{invoker._type[0]}</Avatar>
      </Tooltip>
    );
  }
  if (invoker._type === "agent") {
    return (
      <AgentAvatar
        agentId={invoker.agentId}
        size={size}
        TooltipProps={TooltipProps}
      />
    );
  }
  if (invoker._type === "user") {
    return (
      <UserAvatar
        userId={invoker.userId}
        tooltip={tooltip}
        size={size}
        TooltipProps={TooltipProps}
      />
    );
  }

  if (invoker._type === "workflow") {
    const icon = (
      <img
        src="/assets/allgood-logo-icon.svg"
        color={"action"}
        height={size}
        width={size}
      />
    );

    return (
      <Tooltip
        title={`allGood Workflow ID: ${invoker.workflowRunId} - ${invoker.workflowType}`}
        disableHoverListener={!tooltip}
        {...TooltipProps}
      >
        <Avatar sx={{ width: size, height: size }}>{icon}</Avatar>
      </Tooltip>
    );
  }
};
