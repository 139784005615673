import Drawer from "@mui/material/Drawer";
import React, { useState } from "react";
import CopilotIcon from "@/icons/copilot-icon";
import IconButton from "@mui/material/IconButton";
import { Stack } from "@mui/system";
import { TeamTask } from "@/pages/campaign/configure/team-task";
import { useParams } from "react-router";
import { trpc } from "@/utils/trpc";
import type { MissionId } from "allgood-schema";
import type { StreamEvent } from "@langchain/core/dist/tracers/log_stream";
import { Alert, Snackbar } from "@mui/material";
import { useSearchParams } from "@/hooks/use-search-params";

type SideChatProps = {
  taskKey: string;
  onSubmitDeliverable?: () => void;
  llmContext?: object;
};

export const SideChat: React.FC<SideChatProps> = ({
  taskKey,
  onSubmitDeliverable,
  llmContext,
}) => {
  const [open, setOpen] = useState(false);
  const params = useParams();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const missionId = params.missionId as MissionId;

  const [tasks] = trpc.task.list.useSuspenseQuery({
    filter: { taskKey, missionId },
    limit: 1,
  });

  const task = tasks.records[0];

  const handleSubmitDeliverable = async () => {
    setOpenSnackbar(true);
    onSubmitDeliverable?.();
  };

  const handleToolEnd = (stream: StreamEvent) => {
    if (stream.name === "submit_task") {
      handleSubmitDeliverable();
    }
  };

  return (
    <>
      <IconButton
        size={"small"}
        onClick={() => {
          if (searchParams.get("taskId") !== task?.id) {
            searchParams.delete("threadId");
            setSearchParams(searchParams);
          }
          setOpen(true);
        }}
      >
        <CopilotIcon size={26} />
      </IconButton>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          variant="standard"
          sx={{ width: "100%" }}
        >
          Updating UI components
        </Alert>
      </Snackbar>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { display: "flex", flexDirection: "column" } }}
      >
        <Stack
          width={"50vw"}
          minWidth={"600px"}
          height={"100%"}
          sx={{ display: "flex", overflow: "hidden" }}
        >
          <TeamTask
            taskId={task?.id}
            onClose={() => setOpen(false)}
            onSubmitDeliverable={handleSubmitDeliverable}
            onToolEnd={handleToolEnd}
            llmContext={{ ...params, ...llmContext, taskKey }}
          />
        </Stack>
      </Drawer>
    </>
  );
};
