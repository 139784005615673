import {
  Call,
  CardGiftcardOutlined,
  ChatBubbleOutlineRounded,
  DateRange,
  Event,
  MailOutline,
  MenuBookOutlined,
  Monitor,
  Newspaper,
  PeopleAltOutlined,
  PlayCircle,
  RestaurantOutlined,
  Timeline,
} from "@mui/icons-material";
import { Table } from "@phosphor-icons/react/dist/csr/Table";

export const getIcon = (
  type: string,
  count: number,
  height = 26,
  style = {},
) => {
  let icon;
  let typeTitle;
  type = type.toLowerCase();

  switch (type) {
    case "allgood":
    case "all_good":
      icon = (
        <img
          src="/assets/allgood-logo-icon.svg"
          color={"action"}
          height={height + 15}
          style={{ ...style }}
        />
      );
      typeTitle = `allgood`;
      break;
    case "email":
      icon = <MailOutline color={"action"} sx={{ ...style }} />;
      typeTitle = `email${count > 1 ? "s" : ""}`;
      break;
    case "call":
      icon = <Call color={"action"} sx={{ ...style }} />;
      typeTitle = `call${count > 1 ? "s" : ""}`;
      break;
    case "meeting":
      icon = <DateRange color={"action"} sx={{ ...style }} />;
      typeTitle = `meeting${count > 1 ? "s" : ""}`;
      break;
    case "website":
      icon = <Monitor color={"action"} sx={{ ...style }} />;
      typeTitle = `page view${count > 1 ? "s" : ""}`;
      break;
    case "food":
      icon = <RestaurantOutlined color={"action"} sx={{ ...style }} />;
      typeTitle = `food${count > 1 ? "s" : ""}`;
      break;
    case "gong":
      icon = (
        <img
          src={"/assets/icons/icon-gong.svg"}
          height={height}
          style={{ filter: "grayscale(1) opacity(0.8)", ...style }}
          alt={"Gong"}
        />
      );
      typeTitle = "Gong";
      break;
    case "slack":
      icon = (
        <img
          src={"/assets/icons/icon-slack.svg"}
          height={height}
          style={{ filter: "grayscale(1) opacity(0.8)", ...style }}
          alt={"Slack"}
        />
      );
      typeTitle = "Slack";
      break;
    case "web chat":
      icon = <ChatBubbleOutlineRounded />;
      typeTitle = `Web chat${count > 1 ? "s" : ""}`;
      break;
    case "marketo":
      icon = (
        <img
          src={"/assets/icons/icon-marketo.svg"}
          height={height}
          style={{ filter: "grayscale(1) opacity(0.6)", ...style }}
          alt={"Marketo"}
        />
      );
      typeTitle = "Marketo";
      break;
    case "zendesk":
      icon = (
        <img
          src={"/assets/icons/icon-zendesk.svg"}
          height={height}
          style={{ filter: "grayscale(1) opacity(0.6)", ...style }}
          alt={"Zendesk"}
        />
      );
      typeTitle = "Zendesk";
      break;
    case "salesforce":
      icon = (
        <img
          src={"/assets/icons/icon-salesforce.svg"}
          height={height}
          style={{ filter: "grayscale(1) opacity(0.6)", ...style }}
          alt={"Salesforce"}
        />
      );
      typeTitle = "Salesforce";
      break;
    case "outreach":
      icon = (
        <img
          src={"/assets/icons/icon-outreach.png"}
          height={height}
          style={{ ...style }}
          alt={"Outreach"}
        />
      );
      typeTitle = "Outreach";
      break;
    case "form":
      icon = <Table color={"#333"} style={{ ...style }} />;
      typeTitle = `form${count > 1 ? "s" : ""}`;
      break;
    case "newsletter":
      icon = <Newspaper color={"action"} sx={{ ...style }} />;
      typeTitle = `newsletter${count > 1 ? "s" : ""}`;
      break;
    case "event":
      icon = <Event color={"action"} sx={{ ...style }} />;
      typeTitle = `event${count > 1 ? "s" : ""}`;
      break;
    case "incentive":
      icon = <CardGiftcardOutlined color={"action"} sx={{ ...style }} />;
      typeTitle = `incentive${count > 1 ? "s" : ""}`;
      break;
    case "ebook":
      icon = <MenuBookOutlined color={"action"} sx={{ ...style }} />;
      typeTitle = `ebook${count > 1 ? "s" : ""}`;
      break;
    case "people":
      icon = <PeopleAltOutlined color={"action"} sx={{ ...style }} />;
      typeTitle = `people${count > 1 ? "s" : ""}`;
      break;
    case "chat":
      icon = <ChatBubbleOutlineRounded color={"action"} sx={{ ...style }} />;
      typeTitle = `chat${count > 1 ? "s" : ""}`;
      break;
    case "video":
      icon = <PlayCircle color={"action"} sx={{ ...style }} />;
      typeTitle = `video${count > 1 ? "s" : ""}`;
      break;
    default:
      icon = <Timeline color={"action"} sx={{ ...style }} />;
      typeTitle = `event${count > 1 ? "s" : ""}`;
      break;
  }
  return { icon, typeTitle };
};
