import * as Sentry from "@sentry/react";
import * as config from "../config";

export function initSentry() {
  Sentry.init({
    dsn: "https://5eedf24ead7f7d0406073f86b7eebf0b@o4507969455194112.ingest.us.sentry.io/4507969463582720",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", config.API_SERVER],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: config.MODE,
    // Only send errors in production and if the log level is warning or higher
    enabled: config.MODE == "staging" || config.MODE == "production",
    beforeSend: (event) => {
      if (
        event.level === "info" ||
        event.level === "debug" ||
        event.level === "log"
      ) {
        return null;
      }

      return event;
    },
  });
}
