import {
  Avatar,
  Box,
  capitalize,
  Card,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import type {
  ActivityCluster,
  ActivityModel,
} from "src/components/activity/activity-types";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { colorMapping } from "src/pages/campaign-list/checklist-helper";
import { ActivityCollapseCard } from "@/pages/company/activity-collapse-card";

export const getHeader = (activity: ActivityModel) => {
  if (activity.content?.primaryAttributeValue) {
    const split = activity.content?.primaryAttributeValue.split("/");
    if (split.length > 2) {
      return split.slice(1, 3).join("/");
    }
    return activity.content?.primaryAttributeValue;
  }
  return activity.title;
};

export const getTitle = (activity: ActivityModel) => {
  const primaryAttributeValue = activity.content?.primaryAttributeValue;

  if (activity.type === "website") {
    const title: string[] = primaryAttributeValue
      .replaceAll("https://", "")
      .split("/") || ["", ""];

    const body =
      title.length > 2 ? " Viewed - " + title.slice(1, 3).join("/") : "";
    return body?.length > 60 ? body?.substring(0, 60) + "..." : body;
  } else if (activity.activityType === "hs_email_event") {
    return `Email ${activity.content?.action_type.toLowerCase()} event - "${
      activity.content?.email_campaign_name
    }" Campaign`;
  }

  if (activity.content?.old_status && activity.content?.new_status) {
    return `${activity.content?.old_status} -> ${activity.content?.new_status}`;
  }

  return (
    activity.title ||
    activity.content?.new_status ||
    primaryAttributeValue ||
    activity.content?.Subject ||
    activity.activityType
  )
    ?.replace("hs_", "")
    .split("_")
    .map(capitalize)
    .join(" ");
};

const SimpleActivityCluster = ({
  clusters,
  onSelect,
}: {
  clusters: ActivityCluster[];
  onSelect: (activity: ActivityModel) => void;
}) => {
  const theme = useTheme();

  return (
    <Stack direction={"column"} spacing={3} marginTop={1} marginBottom={2}>
      {clusters.slice(0, clusters.length).map((cluster, i) => (
        <Box key={i} component={"div"} sx={{}}>
          <Typography variant={"caption"} marginLeft={5.5}>
            {cluster.activities[0]?.timestamp.toLocaleDateString()}
          </Typography>

          <Stack direction={"column"} spacing={2} bgcolor={"white"}>
            {cluster.activities.map((activity) => (
              <Box key={activity.id} data-cy={"activity"}>
                {activity.activityType === "CHECKLIST_COMPLETE" ? (
                  <Card
                    elevation={1}
                    sx={{
                      p: 2,
                      background: colorMapping(theme, "completed")
                        .backgroundColor,
                      borderRadius: 1,
                    }}
                  >
                    <Stack direction={"row"} spacing={2}>
                      <Avatar
                        component="span"
                        variant={"rounded"}
                        sx={{
                          width: 28,
                          height: 28,
                          background: "none",
                          color: colorMapping(theme, "completed").color,
                        }}
                      >
                        <CheckCircleOutlineIcon />
                      </Avatar>
                      <Box component="div">
                        <Typography
                          variant={"subtitle1"}
                          color={theme.palette.success.dark}
                        >
                          {activity?.title}
                        </Typography>
                        <Typography
                          variant={"body2"}
                          color={theme.palette.grey["800"]}
                        >
                          {activity?.body}
                        </Typography>
                      </Box>
                    </Stack>
                  </Card>
                ) : (
                  <ActivityCollapseCard
                    activity={activity}
                    onSelect={onSelect}
                  />
                )}
              </Box>
            ))}
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};

const Timeline = ({
  clusters,
  onSelectActivity,
}: {
  clusters: ActivityCluster[];
  onSelectActivity: (activity: ActivityModel) => void;
}) => {
  return (
    <Box
      component={"div"}
      sx={{
        backgroundImage: "linear-gradient(#DDD, #DDD)",
        backgroundSize: "2px 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "30px center",
      }}
      paddingTop={2}
    >
      <SimpleActivityCluster clusters={clusters} onSelect={onSelectActivity} />
    </Box>
  );
};

export default Timeline;
