import { AGCompanyId, MilestoneId } from "allgood-schema";
import { z } from "zod";
import { Membership } from "./membership";

// Schema for a Milestone
export const MilestoneOutput = z.object({
  name: z.string(),
  milestoneId: MilestoneId.nullable(),
  weight: z.number(),
  time: z.number(),
  order: z.number(),
  tags: z.array(z.unknown()),
  timestamp: z.string().nullable(), // TODO: Use a datetime object
  inRepresentativeJourney: z.boolean().nullable().default(false), // TODO: this should be UI or Tool only field.
});
export type MilestoneOutput = z.infer<typeof MilestoneOutput>;

// Content payload for Buyer Journey
export const BuyerJourneyContent = z.object({
  milestones: z.array(MilestoneOutput),
  cluster: z.number().optional(),
  clusterSimilarity: z.number().optional(),
});
export type BuyerJourneyContent = z.infer<typeof BuyerJourneyContent>;

// Schema for a Journey
export const Journey = z.object({
  companyId: AGCompanyId, // Assuming AGCompanyId is a string
  content: BuyerJourneyContent,
  recommendation: z.string().nullish(), // TODO: this is for UI and Demo only.
  companyCount: z.number().optional(), // TODO: this is for UI and Demo only.
});
export type Journey = z.infer<typeof Journey>;

export const BuyerJourneyLabel = z.enum([
  "converted",
  "prospective",
  "representative",
]);
export type BuyerJourneyLabel = z.infer<typeof BuyerJourneyLabel>;

export type BuyerJourneyMembership = Membership<BuyerJourneyContent>;

export const BuyerJourneyFilter = z.object({
  label: BuyerJourneyLabel.default("converted"),
  companyIds: AGCompanyId.array().optional(),
  companyName: z.string().optional(),
  milestoneIds: MilestoneId.array().optional(),
  fields: z.record(z.string()).optional(),
});
export type BuyerJourneyFilter = z.infer<typeof BuyerJourneyFilter>;

export const BuyerJourneyEntry = z.object({
  id: z.string(),
  companyId: z.string(),
  content: BuyerJourneyContent,
  label: z.string(),
  timestamp: z.string(),
});
export type BuyerJourneyEntry = z.infer<typeof BuyerJourneyEntry>;
