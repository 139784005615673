import { AgentId } from "allgood-schema";
import { Avatar, Fade, Tooltip } from "@mui/material";
import { AgentById } from "@/utils/by-id";
import { ComponentProps } from "react";

export const AgentAvatar = ({
  agentId,
  tooltip = true,
  size,
  stacked,
  hoverFactor,
  TooltipProps = {},
}: {
  agentId: AgentId;
  tooltip?: boolean;
  size?: number;
  stacked?: number;
  hoverFactor?: number;
  TooltipProps?: Omit<ComponentProps<typeof Tooltip>, "children" | "title"> & {
    title?: string;
  };
}) => {
  return (
    <AgentById agentId={agentId} size={size} stacked={stacked}>
      {(agent) => (
        <Fade in={!!agent} unmountOnExit>
          <Tooltip
            title={agent ? `${agent.name} agent` : "Agent"}
            disableHoverListener={!tooltip}
            {...TooltipProps}
          >
            <Avatar
              src={agent ? `/${agent.config.icon}` : undefined}
              sx={
                size
                  ? {
                      width: size,
                      height: size,
                      transition: "all 0.2s !important",
                      outline: stacked ? "3px solid white" : undefined,
                      outlineOffset: "-1px",
                      marginX: stacked ?? 0,
                      "&:hover": hoverFactor
                        ? {
                            boxShadow: "0 0 2px 2px rgba(0, 0, 0, 0.1)",
                            transform: `scale(${1 + hoverFactor}) translateY(-${
                              (size * hoverFactor) / 2.2
                            }px)`,
                          }
                        : undefined,
                    }
                  : undefined
              }
            ></Avatar>
          </Tooltip>
        </Fade>
      )}
    </AgentById>
  );
};
