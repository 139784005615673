import type { FC } from "react";
import React from "react";
import handlebars from "handlebars";

type AgenticComponentProps = {
  data: Record<string, unknown>;
  template?: string;
  fallback?: React.ReactNode;
};
export const AgenticComponent: FC<AgenticComponentProps> = ({
  data,
  template,
  fallback,
}) => {
  if (!template || !data) {
    return <>{fallback}</>;
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: handlebars.compile(template)(
          {
            // TODO LLM randomly use wrong variables. This is a hack to fix it
            params: data,
            ...data,
          },
          {
            allowCallsToHelperMissing: true,
          },
        ),
      }}
    />
  );
};
