import type { MissionId } from "allgood-schema";
import type { TouchTimelineWidgetProps } from "@/components/rap/touch-timeline-widget";
import { TouchTimelineWidget } from "@/components/rap/touch-timeline-widget";
import type { ContactFilterProps } from "@/components/rap/contact-filter-widget";
import { ContactFilterWidget } from "@/components/rap/contact-filter-widget";

export type BaseProps = {
  missionId: MissionId;
};

export const rapWidgets = {
  // ChainKey => Widget
  COMPANY_JOURNEY_TIMELINE: (props: TouchTimelineWidgetProps) => {
    return <TouchTimelineWidget {...props} />;
  },
  COMPANY_CONTACT: (props: ContactFilterProps) => {
    return <ContactFilterWidget {...props} />;
  },
};
