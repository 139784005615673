import { Box } from "@mui/system";
import type { StreamEvent } from "@langchain/core/dist/tracers/log_stream";
import React, { useEffect, useMemo, useState } from "react";

import "./components/scroll-to-bottom.css";
import { llmWidgets } from "@/pages/task/components/widgets/llm-widgets";
import { TaskConfigUpdate } from "@/pages/campaign/configure/task-config-update";
import { Chat } from "@/pages/task/components/chat";
import { ChatHeader } from "@/pages/task/components/chat-header";
import type { MissionId, ThreadId } from "allgood-schema";
import type { ChatHistory } from "allgood-api/src/routes/trpc/llm";
import type { RouterOutputs } from "@/utils/trpc";
import { trpc } from "@/utils/trpc";
import type { useStream } from "@/pages/task/hooks/useStream";
import { v4 as uuidv4 } from "uuid";
import Loading from "@/components/loading";
import type { TaskWidgetContext } from "@/pages/campaign/configure/team-task";
import { useSearchParams } from "@/hooks/use-search-params";

export const ChatTaskWidget = (context: TaskWidgetContext) => {
  if (!context.task) {
    return <div>Task Instructions not found</div>;
  } else {
    return (
      <ChatTaskPage
        missionId={context.missionId}
        task={context.task}
        deliverable={context.deliverable}
        onToolEnd={context.onToolEnd}
        initialLlmData={{
          taskId: context.task.id,
          deliverableId: context.deliverable.id,
          threadId: undefined,
          chatHistory: [],
        }}
        context={{
          missionId: context.missionId,
          taskId: context.task.id,
          ...context.llmContext,
        }}
        widgets={llmWidgets}
      />
    );
  }
};

export type LLMData = {
  taskId?: string;
  deliverableId?: string;
  threadId?: string;
  chatHistory: ChatHistory[];
};

export type TaskMode = "chat" | "config";

type TaskPageProps = {
  missionId: MissionId;
  task: RouterOutputs["task"]["list"]["records"][number];
  deliverable: RouterOutputs["deliverable"]["get"];
  // MUST Map to the names of the agents in the backend
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: Record<string, any>;
  initialLlmData?: LLMData;
  widgets?: typeof llmWidgets;
  onToolEnd?: (event: StreamEvent) => void;
  sendMessage?: (sendFn: (message: string) => void) => void;
};

export const ChatTaskPage: React.FC<TaskPageProps> = ({
  missionId,
  task,
  deliverable,
  context = {},
  widgets = llmWidgets,
  initialLlmData = {
    taskId: undefined,
    deliverableId: undefined,
    threadId: undefined,
    chatHistory: [],
  },
  onToolEnd,
  sendMessage,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const inThreadId = searchParams.get("threadId");

  const [llmData, setLlmData] = useState<LLMData>({
    ...initialLlmData,
    taskId: task.id,
    deliverableId: deliverable.id,
  });
  const [mode, setMode] = useState<TaskMode>("chat");
  const [interactiveMode, setInteractiveMode] = React.useState(false);
  const [startTask, setStartTask] = React.useState(false);

  const teamConfigId = task.config.teamConfigId!;

  const { data: conversation, isFetching: isLoadingConversation } =
    trpc.llm.getLastConversation.useQuery(
      {
        deliverableId: deliverable.id,
        threadId: (inThreadId as ThreadId) ?? undefined,
      },
      {
        enabled:
          // Do not fetch conversation when startThread exists. It expects to start a new thread automatically.
          !Boolean(searchParams.get("startThread")),
        // switching task is not updated over swtching tasks.
        staleTime: 1,
      },
    );

  const threadId = useMemo(() => {
    if (!conversation) {
      return inThreadId ?? uuidv4();
    } else {
      // Automatically load the first conversation if exists
      setSearchParams(
        { ...searchParams, taskId: task.id, threadId: conversation.threadId },
        { replace: true },
      );
      return conversation.threadId;
    }
  }, [task, inThreadId, conversation]); // add task as dependency. always regenerate uuid if no conversation.

  const [streamHandlers, setStreamHandlers] =
    useState<ReturnType<typeof useStream>>();

  useEffect(() => {
    setInteractiveMode(llmData.chatHistory.length > 0);
  }, [threadId, llmData.chatHistory.length]);

  useEffect(() => {
    if (conversation !== undefined) {
      setLlmData({
        taskId: task.id,
        deliverableId: deliverable.id,
        threadId: threadId,
        chatHistory: conversation?.messages ?? [],
      });
    }
  }, [conversation]);

  const startThread = searchParams.get("startThread");
  useEffect(() => {
    // TODO this is intermediate solution to start conversation.
    //  In the future, we would just kick off a new conversation in API,
    //  and UI just subscribes to API.
    if (startThread) {
      const newThreadId = uuidv4();
      if (llmData.threadId !== newThreadId) {
        console.log("Thread already exists. Clear data and restart");
        setLlmData({
          ...llmData,
          threadId: newThreadId,
          chatHistory: [],
          taskId: llmData.taskId,
        });
      }
      setStartTask(true);
      searchParams.delete("startThread");
      setSearchParams(
        { ...searchParams, taskId: task.id, threadId: newThreadId },
        { replace: true },
      );
    }
  }, [llmData.threadId, startThread]);

  useEffect(() => {
    if (startTask) {
      if (
        streamHandlers?.threadId === llmData.threadId &&
        llmData.chatHistory.length === 0
      ) {
        console.log("Start task");
        streamHandlers?.startStream("Start task");
        setStartTask(false);
      }
    }
  }, [startTask, llmData, streamHandlers]);

  // sendMessage?: (sendFn: (message: string) => void) => void;

  return (
    <Box
      height={"100%"}
      mb={0}
      position={"relative"}
      pb={mode === "chat" ? 8 : 0}
    >
      <Box height={"100%"} pb={0}>
        <Loading loading={isLoadingConversation} fullscreen={false}>
          <Box
            // variant={"outlined"}
            sx={{
              fontSize: 13,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: mode === "chat" ? "16px 16px 0 0" : undefined,
            }}
          >
            <ChatHeader
              mode={mode}
              setMode={setMode}
              interactiveMode={interactiveMode}
              setInteractiveMode={setInteractiveMode}
              teamConfigId={teamConfigId!}
              missionId={missionId!}
              llmData={llmData}
              onClickClear={() => {
                const newThreadId = uuidv4();
                setSearchParams(
                  { ...searchParams, taskId: task.id, threadId: newThreadId },
                  { replace: true },
                );
              }}
              onClickStart={() => {
                const newThreadId = uuidv4();
                setSearchParams(
                  {
                    ...searchParams,
                    taskId: task.id,
                    threadId: newThreadId,
                    startThread: "true",
                  },
                  { replace: true },
                );
              }}
            />
            {/*
            Chat Mode
          */}
            {mode === "chat" && (
              <Chat
                teamConfigId={teamConfigId!}
                onSubmit={setLlmData}
                sendMessage={sendMessage}
                llmData={llmData}
                context={context}
                widgets={widgets}
                onToolEnd={onToolEnd}
                interactiveMode={interactiveMode}
                streamHandlers={(handlers) => {
                  setStreamHandlers(handlers);
                }}
              />
            )}

            {/*
            Config Mode
          */}
            {mode === "config" && teamConfigId && (
              <Box
                px={2}
                py={2}
                flex={1}
                bgcolor={"background.level1"}
                overflow={"auto"}
              >
                <TaskConfigUpdate teamConfigId={teamConfigId} />
              </Box>
            )}
          </Box>
        </Loading>
      </Box>
    </Box>
  );
};
