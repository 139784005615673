import { CompanySidebar } from "@/pages/company/components/company-sidebar";
import { SideChat } from "@/pages/task/components/side-chat";
import {
  Box,
  Button,
  Popover,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import {
  type MilestoneCluster,
  type Opportunity,
} from "allgood-api/src/services/opportunity_engagement.schema";
import { type AGCompanyId, type MissionId } from "allgood-schema";
import { Resizable } from "re-resizable";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import type { ActivityFilters } from "src/components/activity/helpers/activity-filters";
import Loading from "src/components/loading";
import { Seo } from "src/components/seo";
import { trpc } from "src/utils/trpc";
import { z } from "zod";
import { ActivityTimelineWrapper } from "./components/activity-timeline-detail";
import { rapWidgets } from "@/components/rap/rap-widgets";

export const MILESTONE_COUNT_IN_PAGE = 100;
const DAYS_FROM_START = 90;

const UIFilters = z.object({
  milestoneNames: z.array(z.string()),
  tag: z.array(z.string()),
  lookback: z.number(),
});
export type UIFilters = z.infer<typeof UIFilters>;

const CompanyPage = () => {
  const theme = useTheme();
  const { companyId, missionId } = useParams<{
    companyId: AGCompanyId;
    missionId?: MissionId;
  }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedCluster, setSelectedCluster] =
    useState<MilestoneCluster | null>(null);
  const [timelineWidth, setTimelineWidth] = useState(600);
  const [filters, setFilters] = useState<ActivityFilters>({
    accountId: companyId!,
    touchTypes: ["sales", "marketing", "opportunity"],
    offset: undefined,
    keyword: "",
    contacts: [],
    startTime: undefined,
    endTime: undefined,
  });

  const [milestoneFilters, setUiFilters] = useState<UIFilters>({
    milestoneNames: [],
    tag: [],
    lookback: DAYS_FROM_START,
  });
  const [inputMilestoneFilters, setInputMilestoneFilters] = useState<UIFilters>(
    {
      milestoneNames: [],
      tag: [],
      lookback: DAYS_FROM_START,
    },
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentFilter, setCurrentFilter] = useState<
    null | "milestone" | "tag" | "lookback"
  >(null);

  const handlePopoverOpen =
    (filter: "milestone" | "tag" | "lookback") =>
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setCurrentFilter(filter);
    };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setCurrentFilter(null);
  };
  const open = Boolean(anchorEl);

  const [opportunity, setOpportunity] = useState<Opportunity | null>(null);

  const { data: company, isFetching: isLoadingCompany } =
    trpc.company.find.useQuery(
      { filter: { id: companyId! } },
      { enabled: !!companyId },
    );

  const [opportunities] =
    trpc.opportunityEngagement.listOpportunities.useSuspenseQuery({
      filter: {
        companyId: companyId!,
      },
    });

  useEffect(() => {
    if (opportunity) {
      if (searchParams.get("opportunityId") !== opportunity.id) {
        if (opportunity.id) {
          searchParams.set("opportunityId", opportunity.id);
        } else {
          searchParams.delete("opportunityId");
        }
        setSearchParams(searchParams, { replace: true });
      }
    }
  }, [opportunity, searchParams, setSearchParams]);

  useEffect(() => {
    // if opportunity id is in the url, set the opportunity
    if (searchParams.get("opportunityId")) {
      const foundOpportunity = opportunities.opportunities?.find(
        (opportunity) => opportunity.id === searchParams.get("opportunityId"),
      );
      if (foundOpportunity) {
        setOpportunity(foundOpportunity);
        setFilters({
          ...filters,
          startTime: new Date(
            new Date(foundOpportunity.createdAt).getTime() -
              milestoneFilters.lookback * 24 * 60 * 60 * 1000,
          ),
          endTime: foundOpportunity.closedAt
            ? new Date(foundOpportunity.closedAt)
            : new Date(),
        });
      }
    }
  }, [opportunities.opportunities, searchParams, milestoneFilters.lookback]);

  useEffect(() => {
    setSelectedCluster(null);
  }, [filters]);

  const touchTimelineParams = {
    ident: "COMPANY_JOURNEY_TIMELINE",
    params: {
      filter: {
        opportunityId: opportunity?.id,
        agCompanyId: companyId as AGCompanyId,
        agContactIds:
          filters?.contacts && filters?.contacts.length > 0
            ? filters.contacts
            : null,
        startTime: filters.startTime,
        endTime: filters.endTime,
        tags: milestoneFilters.tag,
        names: milestoneFilters.milestoneNames,
      },
      limit: MILESTONE_COUNT_IN_PAGE,
      offset: filters.offset ?? 0,
    },
    missionId: missionId!,
  };

  return (
    <Loading loading={isLoadingCompany} fullscreen={false}>
      <Seo title="Company Page" />

      {company && opportunities ? (
        <Box sx={{ display: "flex", flex: "1 1 0", minHeight: 0 }}>
          <CompanySidebar
            missionId={missionId}
            opportunity={opportunity}
            setOpportunity={setOpportunity}
            company={company}
            opportunities={opportunities.opportunities}
            filters={filters}
            setFilters={setFilters}
          />
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              flexDirection: "column",
            }}
            position={"relative"}
          >
            <Stack direction={"row"} height={"100%"}>
              <Resizable
                enable={{ right: true }}
                size={{
                  width: selectedCluster ? timelineWidth : "100%",
                  height: "100%",
                }}
                onResize={(_e, _direction, ref, _d) => {
                  setTimelineWidth(ref.offsetWidth);
                }}
                minWidth={400}
                style={{ overflow: "hidden" }}
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRight: "1px solid",
                    borderColor: theme.palette.divider,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "100%",
                  }}
                >
                  <Stack direction={"column"} useFlexGap py={0} spacing={6}>
                    <Box component="div" flex={1}>
                      <Typography variant={"subtitle1"} my={2} mb={1} ml={2}>
                        Account Buyer Journey{" "}
                        <SideChat
                          taskKey={
                            "accountBasedAttributionAccountTimelineDetailUI"
                          }
                          llmContext={{
                            functionChain: touchTimelineParams,
                          }}
                        />
                      </Typography>
                      <Stack
                        direction={"row"}
                        gap={1}
                        sx={{ height: "100%", ml: 2 }}
                      >
                        <Button
                          variant={"outlined"}
                          onClick={handlePopoverOpen("milestone")}
                        >
                          Milestone Name
                        </Button>
                        <Button
                          variant={"outlined"}
                          onClick={handlePopoverOpen("tag")}
                        >
                          Tag
                        </Button>
                        <Button
                          variant={"outlined"}
                          onClick={handlePopoverOpen("lookback")}
                        >
                          Lookback
                        </Button>

                        <Popover
                          open={open && currentFilter === "milestone"}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          sx={{ marginTop: 1 }}
                        >
                          <Stack direction={"column"} spacing={0}>
                            <TextField
                              label="Milestones to exclude"
                              value={inputMilestoneFilters.milestoneNames.join(
                                ", ",
                              )}
                              onChange={(e) => {
                                setInputMilestoneFilters({
                                  ...inputMilestoneFilters,
                                  milestoneNames: e.target.value
                                    .split(",")
                                    .map((name) => name.trim()),
                                });
                              }}
                              variant="outlined"
                              sx={{ margin: 2 }}
                            />
                            <Button
                              onClick={() => {
                                if (
                                  inputMilestoneFilters.milestoneNames.length &&
                                  inputMilestoneFilters.milestoneNames[0] !== ""
                                ) {
                                  setUiFilters(inputMilestoneFilters);
                                } else {
                                  setUiFilters({
                                    ...inputMilestoneFilters,
                                    milestoneNames: [],
                                  });
                                }
                              }}
                              sx={{ marginBottom: 2 }}
                            >
                              Apply
                            </Button>
                          </Stack>
                        </Popover>

                        <Popover
                          open={open && currentFilter === "tag"}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          sx={{
                            marginTop: 1,
                            display: "flex",
                            direction: "column",
                          }}
                        >
                          <Stack direction={"column"} spacing={1}>
                            <TextField
                              label="Tags to exclude"
                              value={inputMilestoneFilters.tag.join(", ")}
                              onChange={(e) => {
                                setInputMilestoneFilters({
                                  ...inputMilestoneFilters,
                                  tag: e.target.value
                                    .split(",")
                                    .map((tag) => tag.trim()),
                                });
                              }}
                              variant="outlined"
                              sx={{ margin: 2 }}
                            />
                            <Button
                              onClick={() => {
                                if (
                                  inputMilestoneFilters.tag.length &&
                                  inputMilestoneFilters.tag[0] !== ""
                                ) {
                                  setUiFilters(inputMilestoneFilters);
                                } else {
                                  setUiFilters({
                                    ...inputMilestoneFilters,
                                    tag: [],
                                  });
                                }
                              }}
                              sx={{ marginBottom: 2 }}
                            >
                              Apply
                            </Button>
                          </Stack>
                        </Popover>
                        <Popover
                          open={open && currentFilter === "lookback"}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          sx={{ marginTop: 1 }}
                        >
                          <Stack direction={"column"} spacing={0}>
                            <Stack
                              direction={"row"}
                              spacing={0}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <Slider
                                value={inputMilestoneFilters.lookback}
                                onChange={(_e, value) => {
                                  setInputMilestoneFilters({
                                    ...inputMilestoneFilters,
                                    lookback: value as number,
                                  });
                                }}
                                min={0}
                                max={180}
                                step={1}
                                size="small"
                                sx={{ margin: 2, width: "200px" }}
                              />
                              <Typography variant="body2" sx={{ mr: 2 }}>
                                {inputMilestoneFilters.lookback} days
                              </Typography>
                            </Stack>
                            <Button
                              onClick={() => {
                                setUiFilters(inputMilestoneFilters);
                              }}
                              sx={{ marginBottom: 2 }}
                            >
                              Apply
                            </Button>
                          </Stack>
                        </Popover>
                      </Stack>
                      {rapWidgets["COMPANY_JOURNEY_TIMELINE"]({
                        ...touchTimelineParams,
                        setFilters,
                        onClickCluster: setSelectedCluster,
                      })}
                    </Box>
                  </Stack>
                </Box>
              </Resizable>
              {selectedCluster && (
                <ActivityTimelineWrapper
                  missionId={missionId}
                  selectedCluster={selectedCluster}
                  setSelectedCluster={setSelectedCluster}
                  agContactIds={filters.contacts}
                />
              )}
            </Stack>
          </Box>
        </Box>
      ) : null}
    </Loading>
  );
};

export default CompanyPage;
