import React, { useEffect } from "react";
import AceEditor from "react-ace";
import ace from "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-handlebars";
import jsonWorkerUrl from "ace-builds/src-noconflict/worker-json?url";
import jsWorkerUrl from "ace-builds/src-noconflict/worker-javascript?url";
import { Alert } from "@mui/material";
import { Box } from "@mui/system";

// https://github.com/securingsincity/react-ace/issues/725#issuecomment-1086221818
ace.config.setModuleUrl("ace/mode/json_worker", jsonWorkerUrl);
ace.config.setModuleUrl("ace/mode/javascript_worker", jsWorkerUrl);

type JSONEditorProps = {
  data: unknown;
  onChange?: (data: unknown) => void;
  readOnly?: boolean;
  mode?: "json" | "javascript" | "handlebars";
};
export const JSONEditor: React.FC<JSONEditorProps> = ({
  data,
  onChange,
  readOnly = false,
  mode = "json",
}) => {
  const [error, setError] = React.useState<string | undefined>();
  const [value, setValue] = React.useState<string>(
    mode === "json" ? JSON.stringify(data, null, 2) : (data as string),
  );

  useEffect(() => {
    const strData =
      mode === "json" ? JSON.stringify(data, null, 2) : (data as string);
    if (value !== strData) {
      setValue(strData);
    }
  }, [data]);

  const _onChange = (str: string) => {
    setValue(str);
    try {
      const parsed = mode === "json" ? JSON.parse(str) : str;
      setError(undefined);

      if (mode === "json" && JSON.stringify(parsed) === JSON.stringify(data)) {
        // might be only whitespace changes, no update needed
        return;
      }
      // timeout is a trick to trigger onChange in Async and prevent loop
      setTimeout(() => {
        if (onChange) {
          onChange(parsed);
        }
      }, 0);
    } catch (e) {
      setError((e as Error).message);
    }
  };
  return (
    <Box data-testid={"json-editor"}>
      <AceEditor
        theme={"monokai"}
        width={"100%"}
        minLines={5}
        maxLines={20}
        wrapEnabled={true}
        showPrintMargin={false}
        value={value}
        mode={mode}
        onChange={(str) => {
          _onChange(str);
        }}
        readOnly={readOnly}
      />
      {error ? (
        <Alert severity="error" variant={"filled"} sx={{ marginTop: 1 }}>
          {error}
        </Alert>
      ) : null}
    </Box>
  );
};
