import React, { useEffect, useMemo, useState } from "react";
import type {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import type { TooltipProps } from "recharts";
import {
  Bar,
  BarChart as RechartsBarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import { chartColors } from "@/components/charts/chart-colors";
import Typography from "@mui/material/Typography";
import type { BarChartProps, DimensionalBarChartProps } from "allgood-schema";
import { z } from "zod";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip as MuiTooltip,
} from "@mui/material";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { agTeal } from "src/styles/theme/colors";
import {
  convertToDays,
  dimensionIsTime,
  downloadDataAsCsv,
  parsePercentage,
  round,
  transformString,
} from "./chart-utils";
import { sendEventToAmplitude } from "@/utils/amplitude";

export const SimpleBarChart: React.FC<Omit<BarChartProps, "type">> = ({
  data,
  barSize = 20,
  chartHeight = 340,
  layout = "horizontal",
  percentage = false,
}) => {
  return (
    <ResponsiveContainer height={chartHeight}>
      <RechartsBarChart data={data} layout={layout}>
        <CartesianGrid
          strokeDasharray="2 4"
          horizontal={layout === "horizontal"}
          vertical={layout === "vertical"}
        />
        <XAxis
          dataKey={layout === "horizontal" ? "name" : "value"}
          type={layout === "horizontal" ? "category" : "number"}
          axisLine={false}
          tickLine={false}
          hide={layout === "horizontal"}
        />
        <YAxis
          dataKey={layout === "horizontal" ? "value" : "name"}
          type={layout === "vertical" ? "category" : "number"}
          axisLine={false}
          tickLine={false}
          hide={layout === "vertical"}
          tickFormatter={(value) =>
            percentage ? `${(value * 100).toFixed(2)}%` : `${value}`
          }
          fontSize={14}
        />
        <RechartsTooltip animationDuration={50} content={CustomTooltip} />
        <Legend
          payload={data.map((d, index) => ({
            value: d.name,
            type: "square",
            formatter: () => (
              <Typography
                display={"inline"}
                color={"text.secondary"}
                fontSize={12}
              >
                {d.name.slice(0, 25) + (d.name.length > 25 ? "..." : "")}
              </Typography>
            ),
            color: chartColors[index % chartColors.length],
          }))}
        />
        <Bar
          dataKey={"value"}
          barSize={
            layout === "vertical"
              ? barSize
              : Math.floor(100 / (data.length * 2)) + "%"
          }
        >
          {data.map((entry, index) => (
            <Cell
              fill={chartColors[index % chartColors.length]}
              radius={5}
              key={`cell-${index}`}
            />
          ))}
        </Bar>
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

/** Custom tooltip displayed by Recharts while hovering a data item. */
function CustomTooltip({ payload, label }: TooltipProps<ValueType, NameType>) {
  if (!payload?.[0] || payload[0].value === null) {
    return null;
  }

  const value = payload[0].value as number;

  return (
    <Box
      sx={{
        padding: 1,
        backgroundColor: "background.paper",
        borderRadius: 1,
        boxShadow: 1,
        outline: "1px solid #e0e0e0",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="subtitle2"
          style={{
            borderRadius: 5,
            paddingRight: 10,
          }}
        >
          {label}
        </Typography>
        <Typography variant="body2">
          {payload?.[0] && (
            <>
              {/* parse the value number, and if less than 1, process as percentage */}
              {value < 1 && value > 0
                ? parsePercentage(value)
                : value.toLocaleString()}
            </>
          )}
        </Typography>
      </Stack>
    </Box>
  );
}

// Dimension Chart Component
export const DimensionalBarChart: React.FC<DimensionalBarChartProps> = ({
  data,
  defaults,
  name,
  instanceId,
}) => {
  // Schema for the filter object
  const operatorValue = z.object({
    dimension: z.string(),
    target: z.string(),
    operator: z.enum(["is", "is not"]),
  });

  const _chartFilter = z.object({
    dimension: z.string(),
    metric: z.string(),
    page: z.number(),
    percentage: z.boolean(),
    rolling: z.boolean(),
    dimensionFilterOn: z.boolean(),
    dimensionValueFilter: operatorValue,
  });
  type ChartFilter = z.infer<typeof _chartFilter>;

  // Constants
  const pageSize = 10;
  const defaultFilter: ChartFilter = {
    dimension: "",
    metric: "",
    page: 0,
    percentage: false,
    rolling: false,
    dimensionFilterOn: false,
    dimensionValueFilter: {
      // Hardcoded filter, TODO: make this dynamic
      dimension: "",
      target: "",
      operator: "is",
    },
  };

  // States
  const [selectionFilter, setSelectionFilter] =
    useState<ChartFilter>(defaultFilter);
  const [visualizationData, setVisualizationData] = useState<
    { name: string; value: number }[]
  >([]);
  const [pagedData, setPagedData] = useState<{ name: string; value: number }[]>(
    [],
  );
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Memoized values
  const dimensionKeys = useMemo(() => {
    if (data.length > 0) {
      return Object.keys(data[0].dimension);
    }
    return [];
  }, [data]);

  const metricKeys = useMemo(() => {
    if (data.length > 0) {
      return Object.keys(data[0].metric);
    }
    return [];
  }, [data]);

  // create a memoized map of all the values possible for the dimension per dimension key
  const dimensionValues = useMemo(() => {
    const dimensionValues = new Map<string, Set<string>>();
    data.forEach((row) => {
      dimensionKeys.forEach((key) => {
        const value = row.dimension[key as keyof typeof row.dimension];
        if (value) {
          if (!dimensionValues.has(key)) {
            dimensionValues.set(key, new Set<string>());
          }
          dimensionValues.get(key)?.add(value.toString());
        }
      });
    });
    return dimensionValues;
  }, [data, dimensionKeys]);

  // Apply Filter Method to update the visualization data
  function applyFilters() {
    if (
      data.length > 0 &&
      selectionFilter.dimension &&
      selectionFilter.metric
    ) {
      // Apply dimension value filter if necessary
      let filteredData = data;
      let useFilter = true;

      if (
        selectionFilter.dimensionFilterOn &&
        selectionFilter.dimensionValueFilter
      ) {
        const { dimension, target, operator } =
          selectionFilter.dimensionValueFilter;

        // check that the dimension exists in the data
        filteredData = filteredData.filter((row) => {
          const value = row.dimension[dimension as keyof typeof row.dimension];

          let valueType: "string" | "number" | null;

          if (z.number().safeParse(value).success) {
            valueType = "number";
          } else if (z.string().safeParse(value).success) {
            valueType = "string";
          } else {
            valueType = null;
          }

          if (valueType === null || target === "") {
            useFilter = false;
            return false;
          }

          switch (operator) {
            case "is":
              return valueType === "string"
                ? value.toLowerCase() === target.toLowerCase()
                : value === target;
            case "is not":
              return valueType === "string"
                ? value.toLowerCase() !== target.toLowerCase()
                : value !== target;
            default:
              return true;
          }
        });
      }

      if (!useFilter) {
        filteredData = data;
      }
      // Aggregate the filtered results based on the selected dimension and metric
      let aggregatedData = filteredData.reduce(
        (acc, row) => {
          const dimensionKey =
            row.dimension[
              selectionFilter.dimension as keyof typeof row.dimension
            ];
          const metricValue =
            row.metric[selectionFilter.metric as keyof typeof row.metric];

          const existing = acc.find((item) => item.name === dimensionKey);
          if (existing) {
            existing.value += metricValue;
          } else {
            acc.push({ name: dimensionKey, value: metricValue });
          }
          return acc;
        },
        [] as { name: string; value: number }[],
      );

      // Sort the aggregated data
      if (dimensionIsTime(aggregatedData.map((d) => d.name))) {
        aggregatedData.sort(
          (a, b) => convertToDays(a.name) - convertToDays(b.name),
        );
      } else {
        aggregatedData.sort((a, b) => b.value - a.value);
      }

      // Transform string names
      aggregatedData = aggregatedData.map((item) => ({
        ...item,
        name: transformString(item.name),
      }));

      // Calculate total
      const total = aggregatedData.reduce((acc, item) => acc + item.value, 0);

      // Apply rolling calculation if necessary
      if (
        selectionFilter.rolling &&
        dimensionIsTime(aggregatedData.map((d) => d.name))
      ) {
        aggregatedData = aggregatedData.map((item, index) => {
          const rollingValue = aggregatedData
            .slice(0, index + 1)
            .reduce((acc, item) => acc + item.value, 0);
          return { name: "≤ " + item.name, value: rollingValue };
        });
      }

      // Apply percentage calculation if necessary
      if (selectionFilter.percentage) {
        const divisor = selectionFilter.rolling
          ? total
          : aggregatedData.reduce((acc, item) => acc + item.value, 0);
        aggregatedData = aggregatedData.map((item) => ({
          ...item,
          value: round(item.value / divisor, 4),
        }));
      }

      // Update state
      setVisualizationData(aggregatedData);

      // Update paged data
      const start = selectionFilter.page * pageSize;
      const end = start + pageSize;
      setPagedData(aggregatedData.slice(start, end));
    }
  }

  // Effects
  useEffect(() => {
    if (dimensionKeys.length > 0 && metricKeys.length > 0) {
      if (defaults?.dimension && dimensionKeys.includes(defaults.dimension)) {
        setSelectionFilter({
          ...defaultFilter,
          dimension: defaults.dimension,
          metric: metricKeys[0],
        });
      } else {
        setSelectionFilter({
          ...defaultFilter,
          dimension: dimensionKeys[0],
          metric: metricKeys[0],
        });
      }
    }
  }, [dimensionKeys, metricKeys]);

  useEffect(() => {
    if (defaults?.filter) {
      setSelectionFilter((prev) => ({
        ...prev,
        ...defaults.filter,
        dimensionFilterOn: true,
      }));
    }
  }, [defaults]);

  useEffect(() => {
    applyFilters();
  }, [data, selectionFilter, dimensionKeys, metricKeys]);

  useEffect(() => {
    setSelectionFilter((prev) => ({
      ...prev,
      dimensionValueFilter: {
        dimension: Array.from(dimensionValues.keys())[0],
        target:
          dimensionValues
            .get(Array.from(dimensionValues.keys())[0])
            ?.values()
            .next().value ?? "",
        operator: "is",
      },
    }));
  }, [dimensionValues]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          gap: 1,
          mb: 2,
        }}
      >
        {visualizationData.length > pageSize && (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: "center",
              marginRight: "auto",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setSelectionFilter({
                  ...selectionFilter,
                  page: selectionFilter.page - 1,
                });

                sendEventToAmplitude({
                  event_type: "Navigate Dimension Chart Page",
                  event_properties: {
                    parentId: instanceId,
                    parentName: name,
                    direction: "previous",
                    dimension: selectionFilter.dimension,
                    metric: selectionFilter.metric,
                    page: selectionFilter.page,
                  },
                });
              }}
              disabled={selectionFilter.page === 0}
              size="small"
            >
              <NavigateBeforeIcon />
            </Button>
            <Typography
              variant={"overline"}
              sx={{
                fontSize: 14,
                color: "text.secondary",
              }}
            >
              {selectionFilter.page + 1}/
              {Math.floor(visualizationData.length / pageSize) + 1}
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                setSelectionFilter({
                  ...selectionFilter,
                  page: selectionFilter.page + 1,
                });
                sendEventToAmplitude({
                  event_type: "Navigate Dimension Chart Page",
                  event_properties: {
                    parentId: instanceId,
                    parentName: name,
                    direction: "next",
                    dimension: selectionFilter.dimension,
                    metric: selectionFilter.metric,
                    page: selectionFilter.page,
                  },
                });
              }}
              disabled={
                selectionFilter.page ===
                Math.floor(visualizationData.length / pageSize)
              }
              size="small"
            >
              <NavigateNextIcon />
            </Button>
          </Stack>
        )}
        <Stack sx={{ color: agTeal[500] }}>
          <Typography
            variant="caption"
            sx={{
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Dim: </span>
            {transformString(selectionFilter.dimension)}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Metric: </span>
            {transformString(selectionFilter.metric)}
          </Typography>
        </Stack>
        <MuiTooltip title="Settings">
          <Button
            size="small"
            variant="outlined"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <SettingsIcon fontSize="small" />
          </Button>
        </MuiTooltip>
        <MuiTooltip title="Expand Details">
          <Button
            size="small"
            variant="outlined"
            onClick={() => setDetailsOpen((prev) => !prev)}
          >
            {detailsOpen ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
          </Button>
        </MuiTooltip>
        <MuiTooltip title="Export CSV">
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              downloadDataAsCsv(data);
              sendEventToAmplitude({
                event_type: "Download Dimension Chart Data",
                event_properties: {
                  parentId: instanceId,
                  parentName: name,
                  dimension: selectionFilter.dimension,
                  metric: selectionFilter.metric,
                },
              });
            }}
          >
            <DownloadRoundedIcon />
          </Button>
        </MuiTooltip>
      </Box>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack direction="row" spacing={2} p={2}>
          <Stack
            sx={{
              justifyContent: "flex-end",
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                gap: 1,
                padding: 0.5,
              }}
            >
              <Checkbox
                size="small"
                checked={selectionFilter.percentage}
                onClick={() => {
                  setSelectionFilter({
                    ...selectionFilter,
                    percentage: !selectionFilter.percentage,
                  });
                  sendEventToAmplitude({
                    event_type: "Toggle Dimension Chart Percentage",
                    event_properties: {
                      parentId: instanceId,
                      parentName: name,
                      dimension: selectionFilter.dimension,
                      metric: selectionFilter.metric,
                      percentage: !selectionFilter.percentage,
                    },
                  });
                }}
              />
              <InputLabel>Percentages</InputLabel>
            </Stack>
            <Stack
              direction={"row"}
              sx={{
                gap: 1,
                padding: 0.5,
              }}
            >
              <Checkbox
                size="small"
                checked={selectionFilter.rolling}
                onClick={() => {
                  setSelectionFilter({
                    ...selectionFilter,
                    rolling: !selectionFilter.rolling,
                  });
                  sendEventToAmplitude({
                    event_type: "Toggle Dimension Chart Rolling",
                    event_properties: {
                      parentId: instanceId,
                      parentName: name,
                      dimension: selectionFilter.dimension,
                      metric: selectionFilter.metric,
                      rolling: !selectionFilter.rolling,
                    },
                  });
                }}
                disabled={
                  !dimensionIsTime(pagedData.map((d) => d.name)) ||
                  selectionFilter.metric.includes("unique")
                }
              />
              <InputLabel>Rolling</InputLabel>
            </Stack>
            <Stack
              direction={"row"}
              sx={{
                gap: 1,
                padding: 0.5,
              }}
            >
              <Checkbox
                size="small"
                checked={selectionFilter.dimensionFilterOn}
                onClick={() => {
                  setSelectionFilter({
                    ...selectionFilter,
                    dimensionFilterOn: !selectionFilter.dimensionFilterOn,
                  });
                  sendEventToAmplitude({
                    event_type: "Toggle Dimension Chart Filter",
                    event_properties: {
                      parentId: instanceId,
                      parentName: name,
                      dimension: selectionFilter.dimension,
                      metric: selectionFilter.metric,
                      filter: !selectionFilter.dimensionFilterOn,
                    },
                  });
                }}
                disabled={dimensionValues.size === 0}
              />
              <InputLabel>Filter</InputLabel>
            </Stack>
          </Stack>
          <Stack>
            <InputLabel>Dimension</InputLabel>
            <Select
              value={selectionFilter.dimension}
              onChange={(e) => {
                setSelectionFilter((prev) => ({
                  ...prev,
                  dimension: e.target.value as string,
                  page: 0,
                  rolling: false,
                  percentage: false,
                }));

                sendEventToAmplitude({
                  event_type: "Change Dimension Chart Dimension",
                  event_properties: {
                    parentId: instanceId,
                    parentName: name,
                    oldDimension: selectionFilter.dimension,
                    newDimension: e.target.value as string,
                    metric: selectionFilter.metric,
                  },
                });
              }}
            >
              {dimensionKeys.map((dimension) => (
                <MenuItem key={dimension} value={dimension}>
                  {transformString(dimension)}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Stack>
            <InputLabel>Metric</InputLabel>
            <Select
              value={selectionFilter.metric}
              onChange={(e) => {
                setSelectionFilter({
                  ...selectionFilter,
                  metric: e.target.value as string,
                  page: 0,
                  rolling: false,
                  percentage: false,
                });

                sendEventToAmplitude({
                  event_type: "Change Dimension Chart Metric",
                  event_properties: {
                    parentId: instanceId,
                    parentName: name,
                    dimension: selectionFilter.dimension,
                    oldMetric: selectionFilter.metric,
                    newMetric: e.target.value as string,
                  },
                });
              }}
            >
              {metricKeys.map((metric) => (
                <MenuItem key={metric} value={metric}>
                  {transformString(metric)}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
      </Popover>
      <SimpleBarChart
        data={pagedData}
        percentage={selectionFilter.percentage}
      />
      <Collapse
        in={selectionFilter.dimensionFilterOn}
        timeout="auto"
        unmountOnExit
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            gap: 2,
            mt: 2,
            border: "1px solid #e0e0e0",
            borderRadius: 1,
            padding: 1,
          }}
        >
          <Autocomplete
            options={Array.from(dimensionValues.keys())}
            renderInput={(params) => <TextField {...params} />}
            value={selectionFilter.dimensionValueFilter?.dimension}
            disableClearable
            size="small"
            fullWidth
            onChange={(e, value) => {
              setSelectionFilter({
                ...selectionFilter,
                dimensionValueFilter: {
                  dimension: value as string,
                  target:
                    dimensionValues
                      .get(value as string)
                      ?.values()
                      .next().value ?? "",
                  operator:
                    selectionFilter.dimensionValueFilter?.operator ?? "is",
                },
              });
            }}
          />
          <Autocomplete
            options={["is", "is not"]}
            renderInput={(params) => <TextField {...params} />}
            value={selectionFilter.dimensionValueFilter?.operator}
            disableClearable
            size="small"
            sx={{
              width: 250,
            }}
            onChange={(e, value) => {
              setSelectionFilter({
                ...selectionFilter,
                dimensionValueFilter: {
                  dimension: selectionFilter.dimensionValueFilter?.dimension,
                  target: selectionFilter.dimensionValueFilter?.target,
                  operator: value as "is" | "is not",
                },
              });
            }}
          />
          <Autocomplete
            options={Array.from(
              dimensionValues.get(
                selectionFilter.dimensionValueFilter?.dimension,
              ) ?? [],
            )}
            renderInput={(params) => <TextField {...params} />}
            value={selectionFilter.dimensionValueFilter?.target}
            disableClearable
            size="small"
            fullWidth
            onChange={(e, value) => {
              setSelectionFilter({
                ...selectionFilter,
                dimensionValueFilter: {
                  dimension: selectionFilter.dimensionValueFilter?.dimension,
                  target: value as string,
                  operator:
                    selectionFilter.dimensionValueFilter?.operator ?? "is",
                },
              });
            }}
          />
        </Stack>
      </Collapse>
      <Collapse in={detailsOpen} timeout="auto" unmountOnExit sx={{}}>
        <TableContainer
          sx={{
            outline: "1px solid #e0e0e0",
            borderRadius: 1,
            boxShadow: 1,
            mt: 2,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {transformString(selectionFilter.dimension)}
                </TableCell>
                <TableCell>
                  <span style={{ fontStyle: "italic" }}>Sum of</span>{" "}
                  {" " + transformString(selectionFilter.metric)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                maxHeight: 300,
                overflow: "auto",
              }}
            >
              {pagedData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {selectionFilter.percentage
                      ? parsePercentage(row.value)
                      : row.value.toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  );
};
