import { index, jsonb, timestamp, varchar } from "drizzle-orm/pg-core";
import { AGCompanyId, AGContactId, MembershipId } from "../../agid";
import { agid } from "../custom-types";
import { createBaseWithId } from "./base";
import { agRuntime } from "./schema";

export const Membership = agRuntime.table(
  "membership",
  {
    ...createBaseWithId<MembershipId>(),

    /** The membership begins at this moment, and ends at the next-greatest validFrom for this companyId and contactId. */
    validFrom: timestamp("valid_from").notNull(),

    /** The UUID of the config object this object represents a membership in. */
    memberOf: agid("member_of").notNull(),

    /** The type of the config object this membership is for. */
    memberType: varchar("member_type").notNull(),

    /** JSON payload of the membership, whose type is discriminated by `memberType` */
    content: jsonb("content").$type<unknown>().notNull(),

    /** A label representing the membership state. NULL values are allowed, and
     *  always mean the same thing as the lack of a membership record. */
    label: varchar("label"),

    /** The principal which is a member in the config object. */
    companyId: agid("ag_company_id").$type<AGCompanyId>().notNull(),
    contactId: agid("ag_contact_id").$type<AGContactId | null>(),
  },
  (t) => ({
    // Support 'principal' current-memberships queries
    // also (tenantId, companyId)
    currentByPrincipal: index("membership_currentByPrincipal").on(
      t.tenantId,
      t.companyId,
      t.contactId,
      t.memberType,
      t.validFrom, // Must come last to index the max(validFrom)
    ),

    // Support 'principalMemberOf' current-memberships queries
    // also (tenantId, memberOf),
    currentByPrincipalMemberOf: index(
      "membership_currentByPrincipalMemberOf",
    ).on(
      t.tenantId,
      t.memberOf,
      t.companyId,
      t.contactId,
      t.memberType,
      t.validFrom, // Must come last to index the max(validFrom)
    ),

    // ContactID is highly selective, so we can index it
    // slightly-less-efficiently.
    contactId: index("membership_contactId").on(t.tenantId, t.contactId),

    // Paginate through memberships.
    // Also indexes (tenantId, memberType),
    memberType: index("membership_memberType").on(
      t.tenantId,
      t.memberType,
      t.createdAt,
    ),
  }),
);
