import { Box, Button, Collapse } from "@mui/material";
import React, { useState } from "react";
import type { ActivityModel } from "src/components/activity/activity-types";
import { PropertyList } from "src/components/property-list";
import { PropertyListItem } from "src/components/property-list-item";

const Activity = ({
  activity,
  truncate = false,
}: {
  activity: ActivityModel;
  truncate?: boolean | number;
}) => {
  const [expanded, setExpanded] = useState(false);

  const getContent = (activity: ActivityModel) => {
    if (!activity.content) return null;

    const properties = Object.entries(activity.content);

    return properties.length > 0 ? (
      <Box
        sx={{
          border: 1,
          borderColor: "divider",
          borderRadius: 1,
        }}
      >
        <PropertyList>
          {properties
            .filter(([_, value]) => value !== "" && value !== null)
            .map(([key, value]) => (
              <PropertyListItem
                key={key}
                align="horizontal"
                divider
                label={key}
                value={value}
              />
            ))}
        </PropertyList>
      </Box>
    ) : null;
  };

  if (activity.type === "email" && activity.content) {
    if (!activity.content.EmailThreads) {
      activity.content.EmailThreads = [
        {
          body: activity.content.Description ?? activity.content.body,
        },
      ];
    }

    // TODO email_click activity has empty body in some reason.
    if (
      activity.content.EmailThreads?.filter((thread: { body: string }) =>
        Boolean(thread.body),
      ).length === 0
    ) {
      return getContent(activity);
    }

    return [...activity.content.EmailThreads].reverse().reduce(
      (result, thread, i) => {
        return (
          <>
            {i < activity.content?.EmailThreads.length - 1 ? (
              <>
                <p>
                  <b style={{ color: "#666" }}>{thread.headers}</b>
                </p>
                {thread.body}
                <blockquote
                  style={{
                    marginBlockStart: "1em",
                    marginBlockEnd: "1em",
                    borderLeft: "1px solid #CCC",
                    paddingLeft: "10px",
                  }}
                >
                  {result}
                </blockquote>
              </>
            ) : (
              <>
                {thread.body?.substring(
                  0,
                  truncate && !expanded ? truncate : undefined,
                )}
                {truncate && !expanded ? "..." : null}
                {activity.content?.EmailThreads.length > 1 ? (
                  <div>
                    <Button
                      size={"small"}
                      sx={{ textDecoration: "underline" }}
                      onClick={() => setExpanded(!expanded)}
                    >
                      ...{!expanded ? "Show" : "Hide"} thread
                    </Button>
                  </div>
                ) : null}
                <Collapse in={expanded}>
                  <blockquote
                    style={{
                      marginBlockStart: "1em",
                      marginBlockEnd: "1em",
                      borderLeft: "1px solid #CCC",
                      paddingLeft: "10px",
                    }}
                  >
                    {result}
                  </blockquote>
                </Collapse>
              </>
            )}
          </>
        );
      },
      <></>,
    );
  } else {
    return getContent(activity);
  }
};

export default Activity;
