type IsParameter<Part> = Part extends `:${infer ParamName}` ? ParamName : never;
type FilteredParts<Path> = Path extends `${infer PartA}/${infer PartB}`
  ? IsParameter<PartA> | FilteredParts<PartB>
  : IsParameter<Path>;
type Params<Path> = {
  [Key in FilteredParts<Path>]: string;
};

export const buildRoute = <T extends string>(path: T, params: Params<T>) => {
  return path.replace(/:([^\/]+)/g, (match, key: FilteredParts<T>) => {
    return params[key];
  });
};
