import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import CreditCardIcon from "@mui/icons-material/CreditCardOutlined";
import UserIcon from "@mui/icons-material/PersonOutlineOutlined";
import type { FC } from "react";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";

import { RouterLink } from "@/components/core/router-link";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { SwitchAccountOutlined } from "@mui/icons-material";
import { checkIsSuperUser } from "src/utils/auth";

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const [isCopied, setIsCopied] = useState(false);

  const router = useRouter();
  const auth = useAuth();
  const user = auth.user;
  const isAllGood = checkIsSuperUser(user);

  const handleAccessToken = useCallback(async (): Promise<void> => {
    const token = auth.token;
    try {
      await navigator.clipboard.writeText(token ?? "");
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000); // Reset after 3 seconds
    } catch (err) {
      console.error("Failed to copy token: ", err);
    }
  }, [auth, router]);

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      onClose?.();

      // switch (auth.issuer) {
      //   case Issuer.JWT: {
      //     await auth.signOut();
      //     break;
      //   }
      //
      //   default: {
      //     console.warn("Using an unknown Auth Issuer, did not log out");
      //   }
      // }
      await auth.signOut();

      router.push(paths.index);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  }, [auth, router, onClose]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 240 } }}
      {...other}
    >
      <Box component="div" sx={{ p: 2 }}>
        <Typography variant="body1">
          {user?.profile.firstName} {user?.profile.lastName}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.email}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.tenant.name} [ {user?.tenant.shortId} ]
        </Typography>
        <Typography color="text.secondary" variant="body2">
          Role: {user?.userRole}
        </Typography>
        {user?.userType === "SUPERUSER" && (
          <Typography color="red" variant="body2" fontWeight={"bold"}>
            [SUPERUSER]
          </Typography>
        )}
      </Box>
      <Divider />
      <Box component="div" sx={{ p: 1 }}>
        <ListItemButton
          component={RouterLink}
          href={paths.settings.account}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <SvgIcon fontSize="small">
              <UserIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body2">Profile</Typography>}
          />
        </ListItemButton>

        <ListItemButton
          component={RouterLink}
          href={paths.dashboard.index}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <SvgIcon fontSize="small">
              <CreditCardIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            onClick={handleAccessToken}
            primary={
              <Typography variant="body2">
                {isCopied ? "Copied" : "Copy Access Token"}
              </Typography>
            }
          />
        </ListItemButton>

        {isAllGood ? (
          <ListItemButton
            component={RouterLink}
            href={paths.profile.switchTenant}
            onClick={onClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon sx={{ mr: 1 }}>
              <SvgIcon fontSize="small">
                <SwitchAccountOutlined />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body2">Switch Tenant</Typography>}
            />
          </ListItemButton>
        ) : null}
      </Box>
      <Divider sx={{ my: "0 !important" }} />
      <Box
        component="div"
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "center",
        }}
      >
        <Button color="inherit" onClick={handleLogout} size="small">
          Logout
        </Button>
      </Box>
    </Popover>
  );
};
