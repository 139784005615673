import { jsonb, unique, varchar } from "drizzle-orm/pg-core";
import { sql } from "drizzle-orm";
import type {
  ActionId,
  BusinessObjectiveId,
  BusinessObjectiveMetricsId,
  CampaignId,
  MilestoneId,
  RecommendationId,
} from "../../agid";
import { agid } from "../custom-types";
import { config, createBaseWithId, ownership } from "./base";
import { agConfig, agRuntime } from "./schema";

export const Action = agConfig.table(
  "action",
  {
    ...createBaseWithId<ActionId>(),
    ...ownership,
    ...config,
  },
  (table) => {
    return {
      uniqueKey: unique("uq_action_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

export const BusinessObjective = agConfig.table(
  "business_objective",
  {
    ...createBaseWithId<BusinessObjectiveId>(),
    ...ownership,
    ...config,

    /** Short ID for the business objective */
    shortId: varchar("short_id")
      .unique()
      .notNull()
      .default(
        // TODO: remove after migration
        sql`upper(substr(sha256(gen_random_uuid()::text::bytea)::text, 10, 8))`,
      ),
  },
  (table) => {
    return {
      uniqueKey: unique("uq_business_objective_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

export const Campaign = agConfig.table(
  "campaign",
  {
    ...createBaseWithId<CampaignId>(),
    ...ownership,
    ...config,

    /** Business Objective ID for the campaign*/
    businessObjectiveId: agid(
      "business_objective_id",
    ).$type<BusinessObjectiveId>(),
  },
  (table) => {
    return {
      uniqueKey: unique("uq_campaign_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

export const Milestone = agConfig.table(
  "milestone",
  {
    ...createBaseWithId<MilestoneId>(),
    ...ownership,
    ...config,
  },
  (table) => {
    return {
      uniqueKey: unique("uq_milestone_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

export const BusinessObjectiveMetrics = agRuntime.table(
  "business_objective_metrics",
  {
    ...createBaseWithId<BusinessObjectiveMetricsId>(),
    ...ownership,

    /** Business Objective ID for the  metric */
    businessObjectiveId: agid(
      "business_objective_id",
    ).$type<BusinessObjectiveId>(),
    metrics: jsonb("metrics").$type<unknown>().notNull(),
  },
);

export const Recommendation = agConfig.table(
  "recommendation",
  {
    ...createBaseWithId<RecommendationId>(),
    ...ownership,
    ...config,
  },
  (table) => {
    return {
      uniqueKey: unique("uq_recommendation_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);
