import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { SuspenseLoading } from "@/components/suspense-loading";
import { capitalize, MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import { FilterPopover, useFilterContext } from "@/components/filter-button";
import { trpc } from "@/utils/trpc";
import { AgentId } from "allgood-schema";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import FormLabel from "@mui/material/FormLabel";

export function GuidePopover({
  selectedGuides,
}: {
  selectedGuides: AgentId[];
}): React.JSX.Element {
  const {
    anchorEl,
    onApply,
    onClose,
    open,
    value: initialValue,
  } = useFilterContext();
  const [value, setValue] = React.useState<string>("");

  React.useEffect(() => {
    setValue((initialValue as string | undefined) ?? "");
  }, [initialValue]);

  const [agents] = trpc.agent.getAgents.useSuspenseQuery({});

  const handleApply = (value: string) => {
    onApply(value);
    setValue("");
  };

  return (
    <FilterPopover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      title="Select Guide to add"
    >
      <FormControl>
        <SuspenseLoading>
          <Select
            variant="outlined"
            value={value}
            onChange={(e) => setValue(e.target.value as string)}
          >
            {agents.records.map((agent) => (
              <MenuItem
                key={agent.id}
                value={agent.id}
                disabled={selectedGuides.some(
                  (selectedId) => selectedId === agent.id,
                )}
              >
                <Stack direction={"row"} py={1} alignItems={"center"}>
                  <ListItemIcon>
                    <Avatar src={"/" + agent.config.icon}></Avatar>
                  </ListItemIcon>
                  <ListItemText
                    sx={{ pl: 1 }}
                    primary={capitalize(agent.name.toLocaleLowerCase())}
                  />
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </SuspenseLoading>
      </FormControl>
      <Button
        onClick={() => {
          handleApply(value);
        }}
        variant="contained"
      >
        Add
      </Button>
    </FilterPopover>
  );
}

export function GuideSelector({
  onAddGuide,
}: {
  onAddGuide: (guideId: string) => void;
}): React.JSX.Element {
  const [value, setValue] = React.useState<string>("");
  const [agents] = trpc.agent.getAgents.useSuspenseQuery({});

  const handleSelect = (value: string) => {
    if (value) {
      onAddGuide(value);
      setValue(value);
    }
  };

  return (
    <FormControl>
      <FormLabel htmlFor="guide-select">Select a Lead Guide</FormLabel>
      <SuspenseLoading>
        <Select
          variant="outlined"
          value={value}
          onChange={(e) => handleSelect(e.target.value as string)}
          sx={{ width: "300px" }}
        >
          {agents.records.map((agent) => (
            <MenuItem key={agent.id} value={agent.id}>
              <Stack direction={"row"} py={1} alignItems={"center"}>
                <ListItemIcon>
                  <Avatar src={"/" + agent.config.icon}></Avatar>
                </ListItemIcon>
                <ListItemText
                  sx={{ pl: 1 }}
                  primary={capitalize(agent.name.toLocaleLowerCase())}
                />
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </SuspenseLoading>
    </FormControl>
  );
}
