import { ChatTaskWidget } from "@/pages/task/chat-task-page";
import { Alert, Box, Card, Divider } from "@mui/material";
import type { FC } from "react";
import React, { useState } from "react";
import { useParams } from "react-router";
import type { RouterOutputs } from "src/utils/trpc";
import { trpc } from "src/utils/trpc";
import { SuspenseLoading } from "@/components/suspense-loading";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import type { MissionId, TaskId } from "allgood-schema";
import { X } from "@phosphor-icons/react/dist/csr/X";
import { useRouter } from "@/hooks/use-router";
import { buildRoute } from "@/utils/route";
import { paths } from "@/paths";
import ServiceTaskWidget from "@/pages/campaign/configure/service-task";
import AuditPreReqHumanTasksForm from "@/pages/campaign/tasks/audit-pre-req";
import DataPipelinePreReqHumanTasksForm from "@/pages/campaign/tasks/datapipeline-pre-req";
import BuyerJourneyPreReqHumanTasksForm from "@/pages/campaign/tasks/buyer-journey-pre-req";
import { CheckmarkIcon } from "react-hot-toast";
import HumanTaskWidget from "@/pages/campaign/configure/human-task";
import ParentTaskWidget from "@/pages/campaign/configure/parent-task";
import AccountBasedAttributionSetup from "@/pages/campaign/tasks/account-attribution-input";
import { CheckSquareOffset } from "@phosphor-icons/react/dist/csr/CheckSquareOffset";
import { Deliverable } from "@/pages/campaign/configure/deliverables";
import MktoAssistTemplateInput from "../tasks/mktoassist-template-input";
import type { StreamEvent } from "@langchain/core/dist/tracers/log_stream";

export type TaskWidgetContext = {
  missionId: MissionId;
  task: RouterOutputs["task"]["list"]["records"][number];
  deliverable: RouterOutputs["deliverable"]["get"];
  /**
   * This one is for the HumanTaskWidget context and is a bit complicated.
   * 1. We take this data, submit a TPRC to the backend where we run the summary and the data through the deliverable.
   * 2. IF we get a response for summary back, we can share it, else we just say its submitted.
   *
   * NOTE: just because we have submitted, does not mean the system has accepted, there can be something an agent could
   * ask for the future.
   * @param data
   */
  submitDeliverable: (
    data: Record<string, unknown> | undefined,
  ) => Promise<string>;

  /** optionally you can use this to show a message to the user */
  showMessage: (message: string) => void;

  /** optional for chat */
  onToolEnd?: (stream: StreamEvent) => void;

  llmContext?: object;
};

/**
 *
 * NOTE: This is the mapping from taskWidget to the actual widget.
 *
 * You can use the standard ones, or add customs ones here.
 *
 */
export const taskWidgets: Record<
  string,
  (context: TaskWidgetContext) => React.ReactElement
> = {
  /**
   * Standard Task Widgets go here..
   *
   */
  ["ServiceTaskWidget"]: ServiceTaskWidget,
  ["HumanTaskWidget"]: HumanTaskWidget,
  ["ParentTaskWidget"]: ParentTaskWidget,
  ["TeamTaskWidget"]: ChatTaskWidget,

  /**
   * Add your custom widget here
   */
  ["audit.preReqs"]: AuditPreReqHumanTasksForm,
  ["datapipline.preReqs"]: DataPipelinePreReqHumanTasksForm,
  ["buyerjourney.preReqs"]: BuyerJourneyPreReqHumanTasksForm,
  ["accountBasedAttributionSetup"]: AccountBasedAttributionSetup,
  ["mktoassist.templateFolder"]: MktoAssistTemplateInput,
};

/**
 * Simplified this widget to ONLY be the Team Task Page.
 *
 * @constructor
 */
type TeamTaskProps = {
  taskId?: TaskId;
  llmContext?: object;
  onClose?: () => void;
  onSubmitDeliverable?: () => void;
  onToolEnd?: (stream: StreamEvent) => void;
};
export const TeamTask: FC<TeamTaskProps> = ({
  taskId: propTaskId,
  llmContext,
  onClose,
  onSubmitDeliverable,
  onToolEnd,
}) => {
  const { missionId, taskId } = useParams<{
    missionId: MissionId;
    taskId: TaskId;
  }>();
  const router = useRouter();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showDeliverable, setShowDeliverable] = useState(false);

  if (!propTaskId && !taskId) {
    return <div>Task was not provided</div>;
  }

  const [task] = trpc.task.get.useSuspenseQuery({
    id: propTaskId ?? taskId!,
  });

  const { data: deliverable, refetch: refetchDeliverable } =
    trpc.deliverable.getByTaskId.useQuery({
      taskId: propTaskId ?? taskId!,
    });

  const submitTask = trpc.task.submit.useMutation();

  if (!task || !missionId) {
    return <div>Task not found</div>;
  }

  if (!deliverable) {
    return <div>Deliverable not found</div>;
  }

  const showMessage = (message: string) => {
    setSnackbarMessage(message);
  };
  const handleSubmit = async (data: Record<string, unknown> | undefined) => {
    const response = await submitTask.mutateAsync({
      id: deliverable.id,
      status: "IN_REVIEW",
      deliverable: {
        summary: "Submitted deliverable....",
        data: data,
      },
    });
    const resp = response.deliverable
      ? response.deliverable.summary || "Submitted deliverable...."
      : "Submitted deliverable....";
    setSnackbarMessage(resp);
    refetchDeliverable();
    if (onSubmitDeliverable) {
      onSubmitDeliverable();
    }
    return resp;
  };
  const context: TaskWidgetContext = {
    missionId,
    task: task,
    deliverable: deliverable,
    submitDeliverable: handleSubmit,
    showMessage: showMessage,
    onToolEnd: onToolEnd,
    llmContext,
  };

  const Widget = taskWidgets[task.taskWidget];

  const taskWidget = Widget ? (
    <Widget {...context} />
  ) : (
    <> Task Widget Not Found </>
  );

  const deliverableWidget = (
    <Box>
      {
        // wrap with Box otherwise scroll does not work
      }
      <Deliverable
        missionId={missionId}
        deliverable={deliverable}
        submitDeliverable={async (_data) => {
          refetchDeliverable();
          if (onSubmitDeliverable) {
            onSubmitDeliverable();
          }
          return "";
        }}
      />
    </Box>
  );

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        mr: 2,
        pt: 2,
      }}
      variant={"outlined"}
    >
      <Stack direction={"row"} p={2} px={3}>
        <Box flex={1}>
          <Typography variant={"h6"}>{task.name}</Typography>
          <Typography variant={"body2"} color={"text.secondary"}>
            {task.description}
          </Typography>
        </Box>
        <Stack direction={"row"} spacing={2}>
          <Button
            color={showDeliverable ? "primary" : "secondary"}
            startIcon={
              <CheckSquareOffset style={{ marginRight: -5 }} size={18} />
            }
            onClick={() => setShowDeliverable(!showDeliverable)}
          >
            Deliverables
          </Button>
          <Button
            color={"secondary"}
            startIcon={
              <X
                style={{
                  marginRight: -5,
                }}
                size={18}
              />
            }
            onClick={() => {
              if (onClose) {
                onClose();
              } else {
                router.push(
                  buildRoute(paths.missions.configure, {
                    missionId: missionId!,
                  }),
                );
              }
            }}
          >
            Close
          </Button>
        </Stack>
      </Stack>
      {snackbarMessage && (
        <Alert icon={<CheckmarkIcon />} severity="success">
          {snackbarMessage}
        </Alert>
      )}
      <Divider sx={{ bgcolor: "white" }} />
      <SuspenseLoading>
        <Box
          height={"100%"}
          borderRight={"1px solid"}
          sx={{
            borderColor: "divider",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {showDeliverable ? deliverableWidget : taskWidget}
        </Box>
      </SuspenseLoading>
    </Card>
  );
};
