import { Stack } from "@mui/material";
import { RouterLink } from "@/components/core/router-link";
import { paths } from "@/paths";
import Button from "@mui/material/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { buildRoute } from "@/utils/route";
import { BackButton } from "@/components/back-button";
import * as React from "react";
import { useEffect } from "react";
import { trpc } from "@/utils/trpc";
import { Gear } from "@phosphor-icons/react/dist/csr/Gear";
import { MissionId } from "allgood-schema";

export const DashboardNav = () => {
  const navigate = useNavigate();
  const { missionId: rawMissionId } = useParams();
  const missionId = MissionId.nullish().parse(rawMissionId);
  const { pathname } = useLocation();

  const [dashboards] = trpc.mission.dashboards.useSuspenseQuery({
    id: missionId!,
  });

  const dashboardPath = buildRoute(paths.missions.dashboard, {
    missionId: missionId!,
  });
  const configurePath = buildRoute(paths.missions.configure, {
    missionId: missionId!,
  });

  // Auto select the first button
  useEffect(() => {
    if (missionId && pathname === dashboardPath) {
      navigate(dashboards[0]?.path || configurePath);
    }
  }, [dashboards, missionId, navigate, pathname]);

  return (
    <Stack direction={"row"} spacing={2} p={2}>
      <BackButton path={buildRoute(paths.missions.index, {})} />
      {dashboards.map((dashboard) => (
        <RouterLink href={dashboard.path} key={dashboard.name}>
          <Button
            variant={dashboard.path === pathname ? "contained" : "text"}
            size={"small"}
          >
            {dashboard.name}
          </Button>
        </RouterLink>
      ))}
      <RouterLink href={configurePath}>
        <Button
          variant={!pathname.startsWith(dashboardPath) ? "contained" : "text"}
          size={"small"}
          color={"secondary"}
          startIcon={<Gear />}
        >
          Configure
        </Button>
      </RouterLink>
    </Stack>
  );
};
