import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";

const SalesforceCallbackPage = lazy(
  () => import("src/pages/auth/salesforce/code")
);
export const integrationOAuthRoutes: RouteObject[] = [
  {
    element: (
      <Suspense>
        <Outlet />
      </Suspense>
    ),
    path: "oauth2",
    children: [
      {
        path: "salesforce/callback",
        element: <SalesforceCallbackPage />,
      },
    ],
  },
];
