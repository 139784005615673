import React from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart as RechartsPieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import { chartColors } from "@/components/charts/chart-colors";
import type { PieChartProps } from "allgood-schema";

export const PieChart: React.FC<Omit<PieChartProps, "type">> = ({
  data,
  innerRadius = 60,
  outerRadius = 100,
  chartHeight = 400,
  fillColors = chartColors,
  label = true,
}) => {
  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <RechartsPieChart>
        <Pie
          data={data}
          animationDuration={300}
          dataKey="value"
          nameKey="name"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={90}
          endAngle={-270}
          strokeWidth={0}
          fill="#8884d8"
          label={label}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={fillColors[index % fillColors.length]}
            />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};
