import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { SignalId } from "allgood-schema";

export const SignalWidget = ({
  data,
}: {
  data: {
    name: string;
    status: string;
    id: SignalId;
    description: string;
  }[];
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredSignals = data
    .filter((signal) =>
      signal.name.toLowerCase().includes(searchTerm.toLowerCase()),
    )
    .slice(0, 10);

  return (
    <Box>
      <Typography variant="subtitle2">Signals found: </Typography>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        placeholder="Search by signal name..."
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 1 }}
      />
      {filteredSignals.map((signal) => (
        <Box key={signal.id} sx={{ mb: 1 }}>
          <Typography variant="caption">
            <b>{signal.name}</b>
          </Typography>
        </Box>
      ))}
      <Typography variant="caption" color="textSecondary">
        Showing 10 out of {data.length} signals found
      </Typography>
    </Box>
  );
};
