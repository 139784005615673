import { index, jsonb, timestamp, varchar } from "drizzle-orm/pg-core";
import { AGCompanyId, AGContactId, MembershipId } from "../../agid";
import { agid } from "../custom-types";
import { createBaseWithId, ownership } from "./base";
import { agRuntime } from "./schema";

export const AgCompanyCache = agRuntime.table(
  "cache_ag_company_v",
  {
    ...createBaseWithId<AGCompanyId>(),
    ...ownership,
    fieldRollup: jsonb("field_rollup").$type<object>(),
    attrRollup: jsonb("attr_rollup").$type<object>(),
    srcIds: jsonb("src_ids").$type<object>(),
  },
  (t) => ({
    // Support lookups by mapped ID
    srcIdsIdx: index().using("gin", t.srcIds),
  }),
);

export const AgContactCache = agRuntime.table(
  "cache_ag_contact_v",
  {
    ...createBaseWithId<AGContactId>(),
    ...ownership,
    fieldRollup: jsonb("field_rollup").$type<object>(),
    attrRollup: jsonb("attr_rollup").$type<object>(),
    srcIds: jsonb("src_ids").$type<object>(),
  },
  (t) => ({
    // Support lookups by mapped ID
    srcIdsIdx: index().using("gin", t.srcIds),
  }),
);
