import { useState, useMemo, useEffect } from "react";
import {
  MenuItem,
  Chip,
  Paper,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  type SelectChangeEvent,
  OutlinedInput,
  Button,
  LinearProgress,
  Box,
} from "@mui/material";
import { BuyerJourneyPreReqHumanTasksSchema } from "allgood-api/src/services/mission/mission.schema";
import type { z } from "zod";
import type { TaskWidgetContext } from "@/pages/campaign/configure/team-task";
import { trpc } from "src/utils/trpc";
import type { Milestone } from "allgood-api/src/repos/milestone.schema";

type BuyerJourneyPreReqHumanTaskData = z.infer<
  typeof BuyerJourneyPreReqHumanTasksSchema
>;

interface MilestoneSelectProps {
  label: string;
  value: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
  allMilestones: Milestone[];
}

const MilestoneSelect: React.FC<MilestoneSelectProps> = ({
  label,
  value,
  onChange,
  allMilestones,
}) => {
  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id={`${label.toLowerCase()}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label.toLowerCase()}-label`}
        id={`${label.toLowerCase()}-select`}
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        {allMilestones.map((milestone) => (
          <MenuItem key={milestone.name} value={milestone.ident}>
            {milestone.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const BuyerJourneyPreReqHumanTasksForm = (context: TaskWidgetContext) => {
  const { task, deliverable } = context;
  const [config, setConfig] = useState<BuyerJourneyPreReqHumanTaskData>({
    finalMilestones: [],
    excludeMilestones: [],
  });
  const [error, setError] = useState<string | null>(null);
  const [allMilestones, setAllMilestones] = useState<Milestone[]>([]);

  const { data } = trpc.milestone.list.useQuery({});

  useEffect(() => {
    // Set the milestone list
    setAllMilestones(data?.records ?? []);

    const ret = BuyerJourneyPreReqHumanTasksSchema.safeParse(
      deliverable?.deliverable?.data,
    );
    if (ret.success) {
      // since this data is used for components, undefined will mess up controlled vs uncontrolled components
      // so we need to make sure we have a default value for all fields
      setConfig({
        ...config,
        ...ret.data,
      });
    } else {
      // context.showMessage("Failed to load task data");
    }
  }, [task, data]);

  const handleChange =
    (field: "finalMilestones" | "excludeMilestones") =>
    (event: SelectChangeEvent<string[]>) => {
      const { value } = event.target;
      setConfig({
        ...config,
        [field]: typeof value === "string" ? value.split(",") : value,
      });
    };

  const handleSubmit = () => {
    // Validate the form
    const ret = BuyerJourneyPreReqHumanTasksSchema.safeParse(config);
    if (!ret.success) {
      setError(ret.error.errors[0].message);
      return;
    }
    setError(null); // Clear the error if there was one
    context.submitDeliverable(config);
  };

  const progress = useMemo(() => {
    const totalTasks = Object.keys(config).length;
    const completedTasks = Object.values(config).filter(Boolean).length;
    return (completedTasks / totalTasks) * 100;
  }, [config]);

  return (
    <Paper elevation={3} sx={{ p: 3, bgcolor: "#fafafa" }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#333" }}
      >
        Please complete the task requirements:
      </Typography>

      <LinearProgress variant="determinate" value={progress} sx={{ mb: 3 }} />

      <Stack spacing={3}>
        <MilestoneSelect
          label="Final Milestones"
          value={config.finalMilestones}
          onChange={handleChange("finalMilestones")}
          allMilestones={allMilestones}
        />
        <MilestoneSelect
          label="Exclude Milestones"
          value={config.excludeMilestones}
          onChange={handleChange("excludeMilestones")}
          allMilestones={allMilestones}
        />
      </Stack>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>

      {error && (
        <Box sx={{ mt: 3 }}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
    </Paper>
  );
};

export default BuyerJourneyPreReqHumanTasksForm;
