import React, { useState, useMemo } from "react";
import {
  Paper,
  Typography,
  Stack,
  FormControlLabel,
  Switch,
  Button,
  LinearProgress,
  Box,
} from "@mui/material";
import type { z } from "zod";
import { DataPipelinePreReqHumanTasksSchema } from "allgood-api/src/services/mission/mission.schema";
import { TaskWidgetContext } from "@/pages/campaign/configure/team-task";

type DataPipelinePreReqData = z.infer<
  typeof DataPipelinePreReqHumanTasksSchema
>;
const DataPipelinePreReqHumanTasksForm = (context: TaskWidgetContext) => {
  const [config, setConfig] = useState<DataPipelinePreReqData>({
    marketoConnected: false,
    salesforceConnected: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;
    setConfig((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    await context.submitDeliverable(config);
  };

  const progress = useMemo(() => {
    const totalTasks = Object.keys(config).length;
    const completedTasks = Object.values(config).filter(Boolean).length;
    return (completedTasks / totalTasks) * 100;
  }, [config]);

  return (
    <Paper elevation={3} sx={{ p: 3, bgcolor: "#fafafa" }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#333" }}
      >
        Please complete the task requirements:
      </Typography>

      <LinearProgress variant="determinate" value={progress} sx={{ mb: 3 }} />

      <Stack spacing={3}>
        <FormControlLabel
          control={
            <Switch
              checked={config.marketoConnected}
              onChange={handleChange}
              name="marketoConnected"
            />
          }
          label="Have you connected Marketo?"
          labelPlacement="start"
          sx={{ justifyContent: "space-between", ml: 0 }}
        />
        <Typography variant="body2" color="text.secondary">
          Marketo can be connected by following the instructions, click
          Settings, Integrations.
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={config.salesforceConnected}
              onChange={handleChange}
              name="salesforceConnected"
            />
          }
          label="Have you connected Salesforce?"
          labelPlacement="start"
          sx={{ justifyContent: "space-between", ml: 0 }}
        />
        <Typography variant="body2" color="text.secondary">
          Salesforce can be connected by following the instructions, click
          Settings, Integrations.
        </Typography>
      </Stack>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          // disabled={!Object.values(config).every(Boolean)}
        >
          Submit
        </Button>
      </Box>
    </Paper>
  );
};

export default DataPipelinePreReqHumanTasksForm;
