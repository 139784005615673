import { type DimensionalBarChartProps } from "allgood-schema";
import { sentenceCase } from "change-case";
import Papa from "papaparse";

export function transformString(str: string): string {
  const patternsToExclude = [
    // Do not case-transform URLs.
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,

    // Do not case-transform any string that already contains a space.
    /\s/,
  ];

  // If the string matches an exclude pattern, don't transform it.
  if (patternsToExclude.some((pattern) => pattern.test(str))) {
    return str;
  }

  // Otherwise, convert it to sentence case.
  return sentenceCase(str);
}

/** Round a number to a specified number of decimal places. */
export function round(num: number, decimals = 2): number {
  return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

/** Check if all metrics in a dimension is a time dimension. */
export function dimensionIsTime(dimension: string[]): boolean {
  return dimension.every(
    (d) =>
      d.toLowerCase().includes("year") ||
      d.toLowerCase().includes("month") ||
      d.toLowerCase().includes("week") ||
      d.toLowerCase().includes("day"),
  );
}

/**
 * Convert a time period to the equivalent number of days.
 * @param timePeriod The time period string.
 * @returns The number of days.
 */
export function convertToDays(timePeriod: string): number {
  const yearToDays = 365;
  const monthToDays = 30; // Approximation
  const weekToDays = 7;

  // Updated regular expressions with word boundaries and case-insensitive flags
  const dayPattern = /\b(\d+)\s*days?\b/i;
  const weekPattern = /\b(\d+)\s*weeks?\b/i;
  const monthPattern = /\b(\d+)\s*months?\b/i;
  const yearPattern = /\b(\d+)\s*years?\b/i;
  const pastPattern = /\bPast\s*(\d+)\b/i;

  const pastMatch = pastPattern.exec(timePeriod);
  if (pastMatch) {
    return parseInt(pastMatch[1], 10) * yearToDays + 1;
  }

  // Check for other patterns if 'Past' pattern does not match
  if (dayPattern.test(timePeriod)) {
    const dayMatch = dayPattern.exec(timePeriod);
    if (dayMatch) {
      return parseInt(dayMatch[1], 10);
    }
  } else if (weekPattern.test(timePeriod)) {
    const weekMatch = weekPattern.exec(timePeriod);
    if (weekMatch) {
      return parseInt(weekMatch[1], 10) * weekToDays;
    }
  } else if (yearPattern.test(timePeriod)) {
    const yearMatch = yearPattern.exec(timePeriod);
    if (yearMatch) {
      return parseInt(yearMatch[1], 10) * yearToDays;
    }
  } else if (monthPattern.test(timePeriod)) {
    const monthMatch = monthPattern.exec(timePeriod);
    if (monthMatch) {
      return parseInt(monthMatch[1], 10) * monthToDays;
    }
  }

  return 0; // Default value if no match
}

export function parsePercentage(value: number): string {
  return `${(value * 100).toFixed(2)}%`;
}

/** Export the dataset to a flattened CSV file, and download it via blob URI. */
export function downloadDataAsCsv(data: DimensionalBarChartProps["data"]) {
  // Prepare the header
  if (!(data.length > 0)) {
    return;
  }

  const dimensionKeys = Object.keys(data[0].dimension);
  const metricKeys = Object.keys(data[0].metric);
  const headers = [
    ...dimensionKeys,
    ...metricKeys,
    ...metricKeys.map((m) => `${m}_percentage`),
  ];

  const tabular: Array<Array<string | number>> = [];

  // for every metric key, sum the values and store them in an array
  const metricsTotals = metricKeys.map((m) =>
    data.reduce(
      (acc, row) => acc + row.metric[m as keyof typeof row.metric],
      0,
    ),
  );

  for (const entry of data) {
    const dimensionValues = Object.values(entry.dimension).map((v) =>
      v ? v.toString() : "Blank",
    );
    const metricValues = Object.values(entry.metric);
    const metricPercentages = metricValues.map(
      (m, i) => (m / metricsTotals[i]) * 1,
    );

    // Round metric numbers to two decimal places
    metricValues.forEach((value, i) => {
      metricValues[i] = round(value);
    });

    metricPercentages.forEach((value, i) => {
      metricPercentages[i] = round(value);
    });

    // Add the row of the csv with the dimension values, metric values, and the percentage of the metric values
    tabular.push([...dimensionValues, ...metricValues, ...metricPercentages]);
  }

  const csvString = Papa.unparse(
    {
      fields: headers,
      data: tabular,
    },
    { header: true },
  );
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  // Create a download link and click it.
  const a = document.createElement("a");
  a.href = url;
  a.download = "export.csv";
  a.click();

  // Release the URL object
  URL.revokeObjectURL(url);
}
