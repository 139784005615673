import { index, jsonb } from "drizzle-orm/pg-core";
import { DeliverableId, LangGraphCheckpointId, ThreadId } from "../../agid";
import { agid } from "../custom-types";
import { createBaseWithId, ownership } from "./base";
import { agRuntime } from "./schema";

export const LangGraphCheckpoint = agRuntime.table(
  "langgraph_checkpoint",
  {
    ...createBaseWithId<LangGraphCheckpointId>(),
    ...ownership,

    deliverableId: agid("deliverable_id").$type<DeliverableId>(),

    threadId: agid("thread_id").$type<ThreadId>().notNull(),

    /** ID of the parent checkpoint. */
    parentId: agid("parent_id").$type<LangGraphCheckpointId>(),

    /** Checkpoint data. */
    checkpoint: jsonb("checkpoint").notNull(),

    /** Metadata. */
    metadata: jsonb("metadata").notNull(),
  },
  (t) => ({
    threadIdx: index("ix_langgraph_checkpoint_instance_thread").on(
      t.tenantId,
      t.deliverableId,
      t.threadId,

      // Including `t.id` matches the (thread_id, checkpoint_id) `PRIMARY KEY` from
      // the LangGraph SqliteSaver implementation.
      t.id,
    ),

    parentIdx: index("ix_langgraph_checkpoint_parent").on(
      t.tenantId,
      t.parentId,
    ),
  }),
);
