import type { SelectChangeEvent } from "@mui/material";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import type { DeliverableId, MissionId, ThreadId } from "allgood-schema";
import { trpc } from "@/utils/trpc";
import { ClockCounterClockwise } from "@phosphor-icons/react";
import { format } from "date-fns";
import { sendEventToAmplitude } from "src/utils/amplitude";
import { useSearchParams } from "react-router-dom";

/**
 * Loads available threads, and if selected, calls to switch to that threadId
 * @param missionId
 * @param deliverableId
 * @param teamConfigId
 * @param threadId
 * @constructor
 */
export const ThreadSelectComponent = ({
  missionId,
  deliverableId,
  threadId,
}: {
  missionId: MissionId;
  deliverableId: DeliverableId;
  threadId?: ThreadId;
}) => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const { data: threads, refetch: refetchThreads } =
    trpc.agentWorkflow.getThreadsByInstanceId.useQuery({
      deliverableId: deliverableId!,
      limit: 15,
    });
  const handleThreadDropdownChange = (event: SelectChangeEvent<string>) => {
    sendEventToAmplitude({
      event_type: "Selected Agent Thread",
      event_properties: {
        missionId,
        deliverableId,
        threadId: event.target.value,
      },
    });

    setSearchParams({ threadId: event.target.value, deliverable: "false" });
  };
  const [open, setOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Tooltip title={"History"}>
      <Stack direction={"row"} alignItems={"center"} spacing={0}>
        <IconButton
          onClick={() => {
            refetchThreads();
            setOpen(true);
          }}
        >
          <ClockCounterClockwise size={24} />
        </IconButton>
        <Box
          sx={{
            width: threads?.some((thread) => thread.threadId === threadId)
              ? "inherit"
              : "0px",
            overflow: "hidden",
          }}
        >
          <Select
            value={
              threads?.some((thread) => thread.threadId === threadId)
                ? threadId?.toString() || ""
                : ""
            }
            onChange={handleThreadDropdownChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              minWidth: 120,
            }}
            open={open}
            onOpen={handleToggle}
            onClose={handleClose}
            ref={selectRef}
          >
            {threads &&
              threads!.map((option) => (
                <MenuItem key={option.threadId} value={option.threadId}>
                  <Typography variant="subtitle1" component="div">
                    Created: {format(option.createdAt, "MM/dd/yy HH:mm")}
                  </Typography>
                </MenuItem>
              ))}
          </Select>
        </Box>
      </Stack>
    </Tooltip>
  );
};
