import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import type { Dispatch, FC, SetStateAction } from "react";
import type { RouterOutputs } from "@/utils/trpc";
import type { ActivityFilters } from "@/components/activity/helpers/activity-filters";
import { BackButton } from "@/components/back-button";
import type { MissionId } from "allgood-schema";
import type { Opportunity } from "allgood-api/src/services/opportunity_engagement.schema";
import { rapWidgets } from "@/components/rap/rap-widgets";

type CompanySidebarProps = {
  missionId?: MissionId;
  opportunity: Opportunity | null;
  setOpportunity: (opportunity: Opportunity | null) => void;
  company: NonNullable<RouterOutputs["company"]["find"]>;
  opportunities: NonNullable<
    RouterOutputs["opportunityEngagement"]["listOpportunities"]["opportunities"]
  >;
  filters: ActivityFilters;
  setFilters: Dispatch<SetStateAction<ActivityFilters>>;
};
export const CompanySidebar: FC<CompanySidebarProps> = ({
  missionId,
  opportunity,
  setOpportunity,
  company,
  opportunities,
  filters,
  setFilters,
}) => {
  return (
    <Stack
      sx={{
        minWidth: "360px",
        maxWidth: "360px",
        width: "360px",
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        overflow: "auto",
        borderRight: "1px solid var(--mui-palette-divider)",
      }}
    >
      <Box
        component="div"
        px={2}
        pt={0}
        pb={2}
        sx={{ borderBottom: "1px solid var(--mui-palette-divider)" }}
      >
        <BackButton />
        <Typography variant={"h6"}>ACCOUNT</Typography>
        <Stack direction={"row"} spacing={1}>
          {String(company?.fieldRollup.companyWebsite) ? (
            <object
              type="image/png"
              style={{
                // maxWidth: 56,
                maxHeight: 40,
              }}
              data={`https://logo.clearbit.com/${
                String(company.fieldRollup.companyWebsite)
                  .replace("https://", "")
                  .replace("http://", "")
                  .match(/[^/]+/g)?.[0]
              }`}
            />
          ) : null}
          <Typography variant={"h4"}>
            {String(company.fieldRollup.companyName)}
          </Typography>
        </Stack>
      </Box>

      <Box p={2}>
        <Select
          fullWidth
          value={opportunity?.id ?? ""}
          variant={"filled"}
          onChange={(e) => {
            if (e.target.value === "") {
              setOpportunity(null);
              setFilters({
                ...filters,
                startTime: undefined,
                endTime: undefined,
              });
              return;
            }

            const foundOpportunity =
              opportunities?.find(
                (opportunity) => opportunity.id === e.target.value,
              ) ?? opportunities[0];
            if (!foundOpportunity) return;
            setOpportunity(foundOpportunity);
            setFilters({
              ...filters,
              // make the start date the opportunity creation date - 90 days
              // and the end date the opportunity close date
              startTime: new Date(
                new Date(foundOpportunity.createdAt).getTime() -
                  90 * 24 * 60 * 60 * 1000,
              ),
              endTime: foundOpportunity.closedAt
                ? new Date(foundOpportunity.closedAt)
                : new Date(),
            });
          }}
          size={"medium"}
          sx={{
            "& .MuiSelect-select": {
              textOverflow: "ellipsis",
              display: "inline-block !important",
              alignContent: "center",
              px: 2,
            },
            px: 0,
            bgcolor: "primary.main",
            borderRadius: 0,
          }}
        >
          <MenuItem value={""}>ALL OPPORTUNITIES</MenuItem>
          {opportunities
            ?.sort((a, b) => {
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            })
            .map((opportunity) => (
              <MenuItem key={opportunity.id} value={opportunity.id}>
                {opportunity.name} ({opportunity.createdAt.toLocaleDateString()}
                ) [{opportunity.stage}]
              </MenuItem>
            ))}
        </Select>

        {opportunity && (
          <Box p={2} border={"1px solid"} borderColor={"divider"}>
            {opportunity.name}
            <br />
            <Typography variant={"body2"} color={"text.secondary"}>
              Stage: {opportunity.stage}
              <br />
              Amount:{" "}
              {opportunity.amount
                ? "$" + opportunity.amount?.toLocaleString()
                : "-"}
              <br />
              Created: {opportunity.createdAt.toLocaleDateString()}
              <br />
              Closed: {opportunity.closedAt?.toLocaleDateString() ?? "-"}
              <br />
            </Typography>
          </Box>
        )}
      </Box>
      <Box mt={1} flex={1}>
        {rapWidgets["COMPANY_CONTACT"]({
          missionId: missionId!,
          ident: "COMPANY_CONTACT",
          filters,
          params: {
            filter: {
              ...filters,
              opportunityId: opportunity?.id,
              agCompanyId: company.id,
            },
          },
          setFilters,
        })}
      </Box>
    </Stack>
  );
};
