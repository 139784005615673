import { z } from "zod";

export const SHORT_ID_ALPHABET = "23456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const agIdBrand = "AgId Brand";
export const shortIdBrand = "ShortId Brand";

export type AgId<T extends string> = string & { [agIdBrand]: T };

export type ShortId<T extends string> = string & { [shortIdBrand]: T };

type IsStringLiteral<T extends string> = string extends T ? never : T;

/** Create an ID schema for this particular AgId type. */
function makeIdSchema<T extends string>(
  name: IsStringLiteral<T>,
): z.ZodType<AgId<T>, z.ZodTypeDef, AgId<T>> {
  const schema = z
    .string({ invalid_type_error: `${name} must be a string` })
    .uuid({ message: `${name} must be a valid UUID` })
    .refine((_id: string): _id is AgId<T> => true);

  // NOTE: We need this to ensure that `z.input<SomeSchema>` has `AgId<T>` for
  // its ID fields, and not `string`. This is required to implement a type-safe
  // BaseRepo. Ask Will if you've got questions, because this gets very deep
  // in the weeds.
  return schema as unknown as z.ZodType<AgId<T>, z.ZodTypeDef, AgId<T>>;
}

/** Create an ID schema for this particular Short ID type. */
function makeShortIdSchema<T extends string>(
  name: IsStringLiteral<T>,
): z.ZodType<AgId<T>, z.ZodTypeDef, AgId<T>> {
  // Slightly more permissive than SHORT_ID_ALPHABET.
  const regex = new RegExp(`^[A-Z0-9]{8}$`);
  const schema = z
    .string({ invalid_type_error: `${name} must be a string` })
    .regex(regex, { message: `${name} must be a valid short ID` })
    .refine((_id: string): _id is AgId<T> => true);

  // NOTE: We need this to ensure that `z.input<SomeSchema>` has `AgId<T>` for
  // its ID fields, and not `string`. This is required to implement a type-safe
  // BaseRepo. Ask Will if you've got questions, because this gets very deep
  // in the weeds.
  return schema as unknown as z.ZodType<AgId<T>, z.ZodTypeDef, AgId<T>>;
}

export type AnyAgId = AgId<string>;
export const AnyAgId: z.ZodType<AnyAgId, z.ZodTypeDef, AnyAgId> =
  makeIdSchema("Any");

export const TenantId = makeIdSchema("TenantId");
export type TenantId = z.output<typeof TenantId>;

export const TenantShortId = makeShortIdSchema("TenantShortId");
export type TenantShortId = z.output<typeof TenantShortId>;

export const UserId = makeIdSchema("UserId");
export type UserId = z.output<typeof UserId>;

export const UserShortId = makeShortIdSchema("UserShortId");
export type UserShortId = z.output<typeof UserShortId>;

export const UserTenantMappingId = makeIdSchema("UserTenantMappingId");
export type UserTenantMappingId = z.output<typeof UserTenantMappingId>;

export const AgentId = makeIdSchema("AgentId");
export type AgentId = z.output<typeof AgentId>;

export const TeamConfigId = makeIdSchema("TeamConfigId");
export type TeamConfigId = z.output<typeof TeamConfigId>;

export const BusinessObjectiveId = makeIdSchema("BusinessObjectiveId");
export type BusinessObjectiveId = z.output<typeof BusinessObjectiveId>;

export const BusinessObjectiveMemberId = makeIdSchema(
  "BusinessObjectiveMemberId",
);
export type BusinessObjectiveMemberId = z.output<
  typeof BusinessObjectiveMemberId
>;

export const BusinessObjectiveMetricsId = makeIdSchema(
  "BusinessObjectiveMetricsId",
);
export type BusinessObjectiveMetricsId = z.output<
  typeof BusinessObjectiveMetricsId
>;

export const BusinessObjectiveShortId = makeShortIdSchema(
  "BusinessObjectiveShortId",
);
export type BusinessObjectiveShortId = z.output<
  typeof BusinessObjectiveShortId
>;

export const MembershipId = makeIdSchema("MembershipId");
export type MembershipId = z.output<typeof MembershipId>;

export const ContentId = makeIdSchema("ContentId");
export type ContentId = z.output<typeof ContentId>;

export const ContentConfigurationId = makeIdSchema("ContentConfigurationId");
export type ContentConfigurationId = z.output<typeof ContentConfigurationId>;

export const CampaignId = makeIdSchema("CampaignId");
export type CampaignId = z.output<typeof CampaignId>;

export const CampaignMetricsId = makeIdSchema("CampaignMetricsId");
export type CampaignMetricsId = z.output<typeof CampaignMetricsId>;

export const WorkflowRunId = makeIdSchema("WorkflowRunId");
export type WorkflowRunId = z.output<typeof WorkflowRunId>;

export const WorkflowCheckpointId = makeIdSchema("WorkflowCheckpointId");
export type WorkflowCheckpointId = z.output<typeof WorkflowCheckpointId>;

export const ActionId = makeIdSchema("ActionId");
export type ActionId = z.output<typeof ActionId>;

export const CampaignMembershipId = makeIdSchema("CampaignMembershipId");
export type CampaignMembershipId = z.output<typeof CampaignMembershipId>;

export const CampaignSignalAnalysisId = makeIdSchema(
  "CampaignSignalAnalysisId",
);
export type CampaignSignalAnalysisId = z.output<
  typeof CampaignSignalAnalysisId
>;

export const SignalId = makeIdSchema("SignalId");
export type SignalId = z.output<typeof SignalId>;

export const MilestoneId = makeIdSchema("MilestoneId");
export type MilestoneId = z.output<typeof MilestoneId>;

export const RecommendationId = makeIdSchema("RecommendationId");
export type RecommendationId = z.output<typeof RecommendationId>;

export const ActionIntentId = makeIdSchema("ActionIntentId");
export type ActionIntentId = z.output<typeof ActionIntentId>;

export const ActivityTypeId = makeIdSchema("ActivityTypeId");
export type ActivityTypeId = z.output<typeof ActivityTypeId>;

export const AttributeId = makeIdSchema("AttributeId");
export type AttributeId = z.output<typeof AttributeId>;

export const AGContactId = makeIdSchema("AGContactId");
export type AGContactId = z.output<typeof AGContactId>;

export const AGCompanyId = makeIdSchema("AGCompanyId");
export type AGCompanyId = z.output<typeof AGCompanyId>;

export const AGMappingId = makeIdSchema("AGMappingId");
export type AGMappingId = z.output<typeof AGMappingId>;

export const MLClassifierId = makeIdSchema("MLClassifierId");
export type MLClassifierId = z.output<typeof MLClassifierId>;

export const IntegrationId = makeIdSchema("IntegrationId");
export type IntegrationId = z.output<typeof IntegrationId>;

export const MissionId = makeIdSchema("MissionId");
export type MissionId = z.output<typeof MissionId>;

export const TaskId = makeIdSchema("TaskId");
export type TaskId = z.output<typeof TaskId>;

export const DeliverableId = makeIdSchema("DeliverableId");
export type DeliverableId = z.output<typeof DeliverableId>;

export const ThreadId = makeIdSchema("ThreadId");
export type ThreadId = z.output<typeof ThreadId>;

export const LangGraphCheckpointId = makeIdSchema("LangGraphCheckpointId");
export type LangGraphCheckpointId = z.output<typeof LangGraphCheckpointId>;

export const InsightId = makeIdSchema("InsightId");
export type InsightId = z.output<typeof InsightId>;

export const CheckId = makeIdSchema("CheckId");
export type CheckId = z.output<typeof CheckId>;

export const ObservationId = makeIdSchema("ObservationId");
export type ObservationId = z.output<typeof ObservationId>;

export const NormalizationId = makeIdSchema("NormalizationId");
export type NormalizationId = z.output<typeof NormalizationId>;

export const NormalizationShortId = makeShortIdSchema("NormalizationShortId");
export type NormalizationShortId = z.output<typeof NormalizationShortId>;

export const FieldId = makeIdSchema("FieldId");
export type FieldId = z.output<typeof FieldId>;

export const LinkageId = makeIdSchema("LinkageId");
export type LinkageId = z.output<typeof LinkageId>;
