import {
  AgentId,
  MissionId,
  TenantId,
  UserId,
  UserRole,
  UserType,
} from "allgood-schema";
import { z } from "zod";

/**
 * Invoked by a seed or demo script.
 */
export const SeedInvoker = z.object({
  _type: z.literal("seed"),
});
export type SeedInvoker = z.infer<typeof SeedInvoker>;

/** An invoker representing a direct request from a specific user (e.g. they
 * clicked a button) */
export const UserInvoker = z.object({
  _type: z.literal("user"),
  userId: UserId,
  userType: z.enum(UserType).optional(),
  userRole: z.enum(UserRole).optional(),
});
export type UserInvoker = z.infer<typeof UserInvoker>;

/**
 * An invoker representing a workflow, which may be run on-request, or
 * scheduled in the background.
 */
export const WorkflowInvoker = z.object({
  _type: z.literal("workflow"),
  /** The Temporal workflow type (e.g. `dataPipeline`, `campaignExecution`, etc. */
  workflowType: z.string(),
  /** The Temporal workflow ID */
  workflowId: z.string(),
  /** The Temporal workflow Run UUID */
  workflowRunId: z.string(),
  // TODO? should we add a Workflow Execution Id ?

  // TODO? optional who started it
});
export type WorkflowInvoker = z.infer<typeof WorkflowInvoker>;

/**
 * An invoker representing an Agent, which may be run on-request, or scheduled
 * and run from a workflow.
 */

export const AgentInvoker = z.object({
  _type: z.literal("agent"),
  /** The Agent ID */
  agentId: AgentId,
});
export type AgentInvoker = z.infer<typeof AgentInvoker>;

/**
 * An invoker tracks any user or system component that can make a change to data
 * or configuration.
 */
export const Invoker = z.union([
  SeedInvoker,
  UserInvoker,
  WorkflowInvoker,
  AgentInvoker,
]);
export type Invoker = z.infer<typeof Invoker>;

/**
 * A `Context` is a collection of information that's threaded down into every
 * operation.
 *
 * See the `DbCtx` type (in `db.ts`) for the database context extension type, which includes an optional reference to a current transaction. Callers which use the database should take a `Context & DbCtx`.
 */
export const Context = z
  .object({
    tenantId: TenantId,
    invoker: Invoker,
  })
  .passthrough() // Contexts can legitimately include other things than just `tenantId` and `invoker`.
  .describe("Context");
export type Context = z.infer<typeof Context>;

/**
 * Context extension to specifically require a user invoker.
 * For instance: `Context & UserInvokedCtx` must have a `UserInvoker`.
 */
export type UserInvokedCtx = { invoker: UserInvoker };

/**
 * Context extension to specifically requiure a workflow invoker.
 * For instance: `Context & WorkflowInvokedCtx` must have a `WorkflowInvoker`.
 */
export type WorkflowInvokedCtx = { invoker: WorkflowInvoker };

/**
 * Context extension to include the missionId for a specific mission.
 * For instance: `Context & MissionCtx` must have a `missionId`.
 */
export type MissionCtx = { missionId: MissionId };
