import { ChartCardProps } from "allgood-schema";
import { FC } from "react";
import { PieChart } from "@/components/charts/pie-chart";
import { SimpleBarChart } from "@/components/charts/bar-chart";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

export const ChartCard: FC<ChartCardProps> = ({
  title,
  chartType,
  chartData,
}) => {
  const getChart = () => {
    switch (chartType) {
      case "BarChart":
        return <SimpleBarChart {...chartData} />;
      case "PieChart":
        return <PieChart {...chartData} />;
      default:
        return <>Unknown Chart type {chartType}</>;
    }
  };

  const Chart = getChart();

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>{Chart}</CardContent>
    </Card>
  );
};
