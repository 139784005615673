import React from "react";
import { Box } from "@mui/system";
import { TaskWidgetContext } from "@/pages/campaign/configure/team-task";
import { trpc } from "@/utils/trpc";
import Typography from "@mui/material/Typography";

/*
  The parent task widget will show just how many sub tasks it has.
 */
const ParentTaskWidget = (context: TaskWidgetContext) => {
  const [childTask] = trpc.task.list.useSuspenseQuery({
    filter: { parentId: context.task.id },
    limit: 20,
  });
  if (!context.task) {
    return <div>Task not found</div>;
  } else {
    return (
      <>
        <Box pt={1}>
          <Typography> Sub Tasks </Typography>
          {childTask.records.map((task) => {
            return (
              <Box key={task.id}>
                <Typography variant={"h6"}> {task.name}</Typography>
                <Typography> {task.description}</Typography>
              </Box>
            );
          })}
        </Box>
      </>
    );
  }
};

export default ParentTaskWidget;
