import type { FC } from "react";
import React from "react";
import Loading from "src/components/loading";
import type { RouterInputs } from "src/utils/trpc";
import { trpc } from "src/utils/trpc";
import type { TeamConfigId } from "allgood-schema";
import {
  AgentWorkflowMutation,
  TaskConfigCard,
} from "@/pages/campaign/configure/task-config-card";

type TaskConfigProps = {
  teamConfigId: TeamConfigId;
};
export const TaskConfigUpdate: FC<TaskConfigProps> = ({ teamConfigId }) => {
  const {
    data: task,
    isLoading: isLoadingTask,
    isRefetching: isRefetchingTask,
    refetch: refetchTask,
  } = trpc.agentWorkflow.get.useQuery({
    id: teamConfigId!,
  });

  const {
    mutateAsync: updateAgentWorkflow,
    data: updateResult,
    isLoading: isUpdating,
  } = trpc.agentWorkflow.update.useMutation();

  const handleSubmit = async (_payload: AgentWorkflowMutation) => {
    const payload = _payload as RouterInputs["agentWorkflow"]["update"];
    await updateAgentWorkflow({
      id: payload.id,
      name: payload.name,
      description: payload.description,
      config: payload.config,
    });
    await refetchTask();
  };

  return (
    <Loading loading={isLoadingTask} fullscreen={false}>
      {task && (
        <TaskConfigCard
          key={task.id}
          task={task}
          onSubmit={handleSubmit}
          submitButtonLabel={"Update"}
          isSubmitting={isUpdating || isRefetchingTask}
          submitResult={updateResult}
        />
      )}
    </Loading>
  );
};
