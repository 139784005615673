import Activity from "@/components/activity/activity";
import type { ActivityModel } from "@/components/activity/activity-types";
import { getIcon } from "@/components/activity/get-icon";
import { CollapseCard } from "@/components/collapse-card";
import { agTeal, agYellow } from "@/styles/theme/colors";
import { Box, Chip, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { X } from "@phosphor-icons/react/dist/csr/X";
import { type MilestoneCluster } from "allgood-api/dist/src/services/opportunity_engagement.schema";
import { AGContactId, MissionId } from "allgood-schema";
import { format } from "date-fns";
import { trpc } from "src/utils/trpc";
import { getHeader, getTitle } from "../activity-timeline";
import Loading from "src/components/loading";
import { useDeliverable } from "src/hooks/use-deliverable";
import { Contact } from "allgood-api/dist/src/repos/principal.schema";

type ActivityTimelineWrapperProps = {
  missionId: MissionId | undefined;
  selectedCluster: MilestoneCluster | null;
  setSelectedCluster: (cluster: MilestoneCluster | null) => void;
  agContactIds?: AGContactId[];
};

export const ActivityTimelineWrapper: React.FC<
  ActivityTimelineWrapperProps
> = ({
  missionId,
  selectedCluster,
  setSelectedCluster,
  agContactIds,
}: ActivityTimelineWrapperProps) => {
  const { data: milestoneData, isLoading: milestoneLoading } =
    trpc.milestone.listMilestoneClusterActivities.useQuery({
      milestoneInstanceIds:
        selectedCluster?.map((cluster) => cluster.ids).flat() ?? [],
      agContactIds:
        (agContactIds?.length ?? 0) > 0 ? (agContactIds ?? null) : null,
    });

  const { data: contacts, isLoading: contactsLoading } =
    trpc.contact.findMany.useQuery({
      ids:
        milestoneData
          ?.map((activity) => activity.agContactId) // filter to make sure we only get unique contact ids
          .filter(
            (value, index, self) => self.indexOf(value) === index && !!value,
          )
          .filter((id): id is AGContactId => id !== null && id !== undefined) ??
        [],
    });

  const transformed =
    milestoneData?.map((activity) => {
      const contact = contacts?.find(
        (contact: Contact) => contact.id === activity.agContactId,
      );

      const firstName: string = contact?.fieldRollup?.firstName as string;
      const lastName: string = contact?.fieldRollup?.lastName as string;

      const from = contact
        ? {
            contactId: activity.agContactId,
            name:
              contact?.fieldRollup?.firstName +
              " " +
              contact?.fieldRollup?.lastName,
            role: contact?.fieldRollup?.title,
            email: contact?.fieldRollup?.email,
            avatar: firstName?.charAt(0) + lastName?.charAt(0),
          }
        : {};

      return {
        id: activity.id,
        from: from,
        type: activity.activityType === "visit_webpage" ? "website" : "",
        timestamp: activity.occurredAt,
        srcName: activity.srcName,
        agId: activity.srcId,
        occurredAt: new Date(activity.occurredAt),
        activityType: activity.activityType,
        content: activity.content,
        contentExtras: activity.contentExtras,
        companyId: activity.agCompanyId,
        contactId: activity.agContactId,
        srcId: activity.srcId,
        touchType: "marketing",
        milestoneId: activity.milestoneId,
      } as ActivityModel;
    }) || [];

  return milestoneLoading || contactsLoading ? (
    <Loading loading={true} fullscreen={false} />
  ) : (
    <ActivityTimelineDetail
      missionId={missionId}
      selectedCluster={selectedCluster}
      setSelectedCluster={setSelectedCluster}
      activities={transformed}
      isLoading={milestoneLoading}
    />
  );
};

type ActivityTimelineDetailProps = {
  missionId: MissionId | undefined;
  selectedCluster: MilestoneCluster | null;
  setSelectedCluster: (cluster: MilestoneCluster | null) => void;
  activities: Array<ActivityModel> | undefined;
  isLoading: boolean;
};

export const ActivityTimelineDetail: React.FC<ActivityTimelineDetailProps> = ({
  missionId,
  selectedCluster,
  setSelectedCluster,
  activities,
  isLoading,
}: ActivityTimelineDetailProps) => {
  const { result } = useDeliverable<{
    activities: Array<ActivityModel>;
  }>({
    missionId,
    taskKey: "accountBasedAttributionAccountTimelineDetailUI",
    data: {
      activities: activities || [],
    },
  });

  const outputActivities = result?.activities || [];

  return (
    <Loading loading={isLoading} fullscreen={false}>
      <Stack flex={1}>
        <Box px={2} pt={2} pb={1}>
          <Stack direction={"row"}>
            <Typography variant={"subtitle2"} flex={1}>
              Timeline
            </Typography>
            <Box mt={-1} mr={-1}>
              <IconButton
                size={"small"}
                onClick={() => {
                  setSelectedCluster(null);
                }}
              >
                <X />
              </IconButton>
            </Box>
          </Stack>
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "100%",
          }}
          p={2}
          pt={0}
        >
          {outputActivities?.map((activity: ActivityModel, i: number) => {
            const prevDate = outputActivities[i - 1]
              ? format(outputActivities[i - 1]?.timestamp, "MMM, dd, yyyy")
              : null;
            const date = format(activity.timestamp, "MMM, dd, yyyy");
            const milestone = activity.milestoneId
              ? selectedCluster?.find((cluster) =>
                  activity.milestoneId
                    ? cluster.ids.includes(activity.milestoneId)
                    : false,
                )
              : null;

            return (
              <Box key={i} data-cy={"activity"} py={1}>
                {prevDate !== date && (
                  <Typography variant="caption" mb={1} display={"inline-block"}>
                    {format(activity.timestamp, "MMM, dd, yyyy")}
                  </Typography>
                )}
                <CollapseCard
                  title={
                    <Box py={2}>
                      <Stack direction={"column"} color={"initial"}>
                        <Box flex={4}>
                          <Chip
                            label={
                              <>
                                {milestone?.name ??
                                  activity.contentExtras?.programChannel ??
                                  activity.activityType}
                              </>
                            }
                            variant={"soft"}
                            icon={getIcon(activity.type, 1).icon}
                            size={"small"}
                          ></Chip>{" "}
                          <Typography
                            variant="subtitle2"
                            component="span"
                            color={"agPink.700"}
                          >
                            {activity.contentExtras?.programName ??
                              activity.title ??
                              getHeader(activity)}
                          </Typography>
                        </Box>
                        <Typography
                          variant={"overline"}
                          sx={{ marginTop: -0.5 }}
                          color={
                            activity.touchType === "marketing"
                              ? agYellow["700"]
                              : agTeal["600"]
                          }
                        ></Typography>
                        <Box color={"initial"}>
                          <Typography variant="subtitle2" component="span">
                            {activity.from.name}
                            {activity.from.role && `, ${activity.from.role}`}
                          </Typography>
                        </Box>
                        <Typography variant="body2" component="span">
                          {getTitle(activity)}
                        </Typography>
                      </Stack>
                      <Box>
                        <Typography
                          variant="body2"
                          component="span"
                          color={"text.secondary"}
                          sx={{ wordBreak: "break-word" }}
                        >
                          {activity.body ?? activity.summary}
                        </Typography>
                      </Box>
                    </Box>
                  }
                  titleStyle={{ px: 2 }}
                  sx={{
                    lineHeight: "1rem",
                    border: `1px solid ${milestone?.touchType == "SALES" ? agTeal["300"] : agYellow["300"]}`,
                  }}
                >
                  <Box fontSize={"12px"} px={2} pb={3}>
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      <Activity activity={activity} truncate={false} />
                    </pre>
                  </Box>
                </CollapseCard>
              </Box>
            );
          })}
        </Box>
      </Stack>
    </Loading>
  );
};
