import type { NavItemConfig } from "@/types/nav";
import { paths } from "@/paths";

// NOTE: We did not use React Components for Icons, because
//  you may one to get the config from the server.

// NOTE: First level elements are groups.

export interface LayoutConfig {
  navItems: NavItemConfig[];
}

export const layoutConfig = {
  navItems: [
    {
      key: "dashboard",
      title: "",
      items: [
        {
          key: "home",
          title: "Home",
          href: paths.dashboard.index,
          icon: "home",
        },
        {
          key: "missions",
          title: "Missions",
          href: paths.missions.index,
          icon: "campaign",
          matcher: {
            type: "startsWith",
            href: paths.missions.index,
          },
          allowedRoles: ["ADMIN"],
        },
        {
          key: "guides",
          title: "Guides",
          href: paths.guides.index,
          icon: "guide",
          matcher: {
            type: "startsWith",
            href: paths.guides.index,
          },
          allowedRoles: ["ADMIN"],
        },
        {
          key: "settings",
          title: "Settings",
          href: paths.settings.account,
          icon: "settings",
          matcher: {
            type: "startsWith",
            href: paths.settings.index,
          },
        },
      ],
    },
    {
      key: "internal",
      title: "Internal Only",
      items: [
        {
          key: "admin",
          title: "Admin",
          href: paths.admin.index,
          icon: "admin",
          matcher: {
            type: "startsWith",
            href: paths.admin.index,
          },
          allowedTypes: ["SUPERUSER"],
        },
        {
          key: "companies",
          title: "Companies",
          href: paths.companies.index,
          icon: "companies",
          matcher: {
            type: "startsWith",
            href: paths.companies.index,
          },
          allowedTypes: ["SUPERUSER"],
        },
      ],
      allowedTypes: ["SUPERUSER"],
    },
  ],
} satisfies LayoutConfig;
