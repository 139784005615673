import {
  DeliverableId,
  InsightId,
  MissionId,
  TaskId,
  ThreadId,
} from "allgood-schema";
import { z } from "zod";
import { Invoker } from "../context";

// TODO: Make this more specific.
export const InsightContent = z.object({
  bigNumber: z
    .string()
    .optional()
    .describe(
      "A headline number, like '15%', '+100' or similar. This will be shown in a a large display font to the user.",
    ),
});
export type InsightContent = z.infer<typeof InsightContent>;

export const Priority = z
  .number()
  .int()
  .min(0)
  .max(3)
  .refine((_x): _x is 0 | 1 | 2 | 3 => true);
export type Priority = z.infer<typeof Priority>;

export const CreateInsight = z.object({
  taskId: TaskId,
  deliverableId: DeliverableId.nullish(),
  threadId: ThreadId.nullish(),
  name: z.string(),
  description: z.string(),
  priority: Priority.default(0),
  content: InsightContent,
});
export type CreateInsight = z.input<typeof CreateInsight>;

export const UpdateInsight = CreateInsight.partial().extend({ id: InsightId });
export type UpdateInsight = z.output<typeof UpdateInsight>;

export const Insight = z.object({
  id: InsightId,
  ...CreateInsight.shape,
  createdAt: z.date(),
  createdBy: Invoker,
  updatedAt: z.date().nullish(),
  updatedBy: Invoker.nullish(),
});
export type Insight = z.output<typeof Insight>;

export const InsightFilter = z.object({
  taskId: TaskId.nullish(),
  deliverableId: DeliverableId.nullish(),
  threadId: ThreadId.nullish(),
  missionId: MissionId.nullish(),
  priority: z.union([Priority, z.array(Priority)]).nullish(),
  createdByType: z.enum(["user", "agent"]).nullish(),
});
export type InsightFilter = z.output<typeof InsightFilter>;
