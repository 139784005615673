import type { FC, ReactElement } from "react";
import { useState } from "react";
import ChevronDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Collapse } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";

export interface CollapseBoxProps {
  title: ReactElement | string;
  isExpanded?: boolean;
  collapsible?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sx?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  titleStyle?: any;
  collapseIconPosition?: "left" | "right" | "none";
  children: ((expanded: boolean) => ReactElement) | ReactElement;
}

export const CollapseBox: FC<CollapseBoxProps> = ({
  title,
  isExpanded = false,
  collapsible = true,
  sx,
  titleStyle,
  collapseIconPosition = "right",
  children,
}) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  const childElement: ((expanded: boolean) => ReactElement) | ReactElement =
    typeof children === "function" ? children(expanded) : children;

  return (
    <Box sx={sx}>
      <Box
        sx={{
          alignItems: "center",
          cursor: collapsible ? "pointer" : undefined,
          "&:hover": {
            backgroundColor: collapsible ? "action.hover" : undefined,
          },
          paddingY: 0,
          ...titleStyle,
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Stack direction={"row"} sx={{ alignItems: "center" }}>
          {collapsible &&
          collapseIconPosition !== "none" &&
          collapseIconPosition === "left" ? (
            expanded ? (
              <ChevronDownIcon />
            ) : (
              <ChevronRightIcon />
            )
          ) : null}
          <Box flex={1}>{title}</Box>
          {collapsible &&
          collapseIconPosition !== "none" &&
          collapseIconPosition === "right" ? (
            expanded ? (
              <ChevronDownIcon />
            ) : (
              <ChevronRightIcon />
            )
          ) : null}
        </Stack>
      </Box>
      {collapsible ? (
        <Collapse in={expanded}>
          <>{childElement}</>
        </Collapse>
      ) : (
        <>{childElement}</>
      )}
    </Box>
  );
};
