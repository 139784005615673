import { AgentId, TeamConfigId } from "../../agid";
import { config, createBaseWithId, ownership } from "./base";
import { agConfig } from "./schema";
import { unique } from "drizzle-orm/pg-core";

export const Agent = agConfig.table(
  "agent",
  {
    ...createBaseWithId<AgentId>(),
    ...ownership,
    ...config,
  },
  (table) => {
    return {
      uniqueKey: unique("uq_agent_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

/**
 * Team Config
 *
 * @remarks was earlier Agent Workflow.
 *
 */
export const TeamConfig = agConfig.table(
  "agent_worfklow",
  {
    ...createBaseWithId<TeamConfigId>(),
    ...ownership,
    ...config,
  },
  (table) => {
    return {
      uniqueKey: unique("uq_agent_worfklow_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);
