import { trpc } from "@/utils/trpc";
import { Box } from "@mui/material";
import type { AgenticComponentDeliverable } from "allgood-schema";
import { JSONEditor } from "@/components/JSONEditor";
import { LoadingButton } from "@mui/lab";
import type { DeliverableWidgetContext } from "@/pages/task/components/widgets/deliverable-widgets";
import { AGENTIC_COMPONENT } from "@/pages/task/components/widgets/deliverable-widgets";
import { useEffect, useState } from "react";
import { useAuth } from "@/hooks/use-auth";
import { checkIsSuperUser } from "@/utils/auth";
import Typography from "@mui/material/Typography";

export const AgenticComponentWidget = ({
  deliverableId,
  submitDeliverable,
}: DeliverableWidgetContext) => {
  const auth = useAuth();
  const isSuperUser = checkIsSuperUser(auth.user);
  const [data] = trpc.deliverable.get.useSuspenseQuery(
    { id: deliverableId! },
    { staleTime: 1 },
  );
  const [payload, setPayload] = useState<AgenticComponentDeliverable>({
    reducer: "",
    template: "",
  });

  useEffect(() => {
    if (
      data?.deliverable &&
      payload.reducer === "" &&
      payload.template === ""
    ) {
      setPayload(data.deliverable.data as AgenticComponentDeliverable);
    }
  }, [data]);

  const { mutateAsync: submit, isLoading: isSubmitting } =
    trpc.task.submit.useMutation();

  if (!data) {
    return <div>Task not found</div>;
  }
  if (!data.deliverable) {
    return <div>Deliverable not found</div>;
  }
  const handleSubmit = async () => {
    const res = await submit({
      deliverable: {
        summary: data.deliverable?.summary ?? "",
        widget: {
          id: AGENTIC_COMPONENT,
          params: {},
        },
        data: (payload as AgenticComponentDeliverable) ?? {},
      },
      id: deliverableId!,
      status: "IN_REVIEW",
    });
    if (submitDeliverable) {
      submitDeliverable(payload);
    }
    return res;
  };

  return (
    <Box>
      {isSuperUser && (
        <>
          <Typography variant={"subtitle1"}>(Internal Only)</Typography>
          Reducer
          <JSONEditor
            data={
              (data.deliverable.data as AgenticComponentDeliverable)["reducer"]
            }
            mode={"javascript"}
            onChange={(value) => {
              setPayload({
                ...payload,
                reducer: value as string,
              });
            }}
          />
          Template
          <JSONEditor
            data={
              (data.deliverable.data as AgenticComponentDeliverable)["template"]
            }
            mode={"handlebars"}
            onChange={(value) => {
              setPayload({
                ...payload,
                template: value as string,
              });
            }}
          />
          <LoadingButton
            sx={{ mt: 2 }}
            loading={isSubmitting}
            onClick={async () => {
              await handleSubmit();
            }}
            variant={"contained"}
          >
            Update
          </LoadingButton>
        </>
      )}
    </Box>
  );
};
