import { sql } from "drizzle-orm";
import { index, jsonb, unique, varchar } from "drizzle-orm/pg-core";
import { CheckId, ObservationId } from "../../agid";
import { agid } from "../custom-types";
import { config, createBaseWithId, ownership } from "./base";
import { agConfig, agRuntime } from "./schema";

export const Check = agConfig.table(
  "check",
  {
    ...createBaseWithId<CheckId>(),
    ...ownership,
    ...config,

    /** Category path (e.g. 'Data Completeness' > 'Contacts') */
    category: varchar("category")
      .array()
      .default(sql`array[]::varchar[]`)
      .notNull(),

    /** An identifier for the schema of the payload for Observations of this Check. */
    observationType: varchar("observation_type").notNull(),

    /** If this is a machine check, an identifier of the function which can
     * produce an Observation for this Check. */
    checkType: varchar("check_type"),
  },
  (table) => {
    return {
      uniqueKey: unique("uq_check_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
      byCheckType: index("ix_check_check_type").on(
        table.tenantId,
        table.checkType,
      ),
    };
  },
);

export const Observation = agRuntime.table(
  "observation",
  {
    ...createBaseWithId<ObservationId>(),
    ...ownership,

    checkId: agid("check_id").$type<CheckId>().notNull(),
    comments: varchar("comments"),
    data: jsonb("data").$type<unknown>(),
  },
  (t) => ({
    byCheck: index("ix_observation_check_id").on(t.tenantId, t.checkId),
  }),
);
