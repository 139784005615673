import { z } from "zod";
import {
  ActionId,
  AGCompanyId,
  BusinessObjectiveId,
  CampaignId,
  ContentConfigurationId,
  IntegrationId,
  MilestoneId,
  RecommendationId,
  SignalId,
} from "./agid";

export interface ValidationResult {
  isValid: boolean;
  errors: string[];
}

export interface SourceConfig extends BaseConfig {
  /**
   * URL
   */
  url: string;

  /**
   * Icon
   */
  icon: string;

  /**
   * IntegrationId if available
   */
  integrationId?: IntegrationId;
}

export interface IntegrationMetadata {
  airbyteSourceId?: string;
  dataStartDate?: string;
  dailyExportAllowanceBytes?: number;
}

export interface TenantConfiguration {
  /**
   * TenantConfiguration is the data we store in the DB for a tenant.
   *
   * Note: this is different from the TenantConfig model that is made available to the notebooks and other services.
   * as there might be additional logic performed and not all data is stored in the DB or available to the notebooks.
   */

  /**
   * To make migrations of this data easier in the future, just keep a version tag that is associated with the code version
   */
  version: number;

  /**
   * Expiry of the service token for this tenant.
   */
  serviceTokenExpiry?: string;

  /**
   * Official name of the company associated with this tenant.
   */
  companyName?: string;

  /**
   * Industry of the company associated with this tenant.
   */
  companyIndustry?: string;

  /**
   * Name of the database in Snowflake containing this tenant's data.
   */
  snowflakeDatabase?: string;

  /**
   * Prefix in the ML S3 bucket for the tenant's data.
   */
  s3Prefix?: string;

  /**
   * Name of the Airbyte workspace for the tenant.
   */
  airbyteWorkspace?: string;

  /**
   * Location of the AGx data, including the AG3_dataset and other standard tables.
   * This has been added to allow for SQLLite for initial location.
   *
   */
  /**
   * SSO configuration for the tenant, only required for SAML Auth
   */
  ssoConfig?: SSOConfig;

  /**
   * Integrations Secrets Key
   */
  integrationEncryptionKeyArn?: string;

  serviceIntegrationId?: string;
}

export interface SSOConfig {
  /**
   * Information required for WorkOS SSO Config
   */
  authProvider: string;
  authConnectionId: string;
  authDomain: string;
}

/**
 * Base configuration for a versioned objects.
 */
export class BaseConfig {
  // IF an Agent does an update to the configuration at any time,
  // We can log who they did the update for here
  updatedByAgent?: string;
}

// This config will be for an individual Recommendation Id, will include rules at
// individual level, along with the mapping to actions.
export interface CampaignRecommendationConfiguration {
  // Recommendation Id
  recommendationId: RecommendationId;

  // Milestone to match
  lastMilestoneId?: MilestoneId;

  // Milestone Id
  nextMilestoneId?: MilestoneId;

  // The number of matching order length
  orderLength?: number;

  // is readyTo Convert true
  readyToConvert?: boolean;

  // Minimum confidence level to match
  minimumConfidence?: number;

  // Mapping from Recommendation to Action.
  actions?: ActionId[];

  // Mapping from Recommendation to set of ContentConfigId
  contentConfigs?: ContentConfigurationId[];
}

export interface CampaignConfiguration extends BaseConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entryNodeConfiguration: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exitNodeConfiguration: Record<string, any>;
  prioritizedMilestones: Array<SignalId | MilestoneId>[];
  // The number of members to process an action for in the final step.
  batchSize?: number;

  // scheduled type for campaign [daily, hourly, etc]
  scheduleType?: string;

  // scheduled time for campaign (must include timezone)
  scheduleTime?: Date;

  // recommendation config
  recommendationConfig?: CampaignRecommendationConfiguration[];
}

/**
 * Dashbaord pages have a path and some param substitution.
 *
 * This lets us capture this information to share b/w api and ui.
 */
export interface MissionDashboard {
  // the name that goes on the button
  name: string;

  // the path
  path: string;

  // let the UI know what substitutions are required.
  requiredParams?: string[];
}

export enum WorkflowExecutionStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  CANCELLED = "CANCELLED",
  RETRY = "RETRY",
}

export interface WorkflowExecutionInformation {
  startedAt?: Date;
  endedAt?: Date;
  status?: WorkflowExecutionStatus;
}

export interface CampaignExecutionInfo extends WorkflowExecutionInformation {
  campaignId: CampaignId;
  businessObjectiveId: BusinessObjectiveId;
  preview?: boolean;
  limit?: number;
  cutoffDate?: Date;
}

export interface ContentGenerationInfo extends WorkflowExecutionInformation {
  batchSize?: number;
}

export interface ActionExecutionInfo extends WorkflowExecutionInformation {
  payload?: string;
  batchSize?: number;
  campaignId?: CampaignId;
  actionId?: ActionId;
}

export interface DataPipelineExecutionInfo
  extends WorkflowExecutionInformation {
  s3Prefix: string;
}

export interface DataPipelineTestExecutionInfo
  extends WorkflowExecutionInformation {
  runId: string;
}

export interface JourneyStep {
  contact_id: string;
  source: string;
  timestamp: string;
  milestones: { id: MilestoneId; name?: string }[];
}

export interface DataframeJourney {
  company_id: string;
  journey_steps: JourneyStep[];
  start_time: string;
  end_time: string;
  created_at: string;
  created_by: string;
}

export interface Journey {
  companyId: AGCompanyId;
  journey: JourneyStep[];
  journeySimplified: string[][];
  journeyLength: number;
  startTime: string;
  endTime: string;
}

// TODO: have ID's for the journeys and not store them here anymore
export interface Matching {
  strategy: string;
  confidence: number;
  readyToConvert: boolean;

  prospective: Journey;
  mapping: Journey;
  upcomingMilestones: string[];
  // TODO: Fix this to be SignalGroupId
  nextMilestoneId: string | undefined;
  lastMilestoneId: string | undefined;

  prospectJourneySummary: string; // names of the milestones for search/agg common journey
}

export interface MatchResult {
  strategy: string;
  confidence: number;
  readyToConvert: boolean;
  prospective: string;
  mapping: string;
  upcomingMilestones: string[];
  nextMilestoneId: MilestoneId | null;
  lastMilestoneId: MilestoneId | null;
}

export interface AudienceConfiguration {
  query?: string;
  signalIds?: SignalId[];
}

export interface CampaignMetricsJson {
  // TODO: Add more metrics here as needed
  journeyCount: number;
}

export interface CampaignSignalAnalysisJson {
  signalId: SignalId;
  importance?: number;
  percentage?: number;
}

export interface ContactAttributes {
  firstName?: string;
  lastName?: string;
  title?: string;
  email?: string;
  phone?: string;
  country?: string;
  state?: string;
  jobTitle?: string;
  companyName?: string;
  website?: string;
  leadSource?: string;
  mktoCreatedAt?: Date;
  mktoUpdatedAt?: Date;
  accountRating?: string;
  accountRatingCv?: string;
  contactStatus?: string;
  lifecycleStatus?: string;
  leadScore?: string;
  leadStatus?: string;
  leadRole?: string;
  originalSourceInfo?: string;
  originalSourceType?: string;
  registrationSourceInfo?: string;
  registrationSourceType?: string;
  accountSource?: string;
  acquisitionProgramId?: string;
  mktoAcquisitionDate?: Date;
  numberOfEmployees?: string;
  annualRevenue?: string;
  sfdcAccountId?: string;
  sfdcContactId?: string;
  industry?: string;
  primaryIndustry?: string;
  primarySubIndustry?: string;
  isMarketable?: boolean;
}

export interface CompanyAttributes {
  name?: string;
  website?: string;
  numberOfEmployees?: string;
  annualRevenue?: string;
  type?: string;
  lastActivityDate?: string;
  industry?: string;
  sfdcCreatedAt?: Date;
  accountRating?: string;
  accountRatingCv?: string;
  billingPlan?: string;
  billingPlanFamily?: string;
  renewalDate?: string;
  currentMrr?: number;
  ownerId?: string;
  ownerName?: string;
  ownerEmail?: string;
  lastPlanChangeDate?: string;
  city?: string;
  country?: string;
  linkedinUrl?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActivityContent = Record<string, any>;

export interface ActivityAnnotation {
  signals?: SignalId[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface MLClassifierConfiguration extends Record<any, any> {
  features: string[];
}

/**
 * The content of the email that is sent to the agent.
 */
export interface ContentInfo {
  contentLength: number;
}

export const AgenticComponentDeliverable = z.object({
  reducer: z.string(),
  template: z.string(),
});
export type AgenticComponentDeliverable = z.infer<
  typeof AgenticComponentDeliverable
>;

/**
 * Adding S3Path and S3Prefix as custom types
 */
const brand = Symbol("s3KeyType");

export type S3Path = string & { [brand]: "s3Path" };
export type S3Prefix = string & { [brand]: "s3Prefix" };

function isS3Path(s: string): s is S3Path {
  // TODO: Add more validation
  return s.startsWith("s3://") && !s.endsWith("/");
}

function isS3Prefix(s: string): s is S3Prefix {
  // TODO: Add more validation
  return s.startsWith("s3://") && s.endsWith("/");
}

export const S3Path = z.string().refine(isS3Path, {
  message: "Invalid S3 path",
});

export const S3Prefix = z.string().refine(isS3Prefix, {
  message: "Invalid S3 prefix",
});
