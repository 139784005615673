import { useEffect, useState } from "react";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { MarketoAssistTemplatesFolder } from "allgood-schema";
import type { z } from "zod";
import { TaskWidgetContext } from "@/pages/campaign/configure/team-task";

type MarketoAssistTemplatesFolder = z.infer<
  typeof MarketoAssistTemplatesFolder
>;
type DeliverableType = {
  folder: unknown;
};

const MktoAssistTemplateInput = (context: TaskWidgetContext) => {
  const [config, setConfig] = useState<MarketoAssistTemplatesFolder>({
    folderName: "",
  });

  const deliverable = context.deliverable;
  const handleSubmit = () => {
    const deliverable: DeliverableType = {
      folder: config,
    };

    context.submitDeliverable(deliverable);
  };

  useEffect(() => {
    if (deliverable) {
      const data = deliverable.deliverable?.["data"] as DeliverableType;

      console.log("Deliverable data", data);
      // Parse the deliverable data and set the config if it is valid
      if (data && data.folder) {
        const config = MarketoAssistTemplatesFolder.safeParse(data.folder);

        if (config.success) {
          setConfig(config.data);
        } else {
          console.error("Error parsing deliverable data", config.error.errors);
        }
      }
    }
  }, [deliverable]);

  return (
    <Paper elevation={3} sx={{ p: 3, bgcolor: "#fafafa" }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#333" }}
      >
        Input the name of your templates folder in marketo:
      </Typography>

      <TextField
        label="Folder Name"
        value={config.folderName}
        onChange={(e) => setConfig({ ...config, folderName: e.target.value })}
        fullWidth
        sx={{ mt: 2 }}
      />

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Paper>
  );
};

export default MktoAssistTemplateInput;
