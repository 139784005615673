import type { ChatHistory } from "allgood-api/src/routes/trpc/llm";
import { AgentAvatar } from "@/pages/task/components/agent-avatar";
import { Avatar, capitalize } from "@mui/material";
import React from "react";
import { AgentById, UserById } from "@/utils/by-id";
import { useAuth } from "@/hooks/use-auth";

export const useGetAvatar = (chat: ChatHistory) => {
  const auth = useAuth();
  const picture = auth.user?.profile?.rawAttributes?.picture;

  if (chat.type === "agent" && chat.agentId) {
    return <AgentAvatar agentId={chat.agentId} tooltip={false} />;
  } else if (chat.type === "user") {
    return (
      <Avatar src={chat.userId === auth.user?.userId ? picture : undefined}>
        {chat.name?.[0].toUpperCase()}
      </Avatar>
    );
  } else {
    return <Avatar>{chat.type[0]}</Avatar>;
  }
};

// TODO: Need to get this from the API using the AgentModel
export const getName = (chat: ChatHistory) => {
  if (chat.type === "agent") {
    return (
      <AgentById agentId={chat.agentId!}>{(agent) => agent.name}</AgentById>
    );
  } else if (chat.type === "user") {
    return (
      <UserById userId={chat.userId!}>
        {(user) => (user ? `${user.firstName} ${user.lastName}` : "User")}
      </UserById>
    );
  } else {
    return capitalize(chat.type);
  }
};
