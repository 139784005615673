import { z } from "zod";

// MissionType is an array we can share b/w UI and backend.
export const MissionType = z.enum([
  "CAMPAIGN",
  "BUYER_JOURNEY",
  "DATA_PIPELINE",
  "MARKETING_DATABASE_HEALTH",
  "DATA_NORMALIZATION",
  "ACCOUNT_BASED_ATTRIBUTION",
  "MARKETO_ASSIST",
  "CUSTOM",
]);

export type MissionType = z.infer<typeof MissionType>;

export const TaskType = z.enum(["HUMAN", "SERVICE", "TEAM", "PARENT"]);
export type TaskType = z.infer<typeof TaskType>;

// Marketing Database Health Tasks
// TODO: move this out of here
export const AccountBasedInputTasksSchema = z.object({
  marketableActivities: z.array(z.string()).optional().default([]),
  salesActivities: z.array(z.string()).optional().default([]),
});

export const MarketoAssistTemplatesFolder = z.object({
  folderName: z.string(),
});
