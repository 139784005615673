import React, { useEffect, useState } from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { AccountBasedInputTasksSchema } from "allgood-schema";
import type { z } from "zod";
import { TransferList } from "@/components/transfer-list";
import { trpc } from "src/utils/trpc";
import { TaskWidgetContext } from "@/pages/campaign/configure/team-task";

type AccountBasedInputTasksSchema = z.infer<
  typeof AccountBasedInputTasksSchema
>;
type DeliverableType = {
  activities: unknown;
};

const AccountBasedAttributionSetup = (context: TaskWidgetContext) => {
  const [config, setConfig] = useState<AccountBasedInputTasksSchema>({
    marketableActivities: [],
    salesActivities: [],
  });

  const deliverable = context.deliverable;
  const handleSubmit = () => {
    const deliverable: DeliverableType = {
      activities: config,
    };

    context.submitDeliverable(deliverable);
  };

  const [activityTypes] = trpc.activityType.list.useSuspenseQuery({
    limit: 1000,
  });

  const activityTypeOptions = activityTypes.records.map((activityType) => ({
    label: activityType.name,
    value: activityType.activityTypeName,
  }));

  useEffect(() => {
    if (deliverable) {
      const data = deliverable.deliverable?.["data"] as DeliverableType;

      console.log("Deliverable data", data);
      // Parse the deliverable data and set the config if it is valid
      if (data && data.activities) {
        const config = AccountBasedInputTasksSchema.safeParse(data.activities);

        if (config.success) {
          setConfig(config.data);
        } else {
          console.error("Error parsing deliverable data", config.error.errors);
        }
      }
    }
  }, [deliverable]);

  return (
    <Paper elevation={3} sx={{ p: 3, bgcolor: "#fafafa" }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#333" }}
      >
        Select what signals are considered marketable:
      </Typography>

      <Stack spacing={3}>
        <TransferList
          list={activityTypeOptions}
          listTitle={"List title"}
          selected={activityTypeOptions.filter((activityType) =>
            config.marketableActivities.includes(activityType.value),
          )}
          selectedTitle={"Selected title"}
          onTransfer={(selected) => {
            setConfig((prev) => ({
              ...prev,
              marketableActivities: selected.map((activity) => activity.value),
            }));
          }}
        />
      </Stack>

      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#333" }}
      >
        Select what signals are considered sales:
      </Typography>

      <Stack spacing={3}>
        <TransferList
          list={activityTypeOptions}
          listTitle={"List title"}
          selected={activityTypeOptions.filter((activityType) =>
            config.salesActivities.includes(activityType.value),
          )}
          selectedTitle={"Selected title"}
          onTransfer={(selected) => {
            setConfig((prev) => ({
              ...prev,
              salesActivities: selected.map((activity) => activity.value),
            }));
          }}
        />
      </Stack>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Paper>
  );
};

export default AccountBasedAttributionSetup;
