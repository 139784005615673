import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";

import { Logo } from "@/components/core/logo";
import { RouterLink } from "@/components/core/router-link";
import { paths } from "src/paths";

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = (props) => {
  const { children } = props;

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flex: "1 1 auto",
        flexDirection: {
          xs: "column-reverse",
          md: "row",
        },
        height: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "neutral.800",
          backgroundImage: 'url("/assets/gradient-bg.svg")',
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          color: "common.white",
          display: "flex",
          flex: {
            xs: "0 0 auto",
            md: "1 1 auto",
          },
          justifyContent: "center",
          p: {
            xs: 4,
            md: 8,
          },
        }}
      >
        <Box maxWidth="md">
          <Typography sx={{ mb: 1 }} variant="h4">
            Welcome to allGood
          </Typography>
          {/*<Typography color="text.secondary" sx={{ mb: 4 }}>*/}
          {/*  A professional kit that comes with ready-to-use MUI components*/}
          {/*  developed with one common goal in mind, help you build faster &*/}
          {/*  beautiful applications.*/}
          {/*</Typography>*/}
          {/*<Typography variant="subtitle2" sx={{ mb: 2 }}>*/}
          {/*  Join 6,000+ forward-thinking companies:*/}
          {/*</Typography>*/}
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            gap={4}
            sx={{
              color: "text.primary",
              "& > *": {
                color: "neutral.400",
                flex: "0 0 auto",
              },
            }}
          ></Stack>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.paper",
          display: "flex",
          flex: {
            xs: "1 1 auto",
            md: "0 0 auto",
          },
          flexDirection: "column",
          justifyContent: {
            md: "center",
          },
          maxWidth: "100%",
          p: {
            xs: 4,
            md: 8,
          },
          width: {
            md: 600,
          },
        }}
      >
        <div>
          <Box sx={{ mb: 4, textAlign: "center" }}>
            <Box
              component={RouterLink}
              href={paths.index}
              sx={{ textDecoration: "none" }}
            >
              <Logo width={200} height={80} />
            </Box>
          </Box>
          {children}
        </div>
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
