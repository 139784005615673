import {
  agBlue,
  agOrange,
  agPink,
  agTeal,
  agYellow,
} from "@/styles/theme/colors";

export const chartColors = [
  agYellow[300],
  agPink[500],
  agBlue[300],
  agTeal[300],
  agOrange[400],
  agPink[300],
  agBlue[400],
  agTeal[600],
  agOrange[300],
  agYellow[500],
];
