import Button from "@mui/material/Button";
import type { FC } from "react";
import React from "react";
import { useRenderHandlebarTemplate } from "@/utils/handlebars";

type SuggestionButtonProps = {
  suggestion: string;
  data: unknown;
  onClick: (compiledSuggestion?: string) => void;
};
export const SuggestionButton: FC<SuggestionButtonProps> = ({
  suggestion,
  data,
  onClick,
}) => {
  const { component, text } = useRenderHandlebarTemplate({
    template: suggestion,
    data: data,
  });

  return (
    <Button
      variant={"outlined"}
      color={"primary"}
      sx={{
        boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.03)",
        borderRadius: "30px",
        flex: 1,
        color: "#777",
      }}
      onClick={(e) => {
        console.log(e);
        // do not call onClick if the target is not this button
        // or target is not from its child. This is especially for select box inside triggers this onClick.
        if (
          e.target !== e.currentTarget &&
          e.target !== e.currentTarget.firstChild
        ) {
          return;
        }

        onClick(text);
      }}
    >
      {component}
    </Button>
  );
};
