import React, { useState, useMemo, useEffect } from "react";
import {
  Paper,
  Typography,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  LinearProgress,
  Box,
} from "@mui/material";
import { AuditPreReqHumanTasksSchema } from "allgood-api/src/services/mission/mission.schema";
import { z } from "zod";
import { TaskWidgetContext } from "@/pages/campaign/configure/team-task";

type AuditPreReqHumanTaskData = z.infer<typeof AuditPreReqHumanTasksSchema>;
const AuditPreReqHumanTasksForm = (context: TaskWidgetContext) => {
  const { task, deliverable } = context;
  const [config, setConfig] = useState<AuditPreReqHumanTaskData>({
    marketoConnected: false,
    salesforceConnected: false,
    smartListName: "",
    slackConnected: false,
  });

  useEffect(() => {
    const ret = AuditPreReqHumanTasksSchema.safeParse(
      deliverable?.deliverable?.data,
    );
    if (ret.success) {
      // since this data is used for components, undefined will mess up controlled vs uncontrolled components
      // so we need to make sure we have a default value for all fields
      setConfig({
        ...config,
        ...ret.data,
      });
    } else {
      // context.showMessage("Failed to load task data");
    }
  }, [task]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;
    setConfig((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = () => {
    context.submitDeliverable(config);
  };

  const progress = useMemo(() => {
    const totalTasks = Object.keys(config).length;
    const completedTasks = Object.values(config).filter(Boolean).length;
    return (completedTasks / totalTasks) * 100;
  }, [config]);

  return (
    <Paper elevation={3} sx={{ p: 3, bgcolor: "#fafafa" }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#333" }}
      >
        Please complete the task requirements:
      </Typography>

      <LinearProgress variant="determinate" value={progress} sx={{ mb: 3 }} />

      <Stack spacing={3}>
        <FormControlLabel
          control={
            <Switch
              checked={config.marketoConnected}
              onChange={handleChange}
              name="marketoConnected"
            />
          }
          label="Have you connected Marketo?"
          labelPlacement="start"
          sx={{ justifyContent: "space-between", ml: 0 }}
        />
        <Typography variant="body2" color="text.secondary">
          Marketo can be connected by following the instructions, click
          Settings, Integrations.
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={config.salesforceConnected}
              onChange={handleChange}
              name="salesforceConnected"
            />
          }
          label="Have you connected Salesforce?"
          labelPlacement="start"
          sx={{ justifyContent: "space-between", ml: 0 }}
        />
        <Typography variant="body2" color="text.secondary">
          Salesforce can be connected by following the instructions, click
          Settings, Integrations.
        </Typography>

        <TextField
          label="Name of Marketable Smart List"
          variant="outlined"
          name="smartListName"
          value={config.smartListName}
          onChange={handleChange}
          error={config.smartListName === ""}
          helperText={
            config.smartListName === ""
              ? "This field is required"
              : "SmartLists in Marketo can be imported into AllGood by adding the name here."
          }
          fullWidth
        />

        <FormControlLabel
          control={
            <Switch
              checked={config.slackConnected}
              onChange={handleChange}
              name="slackConnected"
            />
          }
          label="Have you connected Slack to AllGood for notifications?"
          labelPlacement="start"
          sx={{ justifyContent: "space-between", ml: 0 }}
        />
        <Typography variant="body2" color="text.secondary">
          Slack can be connected by following the instructions, click Settings,
          Integrations. Doing so will let our guides notify you when they need
          your attention.
        </Typography>
      </Stack>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Paper>
  );
};

export default AuditPreReqHumanTasksForm;
