import type { ComponentProps } from "react";
import * as React from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import type {
  CreateInsight,
  Priority,
  UpdateInsight,
} from "allgood-api/src/repos/insight.schema";
import { Insight } from "allgood-api/src/repos/insight.schema";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SignalCellularAlt1BarRoundedIcon from "@mui/icons-material/SignalCellularAlt1BarRounded";
import SignalCellularAlt2BarRoundedIcon from "@mui/icons-material/SignalCellularAlt2BarRounded";
import SignalCellularAltRoundedIcon from "@mui/icons-material/SignalCellularAltRounded";
import SignalCellularNullRoundedIcon from "@mui/icons-material/SignalCellularNullRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AssistantIcon from "@mui/icons-material/Assistant";
import { buildRoute } from "@/utils/route";
import { paths } from "@/paths";
import { useRouter } from "@/hooks/use-router";
import { trpc } from "@/utils/trpc";
import { InvokerAvatar } from "@/pages/task/components/avatar";
import { Invoker } from "allgood-api/src/context";
import { z } from "zod";

// We don't need the invoker field---LLM widgets don't have it. Make it optional.
export const CardInsight = z.object({
  ...Insight.shape,
  createdBy: Invoker.optional(),
});
export type CardInsight = z.infer<typeof CardInsight>;

// Static assert that `CardInsight` assigns to `UpdateInsight`
const _assertUpdateInsight = {} as CardInsight satisfies UpdateInsight;

export type Props = {
  insight: CardInsight;
  onChange?: (insight: UpdateInsight) => void;
  onDelete?: (insight: CardInsight) => void;
  sx?: ComponentProps<typeof Card>["sx"];
};

export default function InsightCard({
  insight,
  onChange,
  onDelete,
  sx = {},
}: Props) {
  const onChangePriority = onChange
    ? (priority: Priority) => onChange({ ...insight, priority })
    : undefined;

  // If we don't have a createdBy, it was probably agent-created, because we're rendering as an LLM widget.
  const isHuman = !insight.createdBy || insight.createdBy._type === "user";

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        // I tried the brand colors here, they look sort of drab and awful.
        background: `linear-gradient(145deg, #fb9327, #d92fc0 40%, #00d4ff)`,
        minHeight: "15rem",
        maxWidth: "30rem",
        ...sx,
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{ flex: 0, alignItems: "center", ml: 3, mr: 1.5, mt: 1.5 }}
      >
        <Typography
          variant="subtitle1"
          color="white"
          sx={{ letterSpacing: 1, marginRight: "auto" }}
        >
          {!isHuman && <strong>AI</strong>} INSIGHT
        </Typography>

        <EditPriorityButton
          priority={insight.priority}
          onChangePriority={onChangePriority}
        />
        {onChange && (
          <EditInsightButton insight={insight} onSubmit={onChange} />
        )}
        {onDelete && (
          <DeleteInsightButton insight={insight} onDelete={onDelete} />
        )}
        <OpenThreadButton insight={insight} />
        {insight.createdBy && (
          <InvokerAvatar
            size={32}
            invoker={insight.createdBy}
            TooltipProps={{ placement: "bottom-end" }}
          />
        )}
      </Stack>
      <CardContent
        sx={{
          minHeight: "0", // Allow card content to shrink and clip content
          flex: 1,
          display: "flex",
          flexDirection: "column",
          background: "white",
          borderRadius: 2,
          margin: 1,
          paddingBottom: "1em !important", // MUI theme does something dumb here.
        }}
      >
        <Stack spacing={2} sx={{ flex: 1, minHeight: "0" }}>
          {/* If the insight has a bigNumber, display it; otherwise, display the name. */}
          {insight.content.bigNumber ? (
            <Stack>
              {insight.content.bigNumber && (
                <Typography variant="h5" component="div" sx={{ padding: 0 }}>
                  {insight.content.bigNumber}
                </Typography>
              )}
              <Typography variant="subtitle2">{insight.name}</Typography>
            </Stack>
          ) : (
            <Stack>
              <Typography variant="h5" component="div">
                {insight.name}
              </Typography>
            </Stack>
          )}

          {/* Insight body text */}
          <FadeOut
            side="bottom"
            sx={{ flex: "1", flexShrink: "1" }}
            fadeSize="4rem"
          >
            <Typography variant="body2">{insight.description}</Typography>
          </FadeOut>
        </Stack>
      </CardContent>
    </Card>
  );
}

function OpenThreadButton({ insight }: { insight: CardInsight }) {
  const router = useRouter();

  // Track clicked/hovered state.
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  // Fetch the associated task only when clicked or hovered.
  const { data: task } = trpc.task.get.useQuery(
    { id: insight.taskId },
    { enabled: hovered || clicked },
  );

  // If we have the associated task and we have been clicked, navigate.
  useEffect(() => {
    if (task && clicked) {
      const params = new URLSearchParams();
      if (insight.threadId) {
        params.append("threadId", insight.threadId);
      }
      router.push(
        buildRoute(paths.missions.task, {
          missionId: task.missionId,
          taskId: task.id,
          // TODO: Fix ALL-2114
          //          instanceId: "",
        }) +
          "?" +
          params.toString(),
      );
    }
  }, [clicked, task, insight.threadId, router]);

  return (
    <ButtonSurface
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      onClick={() => setClicked(true)}
    >
      <Tooltip title="Open Thread" placement="bottom-end">
        <IconButton size="small">
          <AssistantIcon sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>
    </ButtonSurface>
  );
}

/** Show a delete button that opens a confirmation dialog before firing the delete. */
function DeleteInsightButton({
  insight,
  onDelete,
}: {
  insight: CardInsight;
  onDelete: (insight: CardInsight) => void;
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonSurface>
        <Tooltip title="Delete Insight" placement="bottom-end">
          <IconButton size="small" onClick={() => setOpen(true)}>
            <DeleteIcon sx={{ color: "white" }} />
          </IconButton>
        </Tooltip>
      </ButtonSurface>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete Insight</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the insight "{insight.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button color="warning" onClick={() => onDelete(insight)}>
            Delete Insight
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function EditPriorityButton({
  priority,
  onChangePriority,
  sx = {},
  iconColor = "white",
  ButtonProps,
}: {
  priority: Priority;
  onChangePriority?: (priority: Priority) => void;
  sx?: Parameters<typeof Button>[0]["sx"];
  iconColor?: string;
  ButtonProps?: ComponentProps<typeof Button>;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  if (!onChangePriority) {
    return (
      <Box ref={ref} sx={sx}>
        <PriorityIcon sx={{ color: iconColor }} priority={priority} />
      </Box>
    );
  }

  const setPriority = (priority: Priority) => {
    setOpen(false);
    onChangePriority(priority);
  };

  return (
    <ButtonSurface ref={ref} sx={sx}>
      <Tooltip title="Change Priority" placement="bottom-end">
        <Button size="small" onClick={() => setOpen(true)} {...ButtonProps}>
          <PriorityIcon sx={{ color: iconColor }} priority={priority} />
        </Button>
      </Tooltip>
      <Menu anchorEl={ref.current} open={open} onClose={() => setOpen(false)}>
        {PRIORITY_NAMES.map((name, p) => (
          <MenuItem key={p} onClick={() => setPriority(p as Priority)}>
            <ListItemIcon>
              <PriorityIcon
                priority={p as Priority}
                sx={{ color: "primary" }}
              />
            </ListItemIcon>
            <ListItemText>{name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </ButtonSurface>
  );
}

const ButtonSurface = forwardRef(function ButtonSurface(
  props: ComponentProps<typeof Box>,
  ref: ComponentProps<typeof Box>["ref"],
) {
  return (
    <Box
      {...props}
      ref={ref}
      sx={{ background: "rgba(255,255,255,0.2)", borderRadius: 1, ...props.sx }}
    >
      {props.children}
    </Box>
  );
});

export const PRIORITY_NAMES = [
  "Unprioritized",
  "Low Priority",
  "Medium Priority",
  "High Priority",
];

export function PriorityIcon({
  priority,
  sx = {},
}: {
  priority: number;
  sx?: ComponentProps<typeof SignalCellularAlt1BarRoundedIcon>["sx"];
}) {
  if (priority === 1) {
    return <SignalCellularAlt1BarRoundedIcon sx={sx} />;
  }
  if (priority === 2) {
    return <SignalCellularAlt2BarRoundedIcon sx={sx} />;
  }
  if (priority === 3) {
    return <SignalCellularAltRoundedIcon sx={sx} />;
  }
  return <SignalCellularNullRoundedIcon sx={sx} />;
}

function FadeOut({
  children,
  side = "right",
  fadeSize = "3rem",
  overflow = "hidden",
  ...rest
}: {
  children: React.ReactNode;
  side?: "right" | "bottom";
  overflow?: "hidden" | "scroll";
  fadeSize?: string;
} & React.ComponentProps<typeof Box>) {
  const gradientDirection: string = {
    right: "to left",
    bottom: "to top",
  }[side];

  const boxRef = useRef<HTMLDivElement>(null);
  const [overflowing, setOverflowing] = useState(false);

  // If the boxRef is overflowing, enable the gradient style.
  useEffect(() => {
    const obs = new ResizeObserver((entries) => {
      const el = entries[0].target;
      setOverflowing(
        el.scrollHeight != el.clientHeight || el.scrollWidth != el.clientWidth,
      );
    });
    obs.observe(boxRef.current!);
    return () => {
      if (boxRef.current) {
        obs.unobserve(boxRef.current);
      }
    };
  }, []);

  const sx = {
    overflow,
    maskImage: overflowing
      ? `linear-gradient(${gradientDirection}, transparent, black ${fadeSize})`
      : "none",
  };
  return (
    <Box {...rest} ref={boxRef} sx={{ ...sx, ...rest.sx }}>
      {children}
    </Box>
  );
}

function EditInsightButton({
  insight,
  onSubmit,
}: {
  insight: CardInsight;
  onSubmit: (insight: CardInsight) => void;
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <ButtonSurface>
        <Tooltip title="Edit Insight" placement="bottom-end">
          <IconButton size="small" onClick={() => setDrawerOpen(true)}>
            <EditIcon sx={{ color: "white" }} />
          </IconButton>
        </Tooltip>
      </ButtonSurface>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{ sx: { display: "flex", flexDirection: "column" } }}
      >
        <EditInsightForm
          title="Edit Insight"
          insight={insight}
          onSubmit={(insight) => {
            setDrawerOpen(false);
            onSubmit(insight);
          }}
          onCancel={() => setDrawerOpen(false)}
        />
      </Drawer>
    </>
  );
}

export function EditInsightForm<T extends CreateInsight>({
  insight,
  onSubmit,
  onCancel,
  title,
}: {
  title: string;
  insight: T;
  onSubmit: (insight: T) => void;
  onCancel: () => void;
}) {
  const [priority, setPriority] = useState<Priority>(
    (insight.priority as Priority) ?? 0,
  );
  const [name, setName] = useState(insight.name);
  const [description, setDescription] = useState(insight.description);
  const [bigNumber, setBigNumber] = useState(insight.content.bigNumber || "");

  const handleSubmit = () =>
    onSubmit({
      ...insight,
      priority,
      name: name.trim(),
      description: description.trim(),
      content: {
        bigNumber: bigNumber.trim().length > 0 ? bigNumber : undefined,
      },
    });

  return (
    <Stack
      sx={{
        m: 4,
        flex: 1,
        width: 800,
        display: "flex",
        flexDirection: "column",
      }}
      spacing={2}
    >
      <Typography variant="h4">{title}</Typography>
      <Stack spacing={2}>
        <Stack direction="row" sx={{ alignItems: "end" }} spacing={2}>
          <TextField
            label="Insight Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ flex: 1 }}
          />
          <EditPriorityButton
            priority={priority ?? 0}
            onChangePriority={setPriority}
            iconColor="primary"
            ButtonProps={{ variant: "outlined", size: "medium" }}
          />
          <TextField
            label="Big Number"
            value={bigNumber}
            onChange={(e) => setBigNumber(e.target.value)}
            sx={{ width: 200 }}
          />
        </Stack>
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          minRows={5}
        />
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{ marginTop: "auto", justifyContent: "end" }}
      >
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained">
          Save
        </Button>
      </Stack>
    </Stack>
  );
}
