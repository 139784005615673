import type { StreamEvent } from "@langchain/core/dist/tracers/log_stream";
import { CollapseBox } from "@/components/collapse-box";
import { Box } from "@mui/system";
import { Chip } from "@mui/material";
import { JSONEditor } from "@/components/JSONEditor";
import Typography from "@mui/material/Typography";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const inputToString = (input: Record<string, any>) => {
  const text = Object.entries(input)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length > 1) {
          return `${value.length} ${key.replace("_names", "")}s`;
        } else {
          return undefined;
        }
      } else if (!Boolean(value)) {
        return undefined;
      }
      return `${key}: ${value}`;
    })
    .filter(Boolean)
    .join(", ");
  return text ? "with " + text : "";
};

export const renderSystemMessage = (event: StreamEvent) => {
  // add update_ to this as well.
  const retrieve = RegExp(/agg_|get_/);
  const update = RegExp(/update_|clone_/);
  const submitTask = RegExp(/submit_task|send_/);
  const createInsight = RegExp(/create_insight/);
  if (
    event.name.match(retrieve) ||
    event.name.match(update) ||
    event.name.match(submitTask) ||
    event.name.match(createInsight)
  ) {
    let action = "";
    if (event.name.match(submitTask)) {
      action = "Submitting";
    }
    if (event.name.match(update)) {
      action = "Updating";
    }
    if (event.name.match(retrieve)) {
      action = "Collecting";
    }
    if (event.name.match(createInsight)) {
      action = "Creating";
    }

    if (event.event === "on_tool_start") {
      try {
        event.data.input = JSON.parse(event.data.input.input);
      } catch (_e) {}
      return (
        <CollapseBox
          title={`${action} data for ${event.name
            .replace(retrieve, "")
            .replaceAll("_", " ")} ${inputToString(event.data.input)}`}
          collapseIconPosition={"left"}
        >
          <Box>
            <Chip label={`tool: ${event.name}`} size="small" />
            <JSONEditor data={event.data.input} />
          </Box>
        </CollapseBox>
      );
    } else if (event.event === "on_tool_end") {
      try {
        event["data"].output = JSON.parse(
          event["data"].output?.kwargs?.content,
        );
      } catch (_e) {
        // console.warn(e);
      }

      const eventName = event.name.replace(retrieve, "").replaceAll("_", " ");

      return (
        <CollapseBox
          title={`Got ${
            event["data"].output?.length
              ? `${event["data"].output.length} ${eventName}`
              : `${eventName}`
          }`}
          collapseIconPosition={"left"}
        >
          <JSONEditor data={event.data.output} />
        </CollapseBox>
      );
    }
  } else if (event.event === "on_tool_end" && event.name === "route") {
    return (
      <Typography variant={"caption"} color={"text.secondary"}>
        [Talking to {event.data.input?.next.replaceAll("_", " ")}]
      </Typography>
    );
  } else if (event.event === "error") {
    return (
      <CollapseBox
        title={`Error in ${event.name
          .replace(retrieve, "")
          .replaceAll("_", " ")} ${inputToString(event.data.input)}`}
        collapseIconPosition={"left"}
      >
        {
          // @ts-ignore
          event.error
        }
      </CollapseBox>
    );
  } else {
    // console.log("Unknown event", event);
  }
};
