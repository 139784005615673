import { Compass } from "@phosphor-icons/react/dist/csr/Compass";
import { RocketLaunch } from "@phosphor-icons/react/dist/csr/RocketLaunch";
import { Gear as GearIcon } from "@phosphor-icons/react/dist/csr/Gear";
import { House as HouseIcon } from "@phosphor-icons/react/dist/csr/House";
import { Building } from "@phosphor-icons/react/dist/csr/Building";
import { Users } from "@phosphor-icons/react/dist/csr/Users";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

export const icons = {
  home: HouseIcon,
  campaign: RocketLaunch,
  guide: Compass,
  companies: Building,
  users: Users,
  admin: AdminPanelSettingsIcon,
  settings: GearIcon,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as Record<string, any>;
