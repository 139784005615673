import { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";

const AdminPage = lazy(() => import("@/pages/admin/index"));

const TakeoutImportPage = lazy(() => import("@/pages/admin/takeout/import"));
const TakeoutExportPage = lazy(() => import("@/pages/admin/takeout/export"));
const MilestonePage = lazy(
  () => import("@/pages/admin/milestone/milestones-page"),
);
const MilestoneCreatePage = lazy(
  () => import("@/pages/admin/milestone/milestone-create-page"),
);
const MilestoneDetailsPage = lazy(
  () => import("@/pages/admin/milestone/milestone-details-page"),
);
const SignalPage = lazy(() => import("@/pages/admin/signals/signals-page"));
const SignalDetailPage = lazy(
  () => import("@/pages/admin/signals/signal-details-page"),
);
const SignalCreatePage = lazy(
  () => import("@/pages/admin/signals/signal-create-page"),
);
// const CompanyPage = lazy(() => import("src/pages/company/company-page"));

const BusinessObjectivesPage = lazy(
  () => import("@/pages/admin/business-objective/business-objectives-page"),
);
const BusinessObjectivesDetailsPage = lazy(
  () =>
    import("@/pages/admin/business-objective/business-objective-details-page"),
);
const BusinessObjectivesCreatePage = lazy(
  () =>
    import("@/pages/admin/business-objective/business-objective-create-page"),
);

const AgentsPage = lazy(() => import("@/pages/admin/agents/agents-page"));
const AgentCreatePage = lazy(
  () => import("@/pages/admin/agents/agent-create-page"),
);
const AgentDetailsPage = lazy(
  () => import("@/pages/admin/agents/agent-details-page"),
);

const AgentWorkflowPage = lazy(
  () => import("@/pages/admin/agent-workflow/agent-workflow-page"),
);
const AgentWorkflowCreatePage = lazy(
  () => import("@/pages/admin/agent-workflow/agent-workflow-create-page"),
);
const AgentWorkflowDetailsPage = lazy(
  () => import("@/pages/admin/agent-workflow/agent-workflow-details-page"),
);
const WorkflowPage = lazy(() => import("@/pages/admin/workflows"));

const AuditChecksPage = lazy(
  () => import("@/pages/admin/audit/checks/checks-page"),
);

const AuditChecksCreatePage = lazy(
  () => import("@/pages/admin/audit/checks/check-create-page"),
);

const AuditChecksConfigurePage = lazy(
  () => import("@/pages/admin/audit/checks/check-details-page"),
);

const AuditObservationsPage = lazy(
  () => import("@/pages/admin/audit/observations/observations-page"),
);

const AuditObservationsConfigurePage = lazy(
  () => import("@/pages/admin/audit/observations/observation-detail-page"),
);

/** Meta Data Pages **/
const MetaDataFieldsPage = lazy(
  () => import("@/pages/admin/metadata/fields/fields-page"),
);
const MetaDataFieldConfigurePage = lazy(
  () => import("@/pages/admin/metadata/fields/field-details-page"),
);
const MetadataFieldCreatePage = lazy(
  () => import("@/pages/admin/metadata/fields/field-create-page"),
);

const MetaDataAttributesPage = lazy(
  () => import("@/pages/admin/metadata/attributes/attributes-page"),
);

const MetaDataAttributeConfigurePage = lazy(
  () => import("@/pages/admin/metadata/attributes/attributes-details-page"),
);

const MetaDataActivityTypesPage = lazy(
  () => import("@/pages/admin/metadata/activity-types/activity-types-page"),
);

const MetaDataActivityTypeConfigurePage = lazy(
  () =>
    import("@/pages/admin/metadata/activity-types/activity-types-details-page"),
);

const MarketoSyncPage = lazy(
  () => import("src/pages/admin/metadata/marketo-sync/sync"),
);

export const adminRoutes: RouteObject[] = [
  {
    path: paths.admin.index,
    element: <AdminPage />,
  },
  {
    path: paths.admin.takeout.import,
    element: <TakeoutImportPage />,
  },
  {
    path: paths.admin.takeout.export,
    element: <TakeoutExportPage />,
  },
  {
    path: paths.admin.milestones,
    element: <MilestonePage />,
  },
  {
    path: paths.admin.createMilestones,
    element: <MilestoneCreatePage />,
  },
  {
    path: paths.admin.configureMilestones,
    element: <MilestoneDetailsPage />,
  },
  {
    path: paths.admin.businessObjectives,
    element: <BusinessObjectivesPage />,
  },
  {
    path: paths.admin.createBusinessObjectives,
    element: <BusinessObjectivesCreatePage />,
  },
  {
    path: paths.admin.configureBusinessObjectives,
    element: <BusinessObjectivesDetailsPage />,
  },
  {
    path: paths.admin.signals,
    element: <SignalPage />,
  },
  {
    path: paths.admin.createSignals,
    element: <SignalCreatePage />,
  },
  {
    path: paths.admin.configureSignals,
    element: <SignalDetailPage />,
  },
  {
    path: paths.admin.agents,
    element: <AgentsPage />,
  },
  {
    path: paths.admin.createAgent,
    element: <AgentCreatePage />,
  },
  {
    path: paths.admin.configureAgent,
    element: <AgentDetailsPage />,
  },
  {
    path: paths.admin.agentWorkflows,
    element: <AgentWorkflowPage />,
  },
  {
    path: paths.admin.createAgentWorkflows,
    element: <AgentWorkflowCreatePage />,
  },
  {
    path: paths.admin.configureAgentWorkflows,
    element: <AgentWorkflowDetailsPage />,
  },
  {
    path: paths.admin.workflows,
    element: <WorkflowPage />,
  },

  /** Audit Pages **/
  {
    path: paths.admin.audit.checks.index,
    element: <AuditChecksPage />,
  },
  {
    path: paths.admin.audit.checks.create,
    element: <AuditChecksCreatePage />,
  },
  {
    path: paths.admin.audit.checks.configure,
    element: <AuditChecksConfigurePage />,
  },
  {
    path: paths.admin.audit.observations.index,
    element: <AuditObservationsPage />,
  },
  {
    path: paths.admin.audit.observations.configure,
    element: <AuditObservationsConfigurePage />,
  },
  {
    path: paths.admin.audit.observations.create,
    element: <AuditObservationsConfigurePage />,
  },

  /** Meta Data Pages **/
  {
    path: paths.admin.metadata.fields.index,
    element: <MetaDataFieldsPage />,
  },
  {
    path: paths.admin.metadata.fields.create,
    element: <MetadataFieldCreatePage />,
  },
  {
    path: paths.admin.metadata.fields.configure,
    element: <MetaDataFieldConfigurePage />,
  },
  {
    path: paths.admin.metadata.attributes.index,
    element: <MetaDataAttributesPage />,
  },
  {
    path: paths.admin.metadata.attributes.create,
    element: <MetaDataAttributeConfigurePage />,
  },
  {
    path: paths.admin.metadata.attributes.configure,
    element: <MetaDataAttributeConfigurePage />,
  },
  {
    path: paths.admin.metadata.activityTypes.index,
    element: <MetaDataActivityTypesPage />,
  },
  {
    path: paths.admin.metadata.activityTypes.configure,
    element: <MetaDataActivityTypeConfigurePage />,
  },
  {
    path: paths.admin.metadata.marketoSync.index,
    element: <MarketoSyncPage />,
  },
];
