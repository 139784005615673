import { index, jsonb, timestamp, varchar } from "drizzle-orm/pg-core";
import { WorkflowRunId, WorkflowCheckpointId } from "../../agid";
import { agid } from "../custom-types";
import { baseNoId, createBaseWithId, ownership } from "./base";
import { agRuntime } from "./schema";

export const WorkflowExecution = agRuntime.table(
  "workflow_execution",
  {
    ...baseNoId,

    // NOTE: this does NOT include a default, because this is a Temporal workflow
    //       run ID. Do not generate UUIDs that aren't from Temporal and stick them in
    //       this column.
    id: agid("id").$type<WorkflowRunId>().primaryKey().notNull(),

    /** Temporal workflow name. */
    workflowId: varchar("workflow_id").notNull(),

    /** Temporal workflow type. */
    workflowType: varchar("workflow_type").notNull(),

    /** Workflow arguments. */
    args: jsonb("args").notNull(),

    /** Result returned from the workflow. */
    result: jsonb("result"),

    /** Error thrown from the workflow, if it has failed. */
    error: jsonb("error"),

    /** When the workflow finished (either successfully or with error). */
    completedAt: timestamp("completed_at"),
  },
  (t) => ({
    workflowIdIdx: index("workflow_execution__wf_id").on(t.workflowId),
    typeIds: index("workflow_execution__wf_type").on(t.workflowType),
  }),
);

export const WorkflowCheckpoint = agRuntime.table(
  "workflow_checkpoint",
  {
    ...createBaseWithId<WorkflowCheckpointId>(),
    ...ownership,

    /** Workflow run ID. */
    workflowRunId: agid("workflow_run_id").$type<WorkflowRunId>().notNull(),

    /** Checkpoint data. */
    contents: jsonb("contents").notNull(),
  },
  (t) => ({
    workflowIdIdx: index("workflow_checkpoint__wf_run_id").on(t.workflowRunId),
  }),
);
