import type { SetURLSearchParams } from "react-router-dom";
import {
  useLocation,
  useSearchParams as _useSearchParams,
} from "react-router-dom";

/**
 * react-router-dom has a bug where the URLSearchParams object is not updated reliably.
 */
export const useSearchParams = (): [URLSearchParams, SetURLSearchParams] => {
  const [_searchParams, setSearchParams] = _useSearchParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return [searchParams, setSearchParams];
};
