import type { ActivityFilters } from "@/components/activity/helpers/activity-filters";
import { AgenticComponent } from "@/components/agentic-component";
import { useDeliverable } from "@/hooks/use-deliverable";
import { SideChat } from "@/pages/task/components/side-chat";
import { Box, Checkbox, ListItemButton } from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import type { ContactWithTouchCount } from "allgood-api/src/services/opportunity_engagement.schema";
import type { AGContactId, MissionId } from "allgood-schema";
import type { Dispatch, FC, SetStateAction } from "react";
import React, { useEffect } from "react";
import { CollapseBox } from "src/components/collapse-box";
import Loading from "src/components/loading";
import type { FunctionChainInputType } from "allgood-api/src/services/rap/rap-functions/company-contact";

export function not<T>(a: readonly T[], b: readonly T[]): T[] {
  return a.filter((value) => b.indexOf(value) === -1);
}

export function intersection<T>(a: readonly T[], b: readonly T[]): T[] {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export function union<T>(a: readonly T[], b: readonly T[]): T[] {
  return [...a, ...not(b, a)];
}

type ContactFilterProps = {
  missionId?: MissionId;
  filters?: ActivityFilters;
  setFilters: Dispatch<SetStateAction<ActivityFilters>>;
  functionChainInput: FunctionChainInputType;
  contacts: ContactWithTouchCount[];
};
export const ContactFilter: FC<ContactFilterProps> = ({
  contacts,
  missionId,
  functionChainInput,
  filters,
  setFilters,
}) => {
  const [selectedContacts, setSelectedContacts] = React.useState<string[]>(
    filters?.contacts ?? [],
  );

  useEffect(() => {
    setFilters((filters) => ({
      ...filters,
      contacts: selectedContacts as AGContactId[],
    }));
  }, [selectedContacts]);

  const {
    template,
    isLoading: isLoadingContactUI,
    refetch: refetchContactUI,
  } = useDeliverable<{ contacts: NonNullable<typeof contacts> }>({
    missionId,
    taskKey: "accountBasedAttributionAccountContactUI",
    data: { contacts: contacts ?? [] },
    evaluate: false,
  });

  const numberOfChecked = (items: readonly string[]) =>
    intersection(selectedContacts, items).length;

  const handleToggleAll = (items: readonly string[]) => {
    if (numberOfChecked(items) === items.length) {
      setSelectedContacts(not(selectedContacts, items));
    } else {
      setSelectedContacts(union(selectedContacts, items));
    }
  };

  return (
    <>
      {contacts ? (
        <List
          disablePadding
          sx={{
            borderTop: 1,
            borderColor: "divider",
          }}
        >
          <CollapseBox
            collapseIconPosition={"right"}
            title={
              <>
                <Typography
                  sx={{
                    px: 2,
                    py: 1,
                  }}
                  variant={"subtitle2"}
                  color={"text.secondary"}
                >
                  <Checkbox
                    sx={{ mr: 1 }}
                    checked={
                      numberOfChecked(selectedContacts) === contacts.length
                    }
                    indeterminate={
                      numberOfChecked(selectedContacts) !== 0 &&
                      numberOfChecked(selectedContacts) !== contacts.length
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleAll(
                        contacts.map(
                          (contact: ContactWithTouchCount) => contact.id,
                        ),
                      );
                    }}
                  />
                  CONTACTS{" "}
                  {`(${selectedContacts.length} / ${contacts.length}) `}
                  <Box sx={{ float: "right", mt: "-4px" }}>
                    <SideChat
                      taskKey={"accountBasedAttributionAccountContactUI"}
                      llmContext={{
                        functionChain: functionChainInput,
                      }}
                      onSubmitDeliverable={() => {
                        // refetchListContacts();
                        refetchContactUI();
                      }}
                    />
                  </Box>
                </Typography>
              </>
            }
            isExpanded={true}
            collapsible={false}
          >
            <Loading loading={isLoadingContactUI} fullscreen={false}>
              <Box
                sx={{
                  borderTop: 1,
                  borderColor: "divider",
                }}
              >
                {contacts.map((contact: ContactWithTouchCount) => {
                  const checked = selectedContacts.includes(contact.id);
                  return (
                    <ListItemButton
                      key={contact.id}
                      sx={{ py: 1, whiteSpace: "break-words" }}
                      divider
                      onClick={() => {
                        if (checked) {
                          setSelectedContacts((prev) =>
                            prev.filter((id) => id !== contact.id),
                          );
                        } else {
                          setSelectedContacts((prev) => [...prev, contact.id]);
                        }
                      }}
                    >
                      <Checkbox checked={checked} />
                      <ListItemText
                        primary={`${contact.fieldRollup.firstName} ${contact.fieldRollup.lastName}`}
                        secondary={
                          <Typography
                            variant={"body2"}
                            color={"text.secondary"}
                            lineHeight={1.4}
                          >
                            {template ? (
                              <AgenticComponent
                                template={template}
                                // TODO LLM randomly use wrong variables. This is a hack to fix it
                                data={{ contact, ...contact }}
                              />
                            ) : (
                              <>
                                {contact.fieldRollup.email ? (
                                  <>
                                    {contact.fieldRollup.email}
                                    <br />
                                  </>
                                ) : null}
                                {contact.fieldRollup.title && (
                                  <>
                                    {contact.fieldRollup.title as string}
                                    <br />
                                  </>
                                )}
                              </>
                            )}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  );
                })}
              </Box>
            </Loading>
          </CollapseBox>
        </List>
      ) : (
        <Loading loading={true} fullscreen={false} />
      )}
    </>
  );
};
