import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";
import { Stack } from "@mui/material";
import type { FC } from "react";
import React from "react";
import type { ChatHistory } from "allgood-api/src/routes/trpc/llm";
import { getName, useGetAvatar } from "../utils/chat-utils";

type ChatMessageProps = {
  chat: ChatHistory;
};
export const ChatMessage: FC<ChatMessageProps> = ({ chat }) => {
  const isUserMessage = chat.type === "user";
  const avatar = useGetAvatar(chat);

  return (
    <Stack direction={isUserMessage ? "row-reverse" : "row"} spacing={2}>
      {avatar}
      <Card
        variant={"outlined"}
        sx={{
          p: 2,
          pb: 0,
          bgcolor: isUserMessage ? "primary.light" : "", // maybe use this for interactive mode?
          border: chat.type === "user" ? 0 : undefined,
        }}
      >
        <Typography variant={"caption"}>
          <b>{getName(chat)}</b>
        </Typography>
        <Markdown
          className={"markdown"}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[[rehypeExternalLinks, { target: "_blank" }]]}
        >
          {chat.message}
        </Markdown>
      </Card>
    </Stack>
  );
};
