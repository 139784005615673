import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { PreviewOutlined } from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import { JSONEditor } from "@/components/JSONEditor";

interface JsonDrawerProps {
  data: unknown;
  title: string;
  editable?: boolean;
  onChange?: (data: unknown) => void;
  children?: React.ReactNode;
}

const JsonDrawer: React.FC<JsonDrawerProps> = ({
  data,
  title,
  editable = false,
  onChange,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsOpen(open);
    };

  return (
    <>
      <IconButton onClick={toggleDrawer(true)} aria-label="Open JSON viewer">
        <PreviewOutlined />
      </IconButton>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 800, padding: 2 }} role="presentation">
          <h2>{title}</h2>
          <strong>Only visible to developers</strong>
          <JSONEditor readOnly={!editable} data={data} onChange={onChange} />
          {children}
        </Box>
      </Drawer>
    </>
  );
};

export default JsonDrawer;
