import React from "react";
import { Chip, Typography } from "@mui/material";
import { trpc } from "@/utils/trpc";
import type { DeliverableId } from "allgood-schema";
import Stack from "@mui/material/Stack";
import { Check, Error } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

export const AuditDatabaseFetchDeliverableWidget = ({
  deliverableId,
}: {
  deliverableId?: DeliverableId;
}) => {
  const [data] = trpc.deliverable.get.useSuspenseQuery({ id: deliverableId! });
  if (!data) {
    return <div>Task not found</div>;
  }
  if (!data.deliverable) {
    return <div>Deliverable not found</div>;
  }
  const { totalErrors, allFailingChecks, totalChecksProcessed } = data
    .deliverable.data as {
    totalErrors: number;
    allFailingChecks: string[];
    totalChecksProcessed: number;
  };

  const tooltipContent =
    allFailingChecks.length > 0 ? (
      <div>
        Failing checks:
        <br />
        {allFailingChecks.map((check, index) => (
          <React.Fragment key={index}>
            {check}
            <br />
          </React.Fragment>
        ))}
      </div>
    ) : null;
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Tooltip title={tooltipContent} arrow placement="bottom">
        <Chip
          icon={totalErrors === 0 ? <Check /> : <Error />}
          label={`${totalErrors} Errors`}
          color={totalErrors === 0 ? "success" : "error"}
          size="small"
        />
      </Tooltip>
      <Typography variant="body2">
        {totalChecksProcessed} Checks Processed
      </Typography>
    </Stack>
  );
};
