import { Box, Button, Chip, Stack } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getIcon } from "@/components/activity/get-icon";
import { format } from "date-fns";
import { agTeal, agYellow } from "@/styles/theme/colors";
import { Star } from "@phosphor-icons/react";
import type {
  MilestoneCluster,
  MilestoneClusterEntry,
  MilestoneTag,
  MilestoneTouch,
  Opportunity,
} from "allgood-api/src/services/opportunity_engagement.schema";
import type { ActivityFilters } from "src/components/activity/helpers/activity-filters";
import { MILESTONE_COUNT_IN_PAGE } from "./company-page";
import { useMemo } from "react";
import { z } from "zod";
import type { FunctionChainInputType } from "allgood-api/src/services/rap/rap-functions/company-journey";

const SimpleActivityCluster = ({
  opportunityId,
  opportunities,
  cluster,
  selected,
  onClickCluster,
}: {
  opportunityId: string | null;
  opportunities: Opportunity[];
  cluster: MilestoneCluster;
  selected: boolean;
  onClickCluster: (cluster: MilestoneCluster) => void;
}) => {
  // check if first element in cluster is an opportunity change, since they are in a cluster alone
  const isOpportunity = cluster[0].touchType === "OPP";

  if (isOpportunity) {
    const oppChangeMembers = cluster[0].members ?? [];
    const member = oppChangeMembers[0];

    // Make sure we have a member and that it has an oppId
    if (!member || typeof member === "string" || !member.oppId) return;

    // If we have an opportunityId, make sure it matches the member's oppId
    if (opportunityId && member.oppId !== opportunityId) return;

    const opportunityMatched = opportunities.find(
      (opp) => opp.id === member.oppId,
    );

    return (
      <Box
        textAlign={"center"}
        justifySelf={"center"}
        sx={{ minWidth: "485px" }}
      >
        <Typography variant={"caption"}>
          {format(cluster[0].date, "MMM, dd, yyyy")}
        </Typography>
        <TimelineItem
          data-cy={"activity"}
          sx={{
            "&::before": { flex: 0, padding: 0 },
            width: "100%",
          }}
        >
          <TimelineContent alignContent={"center"}></TimelineContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color={"warning"} variant="filled">
              <Star weight={"fill"} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent alignContent={"center"}>
            <Typography variant="caption" component="span">
              <Chip
                label={
                  <Box
                    sx={{
                      whiteSpace: "normal",
                    }}
                  >
                    Opportunity{" "}
                    {opportunityMatched
                      ? `${opportunityMatched.name}: ${member.status}` // TODO: figure out a way to configure this
                      : ""}
                  </Box>
                }
                sx={{ height: "auto" }}
                variant={"filled"}
                color={isOpportunity ? "agOrange" : "secondary"}
              ></Chip>
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Box>
    );
  }

  // If we have a cluster that is not an opportunity change, render the cluster
  return (
    <Box component={"div"}>
      <Box textAlign={"center"}>
        <Typography variant={"caption"}>
          {format(cluster[0].date, "MMM, yyyy")}
        </Typography>
      </Box>
      <Stack direction={"row"} spacing={2} justifyContent={"center"}>
        <Button
          fullWidth
          sx={{ backgroundColor: selected ? "#f5f5f5" : "white", padding: 0 }}
          onClick={() => {
            onClickCluster(cluster);
          }}
        >
          <TimelineItem
            sx={{
              "&::before": { flex: 0, padding: 0 },
              width: "100%",
            }}
          >
            <TimelineContent alignContent={"center"}>
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"right"}
                width={"100%"}
                flexWrap={"wrap"}
              >
                {cluster.map((milestoneCluster) => {
                  return (
                    <MilestoneChip
                      key={milestoneCluster.ids[0]}
                      milestoneClusterEntry={milestoneCluster}
                      type={"MARKETING"}
                    />
                  );
                })}
              </Stack>
            </TimelineContent>

            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color="primary" variant="filled"></TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent alignContent={"center"}>
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"left"}
                width={"100%"}
                flexWrap={"wrap"}
              >
                {cluster.map((milestoneCluster) => {
                  return (
                    <MilestoneChip
                      key={milestoneCluster.ids[0]}
                      milestoneClusterEntry={milestoneCluster}
                      type={"SALES"}
                    />
                  );
                })}
              </Stack>
            </TimelineContent>
          </TimelineItem>
        </Button>
      </Stack>
    </Box>
  );
};

const MilestoneChip = ({
  milestoneClusterEntry,
  type,
}: {
  milestoneClusterEntry: MilestoneClusterEntry;
  type: "MARKETING" | "SALES";
}) => {
  if (!milestoneClusterEntry) return;

  if (milestoneClusterEntry.touchType !== type) return;

  const color =
    milestoneClusterEntry.touchType === "MARKETING" ? agYellow : agTeal;

  return (
    <Chip
      label={
        <Box py={1}>
          {`${milestoneClusterEntry.name}`}
          <br />
          <Typography variant={"caption"} color={"text.secondary"}>
            {milestoneClusterEntry.tags?.length
              ? milestoneClusterEntry.tags.join(", ")
              : "No tags"}
          </Typography>
        </Box>
      }
      key={milestoneClusterEntry.ids[0]}
      variant={true ? "filled" : "soft"}
      sx={{
        height: "auto",
        px: 1,
        bgcolor: true ? color[200] : "secondary",
        color: true ? color[900] : "secondary",
      }}
      icon={getIcon(milestoneClusterEntry.icon, 1).icon}
    />
  );
};

export const TouchTimeline = ({
  opportunityId,
  opportunities,
  milestoneData,
  onClickCluster,
  functionChainInput,
  setFilters,
  // milestoneFilters,
}: {
  opportunityId?: string | null;
  opportunities: Opportunity[];
  milestoneData: MilestoneTouch[];
  onClickCluster: (cluster: MilestoneCluster) => void;
  functionChainInput: FunctionChainInputType;
  setFilters: (filter: (prev: ActivityFilters) => ActivityFilters) => void;
  // milestoneFilters: UIFilters;
}) => {
  const itemsToShow = functionChainInput.params.offset
    ? MILESTONE_COUNT_IN_PAGE + functionChainInput.params.offset
    : MILESTONE_COUNT_IN_PAGE;

  const handleFilterChange = () => {
    if (functionChainInput.params.offset) {
      setFilters((filter) => ({
        ...filter,
        offset: itemsToShow + MILESTONE_COUNT_IN_PAGE,
      }));
    } else {
      setFilters((filter) => ({ ...filter, offset: MILESTONE_COUNT_IN_PAGE }));
    }
  };

  const clusters = useMemo(() => {
    const milestones = milestoneData || [];
    const clusters: MilestoneCluster[] = [];

    // For each milestone
    milestones.forEach((milestone) => {
      const milestoneDate = new Date(milestone.endAt);

      if (
        // If the milestone members has an oppId, then it's an opportunity change TODO: Move this to a config
        milestone.touchType === "OPP"
      ) {
        clusters.push([
          {
            ident: "OppChange",
            name: "Opportunity Change",
            touchType: "OPP",
            icon: "opportunity",
            date: milestoneDate,
            count: 1,
            tags: simplifyMilestoneTags(milestone.tags),
            ids: [milestone.id],
            members: milestone.members,
          },
        ]);
        return;
      }

      // Find the cluster that this milestone belongs to which is not an opportunity change
      const cluster = clusters.find(
        (cluster) =>
          cluster[0].ident !== "OppChange" &&
          new Date(cluster[0].date).getFullYear() ===
            milestoneDate.getFullYear() &&
          new Date(cluster[0].date).getMonth() === milestoneDate.getMonth(),
      );

      // If the cluster exists
      if (cluster) {
        // Find the milestone in the cluster
        const milestoneInCluster = cluster.find(
          (milestoneInCluster) => milestoneInCluster.ident === milestone.ident,
        );

        // If the milestone is in the cluster add its id to the cluster
        if (milestoneInCluster) {
          if (milestoneInCluster.ids.includes(milestone.id)) {
            return;
          }

          milestoneInCluster.count += 1;
          milestoneInCluster.tags = [
            ...new Set([
              ...(milestoneInCluster.tags as string[]),
              ...simplifyMilestoneTags(milestone.tags),
            ]),
          ];
          milestoneInCluster.ids = [
            ...new Set([...(milestoneInCluster.ids as string[]), milestone.id]),
          ];
          if (milestone.members) {
            if (milestoneInCluster.members) {
              milestoneInCluster.members = [
                ...new Set([
                  ...milestoneInCluster.members,
                  ...milestone.members,
                ]),
              ];
            } else {
              milestoneInCluster.members = milestone.members;
            }
          }
          milestoneInCluster.date = new Date(
            Math.max(
              new Date(milestoneInCluster.date).getTime(),
              milestoneDate.getTime(),
            ),
          );
          return;
        } else {
          // If the milestone is not in the cluster, add it to the cluster
          cluster.push({
            ident: milestone.ident,
            name: milestone.name,
            touchType: milestone.touchType,
            icon: milestone.icon,
            date: milestoneDate,
            count: 1,
            tags: simplifyMilestoneTags(milestone.tags),
            ids: [milestone.id],
          });
          return;
        }
      } else {
        // If the cluster does not exist, create a new cluster
        clusters.push([
          {
            ident: milestone.ident,
            name: milestone.name,
            touchType: milestone.touchType,
            icon: milestone.icon,
            date: milestoneDate,
            count: 1,
            tags: simplifyMilestoneTags(milestone.tags),
            ids: [milestone.id],
          },
        ]);
      }
    });

    return clusters.sort(
      (a, b) => new Date(b[0].date).getTime() - new Date(a[0].date).getTime(),
    );
  }, [milestoneData]);

  // Check if there are more items to show, i.e. if the total count of milestones is greater than or equal than the itemsToShow
  const moreToShow =
    clusters
      .flatMap((cluster) => cluster.map((c) => c.count))
      .reduce((a, b) => a + b, 0) >= itemsToShow;

  return (
    <Box>
      <Timeline>
        {clusters.slice(0, itemsToShow).map((cluster, i) => (
          <Stack direction={"row"} key={cluster[0].ids[0]}>
            <Box flex={1}>
              <SimpleActivityCluster
                opportunityId={opportunityId ?? null}
                opportunities={opportunities}
                key={i}
                cluster={cluster}
                selected={false}
                onClickCluster={onClickCluster}
              />
            </Box>
          </Stack>
        ))}
        {moreToShow && (
          <Stack
            direction={"row"}
            spacing={1}
            paddingLeft={5}
            justifyContent={"center"}
          >
            <Button
              variant={"text"}
              size={"small"}
              onClick={() => {
                handleFilterChange();
              }}
            >
              <ExpandMoreIcon sx={{ color: "#666" }} />
              <Typography
                variant={"caption"}
                color={"grey"}
                sx={{ alignSelf: "center" }}
              >
                <u>Expand more events.</u>
              </Typography>
            </Button>
          </Stack>
        )}
      </Timeline>
    </Box>
  );
};

// TODO: replace this
function simplifyMilestoneTags(tags: MilestoneTag[] | undefined) {
  if (!tags) {
    return [];
  }
  return tags.map(getTagName).filter((tag) => tag !== undefined) as string[];
}

function getTagName(tag: MilestoneTag): string | undefined {
  if (z.string().safeParse(tag).success) {
    return tag as string;
  }
  if (
    z.object({ medium: z.string(), source: z.string() }).safeParse(tag).success
  ) {
    const t = tag as { medium: string; source: string };
    return `${t.source}`;
  }
}
