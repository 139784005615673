import { type UserId } from "allgood-schema";
import { Avatar, Tooltip } from "@mui/material";
import { UserById } from "@/utils/by-id";

import { Invoker } from "allgood-api/src/context";
import { AgentAvatar } from "@/pages/task/components/agent-avatar";
export const UserAvatar = ({
  userId,
  tooltip = true,
  size,
}: {
  userId: UserId;
  tooltip?: boolean;
  size?: number;
}) => {
  return (
    <UserById userId={userId}>
      {(user) => (
        <Tooltip
          title={user ? `${user.firstName} ${user.lastName}` : "User"}
          disableHoverListener={!tooltip}
        >
          <Avatar
            // TODO we don't store user icons yet
            // src={user ? `/${user.config.icon}` : undefined}
            sx={size ? { width: size, height: size } : undefined}
          >
            {user?.firstName?.[0] + user?.lastName?.[0]}
          </Avatar>
        </Tooltip>
      )}
    </UserById>
  );
};

export const InvokerAvatar = ({
  invoker,
  tooltip = true,
  size,
}: {
  invoker: Invoker;
  tooltip?: boolean;
  size?: number;
}) => {
  const inv = Invoker.safeParse(invoker);
  if (!inv.success) {
    return (
      <Tooltip title={invoker._type}>
        <Avatar sx={size ? { width: size, height: size } : undefined}>
          {invoker._type[0]}
        </Avatar>
      </Tooltip>
    );
  }
  if (invoker._type === "seed") {
    return (
      <Tooltip title={"allGood"} disableHoverListener={!tooltip}>
        <Avatar sx={size ? { width: size, height: size } : undefined}>
          aG
        </Avatar>
      </Tooltip>
    );
  }
  if (invoker._type === "agent") {
    return <AgentAvatar agentId={invoker.agentId} size={36} />;
  }
  if (invoker._type === "user") {
    return <UserAvatar userId={invoker.userId} tooltip={tooltip} size={size} />;
  }

  if (invoker._type === "workflow") {
    const icon = (
      <img
        src="/assets/allgood-logo-icon.svg"
        color={"action"}
        height={size ?? 36}
      />
    );

    return (
      <Tooltip
        title={`allGood Workflow ID: ${invoker.workflowRunId} - ${invoker.workflowType}`}
        disableHoverListener={!tooltip}
      >
        <Avatar sx={size ? { width: size, height: size } : undefined}>
          {icon}
        </Avatar>
      </Tooltip>
    );
  }
};
