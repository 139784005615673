import { lazy, Suspense } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";

import { Layout as DashboardLayout } from "../layouts/dashboard/layout/layout";

import { authRoutes } from "./auth";

import { profileRoutes } from "src/routes/profile";
import { missionRoutes } from "src/routes/mission";
import { adminRoutes } from "src/routes/admin";
import { settingRoutes } from "src/routes/settings";
import { companyRoutes } from "src/routes/company";
import { integrationOAuthRoutes } from "src/routes/integration-oauth";
import { guideRoutes } from "@/routes/guide";
import Loading from "@/components/loading";

const Error401Page = lazy(() => import("../pages/401"));
const Error404Page = lazy(() => import("../pages/404"));
const Error500Page = lazy(() => import("../pages/500"));

const DashboardPage = lazy(() => import("src/pages/dashboard"));
const AllGoodPage = lazy(() => import("src/pages/configure-system/allgood"));
const _AgentsPage = lazy(() => import("@/pages/admin/agents/agents-page"));

// Insights
export const routes: RouteObject[] = [
  {
    element: (
      <DashboardLayout>
        <Suspense fallback={<Loading loading={true} />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "/",
        element: <DashboardPage />,
      },
      {
        path: "/allgood",
        element: <AllGoodPage />,
      },

      ...profileRoutes,
      ...companyRoutes,
      ...missionRoutes,
      ...guideRoutes,
      ...adminRoutes,
      ...settingRoutes,
    ],
  },
  ...integrationOAuthRoutes,

  ...authRoutes,
  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "500",
    element: <Error500Page error={undefined} />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
];
