import type { FC, ReactElement } from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ChevronDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Collapse } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";

export interface Signal {
  name: string;
  trend: number;
  value: number;
  checked?: boolean;
}

export interface SignalCardProps {
  title: ReactElement | string;
  isExpanded?: boolean;
  collapsible?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sx?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  titleStyle?: any;
  collapseIconPosition?: "left" | "right";
  onClick?: () => void;
  children: ((expanded: boolean) => ReactElement) | ReactElement;
}

export const CollapseCard: FC<SignalCardProps> = ({
  title,
  isExpanded = false,
  collapsible = true,
  sx,
  titleStyle,
  collapseIconPosition = "right",
  onClick,
  children,
}) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  const childElement: ((expanded: boolean) => ReactElement) | ReactElement =
    typeof children === "function" ? children(expanded) : children;

  return (
    <Card sx={sx}>
      <CardHeader
        title={
          <Stack direction={"row"} sx={{ alignItems: "center" }}>
            {collapseIconPosition === "left" ? (
              expanded ? (
                <ChevronDownIcon />
              ) : (
                <ChevronRightIcon />
              )
            ) : null}
            <Box flex={1}>{title}</Box>
            {collapseIconPosition === "right" ? (
              expanded ? (
                <ChevronDownIcon />
              ) : (
                <ChevronRightIcon />
              )
            ) : null}
          </Stack>
        }
        sx={{
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "action.hover",
          },
          paddingY: 0,
          ...titleStyle,
        }}
        onClick={() => {
          setExpanded(!expanded);
          if (onClick) {
            onClick();
          }
        }}
      />
      {collapsible ? (
        <Collapse in={expanded}>
          <>{childElement}</>
        </Collapse>
      ) : (
        <>{childElement}</>
      )}
    </Card>
  );
};
