import React, { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";

const GuideListPage = lazy(() => import("src/pages/guides/guide-list-page"));
const GuideConfigurePage = lazy(
  () => import("@/pages/admin/agents/agent-details-page")
);

export const guideRoutes: RouteObject[] = [
  {
    path: paths.guides.index,
    element: <GuideListPage />,
  },
  {
    path: paths.guides.configure,
    element: <GuideConfigurePage />,
  },
];
