import type { FC, ReactNode } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { AuthContext } from "./auth-context";
import { Issuer } from "src/utils/auth";
import type { RouterInputs, RouterOutputs } from "src/utils/trpc";
import { trpc } from "src/utils/trpc";

const STORAGE_TOKEN_KEY = "accessToken";
const STORAGE_TENANT_KEY = "tenantId";

interface AuthProviderProps {
  children: ReactNode;
}

// App.tsx is loading this for now.
const accessToken = window.localStorage.getItem(STORAGE_TOKEN_KEY);
// const tenantId = window.localStorage.getItem(STORAGE_TENANT_KEY);

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const [user, setUser] = useState<RouterOutputs["auth"]["me"]>();
  const [token, setToken] = useState<string>(accessToken ?? "");

  const signOut = useCallback(async (): Promise<void> => {
    // TODO: fix this after we solve App.tsx state
    localStorage.removeItem(STORAGE_TOKEN_KEY);
    localStorage.removeItem(STORAGE_TENANT_KEY);
    setUser(undefined);
    router.push(paths.auth.login);
  }, [router]);

  const { mutateAsync: getSignIn } = trpc.auth.signin.useMutation();

  const signIn = useCallback(
    async (
      loginType: RouterInputs["auth"]["signin"]["loginType"],
      email?: string,
    ): Promise<void | Error> => {
      try {
        const { redirectUrl } = await getSignIn({
          loginType: loginType,
          email,
        });

        window.location.replace(redirectUrl);
      } catch (e: unknown) {
        throw e;
        // return e as Error;
      }
    },
    [getSignIn],
  );

  const {
    data: me,
    error: meError,
    isInitialLoading,
  } = trpc.auth.me.useQuery(undefined, {
    enabled: token.length > 0,
  });
  useEffect(() => {
    if (me) {
      setUser(me);
      window.localStorage.setItem(STORAGE_TENANT_KEY, me.tenantId ?? "");
    }
  }, [me]);

  const { mutateAsync: getSignInCallback } = trpc.auth.callback.useMutation();
  const signInCallback = useCallback(
    async (code: string) => {
      const tokenAndProfile = await getSignInCallback({ code });
      console.log("tokenAndProfile", tokenAndProfile);

      if (tokenAndProfile && tokenAndProfile.accessToken) {
        setToken(tokenAndProfile.accessToken);
        window.localStorage.setItem(
          STORAGE_TOKEN_KEY,
          tokenAndProfile.accessToken,
        );
      }

      return tokenAndProfile;
    },
    [getSignInCallback],
  );

  const switchTenant = useCallback(
    async (accessToken: string) => {
      setToken(accessToken);
      window.localStorage.setItem(STORAGE_TOKEN_KEY, accessToken);
      window.location.href = "/";
    },
    [router],
  );

  const value = useMemo(
    () => ({
      isInitialized: !token || !isInitialLoading,
      isAuthenticated: Boolean(user),
      user: user,
      token,
      issuer: Issuer.JWT,
      signIn,
      signInCallback,
      signOut,
      switchTenant,
    }),
    [meError, user, signIn, signInCallback, signOut],
  );

  // useEffect(() => {
  //   console.log("AuthProvider useEffect user >> ", value);
  //   if (meError) {
  //     signOut();
  //   }
  // }, [user]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
