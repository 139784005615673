import { useState, useEffect } from "react";
import type { WorkflowExecution } from "allgood-api/src/repos/workflow_execution.schema";
import type { WorkflowCheckpoint } from "allgood-api/src/repos/workflow_checkpoint.schema";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { trpc } from "src/utils/trpc";
import {
  Stack,
  Typography,
  LinearProgress,
  Button,
  Card,
  CardActions,
  CardContent,
  Tooltip,
} from "@mui/material";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import { formatDistanceToNow } from "date-fns";
import type { WorkflowRunId } from "allgood-schema";

/** To make sure timestamps shown as 'X ago' update when necessary, wrap in `TimeAgo`. */
export function TimeAgo({
  intervalMs = 20000, // Every 20s.
  date,
}: {
  intervalMs?: number;
  date: Date;
}) {
  const [text, setText] = useState(formatDistanceToNow(date));

  useEffect(() => {
    const interval = setInterval(() => {
      setText(formatDistanceToNow(date));
    }, intervalMs);
    return () => clearInterval(interval);
  }, [intervalMs, date]);

  return (
    <Tooltip
      placement="bottom"
      arrow
      title={date.toISOString()}
      enterDelay={700}
    >
      <span>{text} ago</span>
    </Tooltip>
  );
}

export function CurrentWorkflowCard({
  workflow,
  onStart,
  started,
  running,
}: {
  workflow: WorkflowExecution | null;
  onStart: () => void;
  started: boolean;
  running: boolean;
}) {
  return (
    <Card
      variant="outlined"
      sx={{ minHeight: 220, display: "flex", flexDirection: "column" }}
    >
      <CardContent sx={{ pb: 2, marginBottom: "auto" }}>
        <Stack spacing={2}>
          <Stack spacing={1}>
            {workflow?.createdAt && (
              <Typography variant="body2" color="textSecondary">
                Last started <TimeAgo date={workflow.createdAt} />.
              </Typography>
            )}
            {workflow?.completedAt && (
              <Typography variant="body2" color="textSecondary">
                Completed <TimeAgo date={workflow.completedAt} />.
              </Typography>
            )}
          </Stack>
        </Stack>
      </CardContent>
      {running ? (
        <>
          <CardContent sx={{ py: 0, my: 0 }}>
            <Typography variant="overline" color="textSecondary">
              IN PROGRESS
            </Typography>
          </CardContent>
          <LinearProgress />
        </>
      ) : (
        <CardActions>
          <Button
            size="small"
            variant="contained"
            startIcon={<PlayCircleOutlineIcon />}
            disabled={running || started}
            onClick={onStart}
          >
            Run Service
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export function CheckpointTimelineItem({
  checkpoint,
}: {
  checkpoint: WorkflowCheckpoint;
}) {
  const message = checkpoint?.contents?.message ?? "Checkpoint";

  return (
    <TimelineItem>
      <TimelineOppositeContent />
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="body1">{message}</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          <TimeAgo date={checkpoint.createdAt} />
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

export function WorkflowExecutionTimelineItem({
  workflowRunId,
}: {
  workflowRunId: WorkflowRunId;
}) {
  const [workflow] = trpc.workflows.get.useSuspenseQuery({ workflowRunId });

  const color = "agTeal.700";

  return (
    <TimelineItem>
      <TimelineOppositeContent />
      <TimelineSeparator>
        <TimelineDot sx={{ backgroundColor: color }} />
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle1" component="div" color={color}>
          Started Service Task
        </Typography>
        <Typography variant="subtitle2" color={color}>
          <TimeAgo date={workflow.createdAt} />
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}
