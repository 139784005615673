import { FC, PropsWithChildren, Suspense } from "react";
import Loading from "@/components/loading";

export const SuspenseLoading: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Suspense fallback={<Loading loading={true} fullscreen={false} />}>
      {children}
    </Suspense>
  );
};
