import {
  AgentId,
  TaskId,
  UserId,
  MilestoneId,
  AGCompanyId,
  DeliverableId,
} from "allgood-schema";
import { FC, ReactNode } from "react";
import { RouterOutputs, trpc } from "@/utils/trpc";
import Loading from "@/components/loading";
import { Skeleton } from "@mui/material";

export const AgentById: FC<{
  agentId: AgentId;
  size?: number;
  stacked?: number;
  children: (agent: RouterOutputs["agent"]["getAgentById"]) => ReactNode;
}> = ({ agentId, size, stacked, children }) => {
  const { data, isLoading } = trpc.agent.getAgentById.useQuery({
    agentId: agentId,
  });

  return isLoading ? (
    <Skeleton
      variant="circular"
      width={size || 24}
      height={size || 24}
      sx={{
        transition: "all 0.2s",
        outline: stacked ? "3px solid white" : undefined,
        outlineOffset: stacked ? "-1px" : undefined,
        marginX: stacked ?? 0,
      }}
    />
  ) : (
    data && children(data)
  );
};

export const UserById: FC<{
  userId: UserId;
  children: (agent: RouterOutputs["users"]["get"]) => ReactNode;
}> = ({ userId, children }) => {
  const { data, isLoading } = trpc.users.get.useQuery({
    id: userId,
  });

  return (
    <Loading loading={isLoading} fullscreen={false}>
      {data && children(data!)}
    </Loading>
  );
};

export const CompanyById: FC<{
  companyId: AGCompanyId;
  children: (company: RouterOutputs["company"]["find"]) => ReactNode;
}> = ({ companyId, children }) => {
  const { data, isLoading } = trpc.company.find.useQuery({
    filter: { id: companyId },
  });

  return (
    <Loading loading={isLoading} fullscreen={false}>
      {data && children(data)}
    </Loading>
  );
};

export const MilestoneById: FC<{
  milestoneId: MilestoneId;
  children: (milestone: RouterOutputs["milestone"]["get"]) => ReactNode;
}> = ({ milestoneId, children }) => {
  const [data] = trpc.milestone.get.useSuspenseQuery({
    id: milestoneId,
  });

  return <>{children(data)}</>;
};

export const TaskById: FC<{
  taskId: TaskId;
  children: (task?: RouterOutputs["task"]["get"]) => ReactNode;
}> = ({ taskId, children }) => {
  const { data, isLoading } = trpc.task.get.useQuery({ id: taskId });

  return (
    <Loading loading={isLoading} fullscreen={false}>
      {children(data)}
    </Loading>
  );
};

export const DeliverableById: FC<{
  deliverableId: DeliverableId;
  children: (
    deliverable: RouterOutputs["deliverable"]["get"]["deliverable"],
  ) => ReactNode;
}> = ({ deliverableId, children }) => {
  const { data, isLoading } = trpc.deliverable.get.useQuery({
    id: deliverableId,
  });

  return (
    <Loading loading={isLoading} fullscreen={false}>
      {data?.deliverable && children(data.deliverable!)}
    </Loading>
  );
};
