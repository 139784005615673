import { Alert, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import type { PropsWithChildren } from "react";
import React, { type FunctionComponent } from "react";
import { JSONEditor } from "src/components/JSONEditor";
import { CollapseCard } from "src/components/collapse-card";
import { useAuth } from "src/hooks/use-auth";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { checkIsSuperUser } from "@/utils/auth";
import JsonDrawer from "@/components/json-drawer";

export type BaseConfigModel = {
  name?: string;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any;
};
type BaseConfigCardProps = {
  data: BaseConfigModel;
  setData: (payload: BaseConfigModel) => void;
  submitButtonLabel?: string;
  onSubmit: (payload: BaseConfigModel) => void;
  isSubmitting?: boolean;
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitResult?: any;
  isDisabled?: boolean; // Set to true for Read Only.
  readonly?: string[];
};
export const BaseConfigCard: FunctionComponent<
  PropsWithChildren<BaseConfigCardProps>
> = ({
  data,
  setData,
  submitButtonLabel = "Submit",
  onSubmit,
  isSubmitting,
  submitResult,
  isDisabled = false,
  error,
  readonly = [],
  children,
}) => {
  const auth = useAuth();
  const user = auth.user;
  const isAllGood = checkIsSuperUser(user);

  const handleSubmit = async () => {
    onSubmit(data);
  };

  return (
    <Card>
      <CardContent>
        {submitResult && (
          <Alert severity="success" sx={{ mt: 1 }}>
            Updated Successfully
            <JsonDrawer title="details" data={submitResult} />
          </Alert>
        )}
        {error && (
          <Alert severity="error" variant={"filled"} sx={{ mt: 1 }}>
            Error: {error}
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            margin: "1rem 0rem",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                width: "100%",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={data.name}
                onChange={(e) =>
                  setData({
                    ...data,
                    name: e.target.value,
                  })
                }
                sx={{ width: "100%" }}
                required
                disabled={isDisabled || readonly.includes("name")}
              />
              <TextField
                id="outlined-basic"
                label="Description"
                variant="outlined"
                value={data?.description}
                onChange={(e) =>
                  setData({
                    ...data,
                    description: e.target.value,
                  })
                }
                sx={{ width: "100%" }}
                required
                disabled={isDisabled || readonly.includes("description")}
              />
            </Box>
          </Box>
        </Box>
        {children}

        {isAllGood ? (
          <CollapseCard
            title={"Raw Config (Admin)"}
            titleStyle={{ padding: 2 }}
            isExpanded={false}
            sx={{ mt: 2 }}
          >
            <JSONEditor
              readOnly={isDisabled}
              data={data.config}
              onChange={(data: BaseConfigModel["config"]) => {
                setData({
                  ...data,
                  config: data,
                });
              }}
            />
          </CollapseCard>
        ) : null}

        <Box mt={4} textAlign={"right"}>
          {!isDisabled && (
            <LoadingButton
              variant="contained"
              color="primary"
              size={"large"}
              loading={isSubmitting}
              onClick={() => {
                handleSubmit();
              }}
            >
              {submitButtonLabel}
            </LoadingButton>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
