import { Box, Card, Chip, LinearProgress, Stack } from "@mui/material";
import type { RouterOutputs } from "@/utils/trpc";
import { trpc } from "@/utils/trpc";
import type { DeliverableWidgetContext } from "@/pages/task/components/widgets/deliverable-widgets";
import { deliverableWidgets } from "@/pages/task/components/widgets/deliverable-widgets";
import type { DeliverableStatus, MissionId, TaskId } from "allgood-schema";
import type { FC } from "react";
import React from "react";
import CardContent from "@mui/material/CardContent";
import { useAuth } from "@/hooks/use-auth";
import JsonDrawer from "@/components/json-drawer";
import { checkIsSuperUser } from "@/utils/auth";
import CardHeader from "@mui/material/CardHeader";
import { InvokerAvatar } from "@/pages/task/components/avatar";
import { formatDistanceToNow } from "date-fns";

export const instanceStatusColorMap: Record<DeliverableStatus, string> = {
  TODO: "agPink",
  DONE: "agTeal",
  IN_PROGRESS: "agYellow",
  IN_REVIEW: "agOrange",
  IN_ERROR: "agRed",
};

/**
 * Deliverable component
 *
 * Shows just a single deliverable
 * @param missionId
 * @param deliverable
 * @constructor
 */
export const Deliverable: FC<{
  missionId: MissionId;
  deliverable: RouterOutputs["deliverable"]["get"];
  submitDeliverable?: (
    data: Record<string, unknown> | undefined,
  ) => Promise<string>;
}> = ({ missionId, deliverable, submitDeliverable }) => {
  const context: DeliverableWidgetContext = {
    // TODO: Fix this, we likely only have taskId and missionId as common.
    // businessObjectiveId: businessObjective?.id ?? ("" as BusinessObjectiveId),
    missionId: missionId,
    submitDeliverable,
  };
  const auth = useAuth();
  const user = auth.user;
  const isAllGood = checkIsSuperUser(user);

  console.log("Showing deliverables", deliverable);
  const Widget = deliverableWidgets[deliverable.deliverable?.widget?.id ?? ""]!;

  return (
    <Card key={deliverable.id} elevation={0}>
      {deliverable.status === "IN_PROGRESS" && (
        <LinearProgress color={"agYellow"} />
      )}
      <CardHeader
        avatar={<InvokerAvatar invoker={deliverable.createdBy} />}
        action={
          <>
            {isAllGood && deliverable.deliverable && (
              <JsonDrawer title="deliverable" data={deliverable.deliverable} />
            )}
            <Chip
              size="small"
              // @ts-ignore
              color={instanceStatusColorMap[deliverable.status] ?? "secondary"}
              label={deliverable.status}
            />
          </>
        }
        title={deliverable.deliverable?.summary}
        subheader={
          <span>{formatDistanceToNow(deliverable.createdAt)} ago</span>
        }
      />
      <CardContent>
        <Box sx={{ p: 2 }}>
          {Widget && (
            <Widget
              {...context}
              taskId={deliverable.taskId}
              deliverableId={deliverable.id}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
/**
 * TODO: WE NEED TO FIX THIS TO BE MAKING M ORE SENSE... the ACTIVITY STREAM MIGHT BE A BETTER UI THAN THIS
 * @param missionId
 * @param instanceId
 * @constructor
 */
const Deliverables: FC<{
  missionId: MissionId;
  taskId?: TaskId;
}> = ({ missionId, taskId }) => {
  // TODO: Make this use the infinite query pattern with a refetch. Check out workflows.tsx for an example.
  // When taskId did not provided, show all tasks.
  const { data: deliverables } = trpc.deliverable.list.useQuery(
    {
      filter: {
        missionId: missionId,
        taskId: taskId,
      },
      limit: 100,
    },
    {
      enabled: !Boolean(taskId),
      refetchInterval: 4000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
    },
  );
  console.log("deliverables", deliverables);
  return (
    <Stack spacing={1} direction={"column"} sx={{ m: 2 }}>
      {deliverables?.records.map((deliverable) => (
        <Deliverable missionId={missionId} deliverable={deliverable} />
      ))}
    </Stack>
  );
};
export default Deliverables;
