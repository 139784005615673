import type { ScrollOption } from "react-scroll-to-bottom";
import ScrollToBottom, { useScrollToBottom } from "react-scroll-to-bottom";
import type { ReactNode } from "react";
import { type FC, useState } from "react";

type childrenWithScrollToBottom = {
  children: (scrollToBottom: (option?: ScrollOption) => void) => ReactNode;
};
const Content: FC<childrenWithScrollToBottom> = ({ children }) => {
  const scrollToBottom = useScrollToBottom();

  return children(scrollToBottom);
};

export const StickyScroll: FC<childrenWithScrollToBottom> = ({ children }) => {
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const [isSticky, setIsSticky] = useState(true);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  return (
    <ScrollToBottom
      className="scroll-box-style"
      // This is a magic number that works well. if it goes lower, it tends to lose its sticky.
      debounce={isSticky ? 700 : 17}
      initialScrollBehavior={"auto"}
      scroller={({ maxValue, scrollTop }) => {
        // This is a trick to handle it lose sticky randomly and does not call isSticky(false).
        // We trigger delayed setIsSticky(false) and when the random lose sticky happens, it will be caught.
        // In sticky condition, it keeps resetting the timeout and never runs the setIsSticky(false).
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        setTimeoutId(
          setTimeout(() => {
            setIsSticky(false);
          }, 1000),
        );
        if (scrollTop < prevScrollTop) {
          setIsSticky(false);
          // When user scrolls up, stop sticky scroll
          return 0;
        }
        if (!isSticky) {
          setIsSticky(true);
        }
        setPrevScrollTop(scrollTop);
        return maxValue;
      }}
    >
      <Content>{(scrollToBottom) => children(scrollToBottom)}</Content>
    </ScrollToBottom>
  );
};
