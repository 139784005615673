import type { FC } from "react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Avatar, Stack, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import { useStream } from "@/pages/task/hooks/useStream";
import { v4 as uuidv4 } from "uuid";
import { llmWidgets } from "@/pages/task/components/widgets/llm-widgets";
import type { StreamEvent } from "@langchain/core/dist/tracers/log_stream";
import type { LLMData } from "@/pages/task/chat-task-page";
import Button from "@mui/material/Button";
import { useAuth } from "@/hooks/use-auth";
import { Stop } from "@phosphor-icons/react/dist/csr/Stop";
import { sendEventToAmplitude } from "src/utils/amplitude";
import { StickyScroll } from "@/pages/task/components/sticky-scroll";
import type { TeamConfigId } from "allgood-schema";
import { trpc } from "@/utils/trpc";
import Loading from "@/components/loading";
import { camelCase } from "change-case";
import { SuggestionButton } from "@/pages/task/components/suggestionButton";
import type { ScrollOption } from "react-scroll-to-bottom";
import { ChatMessage } from "@/pages/task/components/chat-message";
import { CollapseCard } from "src/components/collapse-card";
import { getName, useGetAvatar } from "@/pages/task/utils/chat-utils";
import { ChatCircleDots } from "@phosphor-icons/react/dist/csr/ChatCircleDots";
import { CollapseBox } from "@/components/collapse-box";
import { renderSystemMessage } from "@/pages/task/utils/render-system-message";
import { SuspenseLoading } from "@/components/suspense-loading";
import { LoaderIcon } from "react-hot-toast";

type ChatProps = {
  teamConfigId: TeamConfigId;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: Record<string, any>;
  llmData?: LLMData;
  widgets?: typeof llmWidgets;
  onToolEnd?: (event: StreamEvent) => void;
  onSubmit: (llmData: LLMData) => void;
  sendMessage?: (sendFn: (message: string) => void) => void;
  interactiveMode: boolean; // MUST be a boolean on what mode we are running in.
  streamHandlers?: ({
    threadId,
    chatHistory,
    isStreaming,
    startStream,
    stopStream,
  }: ReturnType<typeof useStream>) => void;
};
export const Chat: FC<ChatProps> = ({
  teamConfigId,
  context = {},
  widgets = llmWidgets,
  llmData = {
    taskId: undefined,
    threadId: undefined,
    deliverableId: undefined,
    chatHistory: [],
  },
  onToolEnd,
  onSubmit,
  sendMessage,
  interactiveMode,
  streamHandlers,
}) => {
  const auth = useAuth();
  const picture = auth.user?.profile?.rawAttributes?.picture;

  const { data: teamConfig, isLoading: isLoadingTask } =
    trpc.agentWorkflow.get.useQuery({
      id: teamConfigId,
    });

  const { data: tools, isLoading: isLoadingTools } =
    trpc.agent.getListTools.useQuery();

  const {
    threadId,
    chatHistory: chatHistoryGroup,
    isStreaming,
    startStream,
    stopStream,
  } = useStream({
    initialChatHistory: llmData.chatHistory,
    taskId: llmData.taskId!,
    deliverableId: llmData.deliverableId!,
    threadId: llmData.threadId ?? uuidv4(),
    context,
    onToolEnd,
    onSubmit,
    interactiveMode,
  });

  // Return the stream handlers to control the stream outside the compoennt.
  useEffect(
    () => {
      if (streamHandlers) {
        streamHandlers({
          threadId,
          chatHistory: chatHistoryGroup,
          isStreaming,
          startStream,
          stopStream,
        });
      }
    },
    // adding dependency here goes into infinite loop. just register it once.
    [],
  );

  const [promptText, setPromptText] = useState<string>("");

  const sendFn = useCallback(
    (message: string) => {
      setPromptText(message);
      console.log("SET PROMPT TEXT", message);
      startStream(message);
    },
    [startStream],
  );

  useEffect(() => {
    if (sendMessage) {
      sendMessage?.(sendFn);
    }
  }, [sendFn, sendMessage]);

  const renderWidget = (event: StreamEvent) => {
    if (event.event === "on_tool_end") {
      return widgets.on_tool_end[event.name]?.(event);
    }
  };

  const renderSuggestions = (
    event: StreamEvent,
    scrollToBottom: (option?: ScrollOption) => void,
  ) => {
    const toolName = event.name;

    const suggestions = tools?.find((tool) => tool.name === camelCase(toolName))
      ?.metadata.suggestions;
    if (
      event.event !== "on_tool_end" ||
      !suggestions ||
      suggestions.length === 0
    ) {
      return null;
    }

    return (
      <Box px={3} mb={3}>
        Suggestions
        <Stack spacing={2} mt={1} direction={"row"}>
          {suggestions.map((suggestion, i) => (
            <SuggestionButton
              key={i}
              suggestion={suggestion}
              data={event.data.output}
              onClick={(compiledSuggestion) => {
                if (compiledSuggestion) {
                  scrollToBottom({ behavior: "auto" });
                  startStream(compiledSuggestion);
                }
              }}
            />
          ))}
        </Stack>
      </Box>
    );
  };

  return (
    <Loading loading={isLoadingTask || isLoadingTools} fullscreen={false}>
      <Box
        sx={{
          p: 0,
          pl: 2,
          pt: 0,
          bgcolor: "background.level1",
          flex: 1,
          height: "100%",
          pb: "65px",
        }}
      >
        <StickyScroll>
          {(scrollToBottom) => (
            <Box pr={2} pb={2} pt={2}>
              {chatHistoryGroup.length === 0 && (
                <Box width={"100%"} textAlign={"center"}>
                  <Box display={"inline-block"} justifyContent={"center"}>
                    <Typography variant={"caption"} color={"text.secondary"}>
                      Start a conversation with the agents
                    </Typography>
                    <Stack mt={1} spacing={2} direction={"row"}>
                      {teamConfig?.config.suggestions?.map(
                        (suggestion, index) => (
                          <Button
                            key={index}
                            variant={"outlined"}
                            color={"primary"}
                            sx={{
                              boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.03)",
                              borderRadius: "30px",
                              color: "#777",
                            }}
                            onClick={() => {
                              startStream(suggestion);
                            }}
                          >
                            {suggestion}
                          </Button>
                        ),
                      )}
                    </Stack>
                  </Box>
                </Box>
              )}
              {chatHistoryGroup.map((group, groupIndex) => {
                return group.type === "user" ? (
                  group.chatHistory.map((chat, index) => {
                    return (
                      <Box
                        key={index + "-chat-top"}
                        textAlign={"right"}
                        pl={2}
                        minWidth={"400px"}
                      >
                        <Box
                          key={index + "-chat"}
                          textAlign={"left"}
                          sx={{ padding: 0, marginBottom: 2 }}
                          // display={"inline-block"}
                        >
                          <ChatMessage chat={chat} />
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <>
                    <CollapseCard
                      title={
                        <Stack
                          direction={"row"}
                          spacing={2}
                          pt={2}
                          alignItems={"center"}
                        >
                          {group.chatHistory.map((chat, index) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            const avatar = useGetAvatar(chat);

                            return (
                              <Box key={index + "-chat-top"} textAlign={"left"}>
                                <Box
                                  key={index + "-chat"}
                                  textAlign={"left"}
                                  sx={{ padding: 0, pl: 1, marginBottom: 2 }}
                                >
                                  <Box width={"min-content"}>
                                    <Stack
                                      direction={"row"}
                                      alignItems={"top"}
                                      spacing={1}
                                    >
                                      {avatar}{" "}
                                      <ChatCircleDots
                                        size={28}
                                        color={"#333"}
                                        weight={"thin"}
                                      />
                                    </Stack>
                                    <Typography
                                      variant={"caption"}
                                      lineHeight={1.4}
                                      sx={{ display: "inline-block" }}
                                      mt={0.5}
                                    >
                                      <b>{getName(chat)}</b>
                                    </Typography>
                                  </Box>

                                  {/*{chat.message &&*/}
                                  {/*chat.agentId === teamConfig?.config.teamLead ? (*/}
                                  {/*  <ChatMessage chat={chat} />*/}
                                  {/*) : null}*/}
                                </Box>
                              </Box>
                            );
                          })}
                        </Stack>
                      }
                      sx={{ mb: 2, mx: 2 }}
                      collapseIconPosition={"left"}
                    >
                      <Stack p={2} spacing={2}>
                        {group.chatHistory.map((chat, index) => {
                          return (
                            <Fragment key={index}>
                              {chat.systemHistory?.length ? (
                                <CollapseBox
                                  title={
                                    <>
                                      <Typography variant={"caption"}>
                                        <b>System Messages</b>
                                      </Typography>
                                    </>
                                  }
                                  collapseIconPosition={"left"}
                                  sx={{ mb: 1 }}
                                >
                                  <>
                                    {chat.systemHistory?.map(
                                      (system, index) => {
                                        const systemMessage =
                                          renderSystemMessage(system.event);
                                        return (
                                          Boolean(systemMessage) && (
                                            <Box key={index + "-sh"}>
                                              <pre
                                                style={{
                                                  whiteSpace: "pre-wrap",
                                                  color: "grey",
                                                }}
                                              >
                                                {systemMessage}
                                              </pre>
                                            </Box>
                                          )
                                        );
                                      },
                                    )}
                                  </>
                                </CollapseBox>
                              ) : null}

                              {chat.message ? (
                                <ChatMessage chat={chat} />
                              ) : null}
                            </Fragment>
                          );
                        })}
                        {groupIndex === chatHistoryGroup.length - 1 &&
                          isStreaming && (
                            <Box alignItems={"center"} mt={1}>
                              <LoaderIcon />
                            </Box>
                          )}
                      </Stack>
                    </CollapseCard>
                    {// Render widgets
                    group.chatHistory
                      ?.flatMap((chat) => {
                        return chat.systemHistory ?? [];
                      })
                      .reduce(
                        (acc, system) => {
                          // remove duplication if system.event.data.input is the same
                          if (
                            acc.some((item) => {
                              if (
                                item.event.name === system.event.name &&
                                item.event.event === system.event.event
                              ) {
                                const itemInput =
                                  typeof item.event.data.input === "string"
                                    ? item.event.data.input
                                    : JSON.stringify(item.event.data.input);
                                const systemInput =
                                  typeof system.event.data.input === "string"
                                    ? system.event.data.input
                                    : JSON.stringify(system.event.data.input);

                                console.log("@@@@@@");
                                console.log(itemInput);
                                console.log(systemInput);

                                return itemInput === systemInput;
                              } else {
                                return false;
                              }
                            })
                          ) {
                            return acc;
                          }
                          return [...acc, system];
                        },
                        [] as { event: StreamEvent }[],
                      )
                      .map((system, index) => {
                        const widget = renderWidget(system.event);
                        return (
                          Boolean(widget) && (
                            <Box key={index + "-sh"}>
                              <SuspenseLoading>{widget}</SuspenseLoading>
                              {renderSuggestions(system.event, scrollToBottom)}
                            </Box>
                          )
                        );
                      })}
                    <Stack spacing={2} mb={2}>
                      {group.chatHistory
                        .filter(
                          (chat) =>
                            chat.agentId === teamConfig?.config.teamLead &&
                            chat.message,
                        )
                        .map((chat) => {
                          return <ChatMessage chat={chat} />;
                        })}
                    </Stack>
                  </>
                );
              })}
              {isStreaming && (
                <Box alignItems={"center"} mt={1}>
                  <LoaderIcon />
                </Box>
              )}
            </Box>
          )}
        </StickyScroll>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Card
          sx={{ p: 2, borderRadius: "0", borderRight: 0, borderLeft: 0 }}
          variant={"outlined"}
        >
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            sx={{ overflow: "auto" }}
          >
            <Avatar src={picture}></Avatar>

            <TextField
              fullWidth
              value={promptText}
              onChange={(e) => {
                setPromptText(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  startStream(promptText);
                  setPromptText("");
                }
              }}
              placeholder="Type your message here"
            />
            <Box textAlign={"right"}>
              <Button
                variant={"contained"}
                onClick={() => {
                  if (isStreaming) {
                    sendEventToAmplitude({
                      event_type: "Stopped Stream with Agents",
                      event_properties: {
                        taskId: llmData.taskId,
                        threadId: llmData.threadId,
                        message: promptText,
                      },
                    });
                    return stopStream();
                  }
                  startStream(promptText);
                  setPromptText("");
                  sendEventToAmplitude({
                    event_type: "Sent Message to Agents",
                    event_properties: {
                      taskId: llmData.taskId,
                      threadId: llmData.threadId,
                      message: promptText,
                    },
                  });
                }}
                startIcon={isStreaming ? <Stop /> : undefined}
              >
                {isStreaming ? "Stop" : "Send"}
              </Button>
            </Box>
          </Stack>
        </Card>
      </Box>
    </Loading>
  );
};
