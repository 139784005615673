import * as React from "react";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Input from "@mui/material/Input";
import Stack from "@mui/material/Stack";

export type TransferItem<T> = {
  label: string;
  value: T;
};

function not<T>(a: readonly TransferItem<T>[], b: readonly TransferItem<T>[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection<T>(
  a: readonly TransferItem<T>[],
  b: readonly TransferItem<T>[],
) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union<T>(
  a: readonly TransferItem<T>[],
  b: readonly TransferItem<T>[],
) {
  return [...a, ...not(b, a)];
}

type TransferListProps<T> = {
  list: TransferItem<T>[];
  listTitle: string;
  selected: TransferItem<T>[];
  selectedTitle: string;
  onTransfer: (selected: readonly TransferItem<T>[]) => void;
  isDisabled?: boolean;
};

export function TransferList<T>({
  list,
  listTitle,
  selected,
  selectedTitle,
  onTransfer,
  isDisabled = false,
}: TransferListProps<T>) {
  const [checked, setChecked] = React.useState<readonly TransferItem<T>[]>([]);
  const [left, setLeft] = React.useState<readonly TransferItem<T>[]>(
    list.filter((item) => !selected.find((s) => s.value === item.value)),
  );
  const [right, setRight] =
    React.useState<readonly TransferItem<T>[]>(selected);

  useEffect(() => {
    if (list !== left) {
      setLeft(
        list.filter((item) => !selected.find((s) => s.value === item.value)),
      );
    }

    if (selected !== right) {
      setRight(selected);
    }
  }, [list, selected]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: TransferItem<T>) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly TransferItem<T>[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: readonly TransferItem<T>[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    onTransfer(right.concat(leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    onTransfer(not(right, rightChecked));
  };

  const TransferListSection = (
    title: React.ReactNode,
    items: readonly TransferItem<T>[],
  ) => {
    const [filter, setFilter] = React.useState<string>("");
    const filteredItems = items.filter(
      (item) =>
        checked.includes(item) ||
        item.label.toLowerCase().includes(filter.toLowerCase()),
    );

    return (
      <Card data-testid={title}>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(filteredItems)}
              checked={
                numberOfChecked(filteredItems) === filteredItems.length &&
                filteredItems.length !== 0
              }
              indeterminate={
                numberOfChecked(filteredItems) !== filteredItems.length &&
                numberOfChecked(filteredItems) !== 0
              }
              disabled={filteredItems.length === 0 || isDisabled}
              inputProps={{
                "aria-label": "all items selected",
              }}
            />
          }
          title={title}
          titleTypographyProps={{ variant: "subtitle1" }}
          subheader={`${numberOfChecked(filteredItems)}/${
            filteredItems.length
          } selected`}
        />
        <Divider />
        <Stack direction={"row"} alignContent={"space-between"}>
          <Input
            fullWidth
            sx={{
              px: 2,
              py: 1,
              "&::before": {
                borderBottom: "0px",
              },
            }}
            placeholder="Filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <Button
            size={"small"}
            onClick={() => setFilter("")}
            disabled={!filter}
          >
            Clear
          </Button>
        </Stack>
        <Divider />
        <List
          sx={{
            height: 300,
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {filteredItems.map((item: TransferItem<T>, i) => {
            const labelId = `transfer-list-item-${item.value}-label`;

            return (
              <ListItemButton
                key={item.label + String(item.value) + i}
                role="listitem"
                onClick={handleToggle(item)}
                disabled={isDisabled}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                    disabled={isDisabled}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={item.label}
                  sx={{ wordBreak: "break-word" }}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Card>
    );
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      flexWrap={"nowrap"}
    >
      <Grid item flex={1}>
        {TransferListSection(listTitle, left)}
      </Grid>
      <Grid item>
        <Stack direction={"column"} alignItems={"center"} spacing={1}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || isDisabled}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || isDisabled}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Stack>
      </Grid>
      <Grid item flex={1}>
        {TransferListSection(selectedTitle, right)}
      </Grid>
    </Grid>
  );
}
