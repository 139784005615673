import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Eraser } from "@phosphor-icons/react/dist/csr/Eraser";
import { Gear } from "@phosphor-icons/react/dist/csr/Gear";
import type { FC } from "react";
import React, { useMemo } from "react";
import { useAuth } from "@/hooks/use-auth";
import type { LLMData, TaskMode } from "@/pages/task/chat-task-page";
import type { AgentId, MissionId, TeamConfigId, UserId } from "allgood-schema";
import { DeliverableId, ThreadId } from "allgood-schema";
import { trpc } from "@/utils/trpc";
import Loading from "@/components/loading";

import CardHeader from "@mui/material/CardHeader";
import { PlayCircle } from "@phosphor-icons/react/dist/csr/PlayCircle";
import { sendEventToAmplitude } from "src/utils/amplitude";
import { AgentAvatar } from "@/pages/task/components/agent-avatar";
import { UserAvatar } from "@/pages/task/components/user-avatar";
import { ThreadSelectComponent } from "./thread-select-component";

type ChatHeaderProps = {
  mode: TaskMode;
  setMode: (mode: "chat" | "config") => void;
  interactiveMode: boolean;
  setInteractiveMode: (interactiveMode: boolean) => void;
  missionId: MissionId;
  teamConfigId: TeamConfigId;
  llmData: LLMData;
  onClickClear?: () => void;
  onClickStart?: () => void;
};

export const ChatHeader: FC<ChatHeaderProps> = ({
  mode,
  setMode,
  interactiveMode,
  setInteractiveMode,
  missionId,
  teamConfigId,
  llmData,
  onClickClear,
  onClickStart,
}) => {
  const auth = useAuth();

  const picture = auth.user?.profile?.rawAttributes?.picture;
  const { data: teamConfig, isLoading: isLoadingTask } =
    trpc.agentWorkflow.get.useQuery({
      id: teamConfigId,
    });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInteractiveMode(event.target.checked);
  };

  // unique other user ids within the chat history
  const otherUsersFromHistory = useMemo(
    () =>
      Array.from(
        new Set(
          llmData.chatHistory
            .filter(
              (message) =>
                message.type === "user" && message.userId !== auth.user?.userId,
            )
            .map((message) => message.userId),
        ),
      ).map((userId) => ({
        memberType: "user",
        id: userId,
      })),
    [auth.user?.userId, llmData.chatHistory],
  );

  return (
    <Loading loading={isLoadingTask} fullscreen={false}>
      {teamConfig && (
        <>
          <CardHeader
            sx={{ py: 1 }}
            title={
              <Stack direction={"row"} alignItems={"center"} spacing={4}>
                <Stack direction={"column"}>
                  <Typography variant={"h6"}>Chat</Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={1}
                  flex={1}
                  justifyContent={"start"}
                >
                  <Box>
                    <Tooltip title={"You"}>
                      <Avatar
                        src={picture}
                        sx={{ width: 36, height: 36 }}
                      ></Avatar>
                    </Tooltip>
                  </Box>
                  {[
                    ...otherUsersFromHistory,
                    ...teamConfig.config.teamMembers,
                  ].map(({ id: id, memberType }) => {
                    return (
                      <Box key={id}>
                        {memberType === "agent" || memberType === "lead" ? (
                          <AgentAvatar agentId={id as AgentId} size={36} />
                        ) : (
                          <UserAvatar userId={id as UserId} size={36} />
                        )}
                      </Box>
                    );
                  })}
                </Stack>
                <Stack direction={"row"} justifyContent={"end"} spacing={1}>
                  {llmData.taskId && llmData.deliverableId && (
                    <ThreadSelectComponent
                      missionId={missionId}
                      deliverableId={DeliverableId.parse(llmData.deliverableId)}
                      threadId={
                        llmData.threadId
                          ? ThreadId.parse(llmData.threadId)
                          : undefined
                      }
                    />
                  )}

                  <Box px={1}>
                    <Tooltip title={"Interactive Toggle"}>
                      <Switch
                        onChange={handleChange}
                        checked={interactiveMode}
                      />
                    </Tooltip>
                  </Box>

                  <Tooltip title={"Clear"}>
                    <IconButton
                      onClick={() => {
                        onClickClear?.();
                        sendEventToAmplitude({
                          event_type: "Task Clear clicked",
                          event_properties: {
                            missionId,
                            teamConfigId,
                          },
                        });
                      }}
                    >
                      <Eraser />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={"Start Task"}>
                    <IconButton
                      onClick={() => {
                        onClickStart?.();
                        sendEventToAmplitude({
                          event_type: "Task Start clicked",
                          event_properties: {
                            missionId,
                            teamConfigId,
                          },
                        });
                      }}
                    >
                      <PlayCircle />
                    </IconButton>
                  </Tooltip>

                  {missionId && teamConfigId ? (
                    <Tooltip title={"Settings"}>
                      <IconButton
                        onClick={() => {
                          setMode(mode === "config" ? "chat" : "config");
                          if (mode !== "config") {
                            sendEventToAmplitude({
                              event_type: "Chat Configure Entered",
                              event_properties: {
                                missionId,
                                teamConfigId,
                              },
                            });
                          }
                        }}
                        sx={{
                          bgcolor: mode === "config" ? "primary.main" : "",
                          ":hover": {
                            bgcolor: mode === "config" ? "primary.light" : "",
                          },
                          color:
                            mode === "config" ? "primary.contrastText" : "",
                        }}
                      >
                        <Gear weight={mode === "config" ? "fill" : undefined} />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Stack>
              </Stack>
            }
          />
          <Divider />
        </>
      )}
    </Loading>
  );
};
