import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { TaskWidgetContext } from "@/pages/campaign/configure/team-task";
import { trpc } from "@/utils/trpc";
import {
  Timeline,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  TimelineSeparator,
} from "@mui/lab";
import {
  CheckpointTimelineItem,
  CurrentWorkflowCard,
  WorkflowExecutionTimelineItem,
} from "@/pages/campaign/tasks/audit-workflow-status";

/**
 * The Service Task widget will show information about the workflow service task.
 *
 * A ServiceTask type of Task will have the Workflow Filter information to get the right
 * Workflow Instance etc.
 *
 */
const ServiceTaskWidget = (context: TaskWidgetContext) => {
  // Track whether the task has been started, and whether the task has started running (i.e. created an execution record) yet.
  const [started, setStarted] = useState(false);
  const [running, setRunning] = useState(false);

  // Use a long refetch interval when we think no workflows are running, and a short one when we know they are running.
  const REFETCH_LONG = 20000;
  const REFETCH_SHORT = 1000;
  const refetchInterval = started || running ? REFETCH_SHORT : REFETCH_LONG;

  const workflowType = context.task.workflowType;

  // Mutation to start the workflow.
  const startMutation = trpc.workflows.runServiceTaskWorkflow.useMutation();

  // Get the latest workflow checkpoints for the audit tasks mission.
  const [checkpoints] = trpc.workflows.listCheckpoints.useSuspenseQuery(
    {
      filter: {
        inWorkflowMatchingFilter: {
          workflowType,
          args: { missionId: context.missionId },
        },
      },
      limit: 50, // We don't really care about more than that
    },
    { refetchInterval },
  );

  const [latestRunList] = trpc.workflows.getRuns.useSuspenseQuery(
    {
      filter: { workflowType, args: { missionId: context.missionId } },
      limit: 1,
    },
    { refetchInterval },
  );

  const latestRun = latestRunList?.records?.[0] ?? null;
  const latestRunComplete = !!latestRun?.completedAt;

  // When the ID of the latest execution changes, we are no longer started.
  useEffect(() => {
    setStarted(false);
  }, [latestRun?.id]);

  // Start handler. Mark as started, wait for polling to mark as running.
  const onStart = () => {
    setStarted(true); // Start polling faster immediately.
    startMutation.mutate({
      missionId: context.missionId,
      taskId: context.task.id,
    });
  };

  // Update the started/running state based on the latest run.
  useEffect(() => {
    // If we have no run or a complete run, we are not running. We could be started.
    if (!latestRun || latestRunComplete) {
      setRunning(false);
      return;
    }

    // State here: we have a run, and it's not complete.
    setRunning(true);
    setStarted(false);
  }, [latestRun, latestRunComplete, started]);

  // Figure out which checkpoints are from which workflow executions.
  const timeline = [];
  for (let i = 0; i < checkpoints?.records?.length; i++) {
    const checkpoint = checkpoints.records[i];
    const lastCheckpoint = i > 0 ? checkpoints.records[i - 1] : null;

    // Add a wf execution timeline item if we're on a new workflow.
    if (
      lastCheckpoint &&
      lastCheckpoint.workflowRunId !== checkpoint.workflowRunId
    ) {
      timeline.push(
        <WorkflowExecutionTimelineItem
          key={"wfr" + checkpoint.workflowRunId}
          workflowRunId={checkpoint.workflowRunId}
        />,
      );
    }
    timeline.push(
      <CheckpointTimelineItem
        key={"ckp" + checkpoint.id}
        checkpoint={checkpoint}
      />,
    );
  }

  return (
    <Stack spacing={2} px={3} pt={3} sx={{ overflowY: "scroll", flex: 1 }}>
      <CurrentWorkflowCard
        workflow={latestRun}
        started={started}
        running={running}
        onStart={onStart}
      />

      <Timeline
        sx={{
          marginTop: "0",
          [`& .${timelineOppositeContentClasses.root}`]: {
            display: "none",
          },
        }}
      >
        <TimelineItem
          sx={{
            minHeight: "0",
            animation: "pulse 2s ease-in-out infinite",
            "@keyframes pulse": {
              "0%": { opacity: 1 },
              "30%": { opacity: 0.5 },
              "60%": { opacity: 1 },
              "100%": { opacity: 1 },
            },
          }}
        >
          <TimelineOppositeContent />
          <TimelineSeparator>
            <TimelineDot />
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              color="textSecondary"
              variant="overline"
              component="div"
              sx={{ lineHeight: "2em" }}
            >
              LIVE
            </Typography>
          </TimelineContent>
        </TimelineItem>
        {timeline}
      </Timeline>
    </Stack>
  );
};

export default ServiceTaskWidget;
